export const TranslateNameFromPathToSectionForBasicQuestions = (pathName) => {
    switch(pathName) {
        case 'ostrzegawcze-znaki':
            return 'W1'
        case 'znaki-zakazu-nakazu':
            return 'W2'
        case 'znaki-informacyjne-kierunku-i-miejscowosci-uzupełniajace':
            return 'W3'
        case 'znaki-drogowe-poziome':
            return 'W4'
        case 'sygnaly-swietlne-sygnaly-dawane-przez-kierujacego-ruchem':
            return 'W5'    
        case 'wlaczanie-sie-do-ruchu-skrzyzowania-rownorzedne':
            return 'W6'
        case 'skrzyzowania-ze-znakami-okreslajacymi-pierwszenstwo-przejazdu':
            return 'W7'               
        case 'skrzyzowania-z-sygnalizacja-swietlna':
            return 'W8'  
        case 'skrzyzowania-lub-przejscia-dla-pieszych-z-kierujacych-ruchem':
            return 'W9'
        case 'pozycja-pojazdu-na-drodze-wjazd-i-zjazd-ze-skrzyzowania-zatrzymanie-i-postoj':
            return 'W10'
        case 'zmiana-pasa-ruchu-zmiana-kierunku-jazdy':
            return 'W11'
        case 'wyprzedzanie':
            return 'W12'
        case 'omijanie-wymijanie-cofanie':
            return 'W13'
        case 'uzywanie-swiatel-zewnetrznych-i-sygnalow-pojazdu':
            return 'W14'
        case 'znaczenie-zachowania-szczegolnej-ostroznosci':
            return 'W15'
        case 'zachowanie-wobec-pieszego':
            return 'W16'
        case 'zachowanie-wobec-rowerzysty-i-dzieci':
            return 'W17'
        case 'zachowanie-na-przejazdach-kolejowych-i-tramwajowych':
            return 'W18'
        case 'ogolne-zasady-okreslajace-zachowanie-kierowcy':
            return 'W19'
        case 'spostrzeganie-ocena-sytuacji-i-podejmowanie-decyzji':
            return 'W20'
        default:
            return null;
    }
}

export const TranslateNameFromPathToSectionForSpecialistQuestions = (pathName) => {
    switch(pathName) {
        case 'dopuszczalne-predkosci-pojazdu-ograniczenia':
            return ['C1' , 'D1' , 'T1' , 'B1' , 'A1']   
        case 'wyposazenie-pojazdu-zwiazane-z-bezpieczenstwem':
            return ['C2' , 'D2' , 'T2' , 'B2' , 'A2']
        case 'zasady-odnoszace-sie-do-rodzaju-transportu':
            return ['C3' , 'D3']
        case 'odstepy-i-hamowanie-pojazdu':
            return ['C4' , 'D4' , 'T3' ,'B3', 'A3']
        case 'czynniki-ryzyka-zwiazane-z-roznymi-warunkami-drogowymi':
            return ['C5' , 'D5' ,'T4' ,'B4' , 'A4']
        case 'technika-kierowania-pojazdem':
            return ['C6' ,'D6' , 'B6' , 'A6']
        case 'czynniki-bezpieczenstwa':
            return ['C7' , 'D7' , 'T6' , 'B7' , 'A7']
        case 'obowiazki-wlasciciela-pojazdu':
            return ['C8' , 'D8' , 'T7' , 'B8' , 'A8']
        case 'aspekty-mechaniczne-zwiazane-z-zachowaniem-bezpieczenstwa-na-drodze':
            return ['C9' , 'D9' , 'T8' , 'B9' , 'A9']
        case 'akcje-ratunkowe':
            return ['C10' , 'D10' ,'T9' ,'B10' ,'A10']
        case 'rozne-pola-widzenia-kierowcow':
            return ['T5' , 'B5' , 'A5']
        default:
            return null;
    }
}

export const TranslateNameFromPathToSectionForSigns = (pathName) => {
    switch(pathName) {
        case 'znaki-ostrzegawcze':
            return 'a';
        case 'znaki-zakazu':
            return 'b';
        case 'znaki-nakazu':
             return 'c'
        case 'znaki-informacyjne':
            return 'd'
        case 'znaki-kierunku-i-miejscowosci':
            return 'e'
        case 'znaki-uzupelniajace':
             return 'f'
        case 'znaki-dodatkowe':
            return 'g'
        case 'znaki-poziome':
            return 'p'
        case 'tabliczki-do-znakow':
            return 't'
        case 'syngalizacja-swietlna':
            return 'syg'
        case 'sygnaly-dawane-przez-kierujacego-ruchem':
            return 'pol'
        default:
            return null;
    }
}

export const TranslateNameFromPathToSectionForRegulations = (pathName) => {
    switch(pathName) {
        case 'podstawowe-definicje':
            return ['1' , '1']
        case 'przepisy-ogolne':
            return ['2' , '1']
        case 'ruch-pieszych':
            return ['2' , '2']
        case 'zasady-ogolne':
            return ['3' , '1']
        case 'wlaczanie-sie-do-ruchu':
            return ['3' , '2']
        case 'predkosc-i-hamowanie':
            return ['3', '3']
        case 'zmiana-kierunku-jazdy-lub-pasa-ruchu':
            return ['3' , '4']
        case 'wymijanie-omijanie-i-cofanie':
            return ['3' , '5']
        case 'wyprzedzanie':
            return ['3' , '6']
        case 'przecinanie-sie-kierunkow-ruchu':
            return ['3' , '7']
        case 'ostrzeganie-oraz-jazda-w-warunkach-zmniejszonej-przejrzystosci-powietrza':
            return ['3' , '8']
        case 'holowanie':
            return ['3' , '9']
        case 'ruch-pojazdow-w-kolumnie':
            return ['3' , '10']
        case 'dodatkowe-dotyczace-kierujacych-rowerami':
            return ['3' , '11']
        case 'dodatkowe-dotyczace-kierujacych-pojazdami-zaprzegowymi':
            return ['3' , '12']
        case 'przepisy-porzadkowe':
            return ['4' , '1']
        case 'zatrzymanie-i-postoj-pojazdu':
            return ['4' , '2']
        case 'uzywanie-swiatel-zewnetrznych-pojazdu':
            return ['4' , '3']
        case 'warunki-uzywania-pojazdow-w-ruchu-drogowym':
            return ['4' , '4']
        default:
            return null;
    }
}