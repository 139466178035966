import React from 'react';
import Container from 'react-bootstrap/Container';
import './PricingSection.css';
import Pricing from './Pricing';

const PricingSection = () => {

    return (
        <div className="PricingSection">

            <div className="PricingSection__header-text">
                <h2>Odblokuj pakiet premium w wybranym dla siebie czasie dostępu</h2>
            </div>

            <Container fluid="xl">
                <Pricing
                    isInMainPage={true}
                />
            </Container>
        </div>
    )
}

export default PricingSection
