import React, { useContext , useState, useEffect } from 'react';
import { resetModalSettings, showToast } from '../redux/actions/notificationActions';
import store from '../redux/store';

const OnlineStatusContext = React.createContext();

export function useOnlineStatus(){
    return useContext(OnlineStatusContext);
}

export function OnlineStatusProvider({ children }){

    const [ onlineStatus, setOnlineStatus ] = useState(true);

    useEffect(() => {
        window.addEventListener("offline", () => {
          setOnlineStatus(false);
          store.dispatch(showToast("Problem z połączeniem internetowym" , "error")).then(() => {
            store.dispatch(resetModalSettings());
          })     
        });
        window.addEventListener("online", () => {
          setOnlineStatus(true);
          store.dispatch(showToast("Jesteś ponownie połączony z internetem" , "success")).then(() => {
            store.dispatch(resetModalSettings());
          })     
        });
    
        return () => {
          window.removeEventListener("offline", () => {
            setOnlineStatus(false);
          });
          window.removeEventListener("online", () => {
            setOnlineStatus(true);
          });
        };
    }, []);

    return(
        <OnlineStatusContext.Provider value={onlineStatus}>
            {children}
        </OnlineStatusContext.Provider>
    )

}