import React , { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../../../contexts/AuthContext';
import { useToastManager } from '../../../contexts/ToastManagerContext';
import { Alert } from 'react-bootstrap';
import CustomButton from '../../../components/others/button/CustomButton';
import TextField from '../../../components/userPanel/textField/TextField';
import FirebaseErrorTranslateCode from '../../../helpers/FirebaseTranslateCode';
import './UpdateEmailForm.css';
import { facebookProvider, googleProvider } from '../../../Firebase';
import checkIfCurrentUserExists from '../../../helpers/CheckIfCurrentUserExists';
import { ifChromeMobileBrowserSignReauthenticateWithRedirect } from '../../../helpers/AuthContextHelper';

const UpdateEmailForm = (props) => {

    const [ loading, setLoading ] = useState(false);
    const [error , setError] = useState('');
    const { updateEmail, currentUser, reauthWithSocialMedia  } = useAuth();
    const { showToast } = useToastManager();
    
    const validate = Yup.object({
        email: Yup.string()
            .email('Adres email jest nie poprawny')
            .required('Adres email jest wymagany') 
    })

    const setEmail = async (values) => {
        setError('');
        setLoading(true);

        await checkIfCurrentUserExists();

        await updateEmail(values.email).then(() => {
            showToast('custom-success' , 'E-mail został poprawnie ustawiony');
            props.CloseModal()
        }).catch(async error => {
            setError(FirebaseErrorTranslateCode(error.code));
            if(error.code === 'auth/requires-recent-login'){
                await reauth();
            }
        })    

        setLoading(false);
    }

    function getProvider(){
        const currentProviderId = currentUser.providerData[0].providerId;
        if(currentProviderId === 'google.com'){
            return googleProvider;
        }  
        if(currentProviderId === 'facebook.com'){
            return facebookProvider;
        }
    }

    const reauth = async () => {
        const provider = getProvider();     
        
        await ifChromeMobileBrowserSignReauthenticateWithRedirect(provider);
        
        reauthWithSocialMedia(provider).then(() => {
            showToast('custom-success' , 'Udało się ponownie zalogować');
            setError('');
        }).catch((error) => {
            setError(FirebaseErrorTranslateCode(error.code));
        });   
    }
    
    return (
        <Formik
          initialValues={{
            email:''
          }}
            validationSchema={validate}
            onSubmit={values =>{
                setEmail(values);
          }}
          >
          {formik => (
            <>
                { error && 
                <Alert 
                    variant="danger" 
                    className="alert-danger-custom UpdateEmailForm__TextField">{error}
                </Alert>}
                <Form className="UpdateEmailForm__TextField">
                    <TextField 
                        label="Email" 
                        name="email" 
                        type="email" 
                        placeholder="Adres email"
                    />
                    <div className="Modal__footer">
                        <CustomButton 
                            content={"Anuluj"} 
                            onClick={() => props.CloseModal()}
                        />
                        <CustomButton 
                            content={"Zapisz"} 
                            loading={loading} 
                            type={'submit'}
                            color="yellow"
                        />
                    </div>
                </Form>
            </>
          )}
        </Formik>
    )
}

export default UpdateEmailForm