import React from 'react';
import { Container, Row, Col  } from 'react-bootstrap';
import "./MobileSecton.css";

const MobileSection = () => {
  return (
        <div className="InformationSection">
            <Container fluid="xl">
                    <div className="InformationSection__header">
                            <p>Nowoczesna i intuicyjna aplikacja mobilna</p>
                    </div>
                    <div className="Section__container">
                        <Row>
                            <Col className="InformationSection__box">
                                <a href='https://play.google.com/store/apps/details?id=pl.webnec.testyprawojazdy&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                    <img 
                                        alt='pobierz z Google Play' 
                                        src='https://play.google.com/intl/en_us/badges/static/images/badges/pl_badge_web_generic.png'
                                        className="MobileSection__image"
                                    />
                                </a>
                                <h2>Pobierz naszą aplikację mobilną</h2>
                                <p>
                                Aplikacja dostępna jest na telefonach z system operacyjnym android i nie wymaga połączenia z internetem.<br/>
                                Już teraz pobierz aplikację i przygotowuj się do egzaminu teoretycznego za pomocą swojego telefonu.<br/>
                                Nauka na prawo jazdy nigdy nie była tak prosta!                               
                                </p>
                            </Col>
                        </Row>
                    </div>
            </Container>
        </div>
  )
}

export default MobileSection;