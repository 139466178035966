import React , { useEffect } from 'react';
import CustomCheckbox from '../../../components/others/checkbox/CustomCheckbox';
import { useDispatch , useSelector } from 'react-redux';
import Dropdown from '../../../components/others/dropdown/Dropdown';
import CustomButton from '../../../components/others/button/CustomButton';
import agent from '../../../api/agent';
import { useToastManager } from '../../../contexts/ToastManagerContext';
import { resetAnswersQuestionsLocal , computeProgressInQuestions } from '../../../redux/actions/questionActions';
import { resetAnswersSignsLocal , computeProgressInSigns } from '../../../redux/actions/signActions';
import { resetAnswersRegulationsLocal , computeProgressInRegulations } from '../../../redux/actions/regulationActions';
import { setCategory , setAutoPlay , setTimeInExam } from '../../../redux/actions/userActions';
import { getAmountOfWrongs, resetActiveQuestion } from '../../../redux/actions/wrongActions';
import SmallSpinner from '../../../components/others/spinner/SmallSpinner';
import { ModalContent } from '../../../models/ModalContet';
import { showModal , closeModal , setLoadingInModal } from '../../../redux/actions/notificationActions';
import { clearQuestionsAnswersQueue, clearRegulationsAnswersQueue, clearSignsAnswersQueue } from '../../../redux/actions/answersActions';

const Account = () => {

    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    const questionsProgress = useSelector((state) => state.questions.questionsProgress);
    const amountOfWrongs = useSelector((state) => state.wrongs.amountOfWrongs);
    const regulationsProgress = useSelector((state) => state.regulations.regulationsProgress);
    const signsProgress = useSelector((state) => state.signs.signsProgress);

    const { showToast } = useToastManager();

    const dropdownsItem = [
        {
            "text" : "A, A1, A2, AM",
            "value" : "A"
        },
        {
            "text" :"B, B1",
            "value" : "B"
        },
        {
            "text" : "C, C1",
            "value" : "C"
        },
        {
            "text" : "D, D1",
            "value" : "D"
        },
        {
            "text" : "T",
            "value" : "T"
        }
    ]

    function handleChangeCategory(e){

        let category = e.target.getAttribute("data-value");

        if(category !== null){
            dispatch(setCategory(category));
        }

    }
    
    function handleChangeAutoPlay(e){
        dispatch(setAutoPlay(e.target.checked))
    }
 
    function handleChangeTimeInExam(e){
        dispatch(setTimeInExam(e.target.checked))
    }

    function checkProgressBeforeReset(progressToCheck){
        let progressMap = new Map(Object.entries(progressToCheck));  
        for (const [key, value] of progressMap) {
            if(value > 0 && key !== undefined){
                return true;
            }
        }
        return false;
    }

    //reset question progress
    function handleQuestionProgressReset(){
        const result = checkProgressBeforeReset(questionsProgress);
        if
        (
            (result) ||
            (result && amountOfWrongs != null & amountOfWrongs > 0) ||
            (amountOfWrongs != null & amountOfWrongs > 0)
        )
        {
            let content= new ModalContent(
                "Error",
                "KROK 1",
                "Reset postępu dla pytań:\n-usunie zapisane odpowiedzi\n-postęp będzie równy 0%.",
                "Nie",
                () => dispatch(closeModal()),
                "Tak",
                () => handleConfirmationQuestionProgressReset()
            );
            dispatch(showModal(content));
        }else{
            showToast('custom-success' , 'Nie masz postępu do zresetowania');
        }
    }

    function handleConfirmationQuestionProgressReset(){
        let content= new ModalContent(
            "Error",
            "KROK 2",
            "Czy na pewno chcesz ZRESETOWAĆ SWÓJ POSTĘP DLA PYTAŃ?",
            "Nie",
            () => dispatch(closeModal()),
            "Tak",
            () => questionProgressReset()
        );
        dispatch(showModal(content));
    }

    async function questionProgressReset(){
        dispatch(setLoadingInModal(true));
        try{
            dispatch(clearQuestionsAnswersQueue());
            await agent.UserAnswers.deleteQuestions().then(checksum =>{
                console.log(checksum)
                if(typeof checksum !== 'undefined'){
                    dispatch(resetAnswersQuestionsLocal()).then(response => {
                        dispatch(computeProgressInQuestions());
                        dispatch(getAmountOfWrongs());
                        dispatch(resetActiveQuestion());
                        showToast('custom-success' , 'Postęp dla pytań zresetowany');
                    });  
                }          
            });
        }catch(error){
            console.log(error);
            dispatch(closeModal());
        }
        dispatch(closeModal());
    }

    //reset learning progress 

    function handleLearningProgressReset(){
        const regulationsProgressResult = checkProgressBeforeReset(regulationsProgress);
        const signsProgressResult = checkProgressBeforeReset(signsProgress);
        if
        (
            (regulationsProgressResult && signsProgressResult) ||
            (regulationsProgressResult) ||
            (signsProgressResult)
        ){
            let content= new ModalContent(
                "Error",
                "KROK 1",
                "Reset postępu dla nauki:\n-usunie zapisane postępy dla nauki znaków i przepisów\n-postęp będzie równy 0%",
                "Nie",
                () => dispatch(closeModal()),
                "Tak",
                () => handleConfirmationLearningProgressReset()
            );
            dispatch(showModal(content));
        }else{
            showToast('custom-success' , 'Nie masz postępu do zresetowania');
        }
    }

    function handleConfirmationLearningProgressReset(){
        let content= new ModalContent(
            "Error",
            "KROK 2",
            "Czy na pewno chcesz ZRESETOWAĆ SWÓJ POSTĘP DLA NAUKI?",
            "Nie",
            () => dispatch(closeModal()),
            "Tak",
            () => learningProgressReset()
        );
        dispatch(showModal(content));
    }

    async function learningProgressReset(){
        dispatch(setLoadingInModal(true));
        try{
            dispatch(clearRegulationsAnswersQueue());
            dispatch(clearSignsAnswersQueue());
            await agent.UserAnswers.deleteRegulations().then(checksum =>{
                console.log(checksum)
                if(typeof checksum !== 'undefined'){
                }
                dispatch(resetAnswersRegulationsLocal()).then(response => {
                    dispatch(computeProgressInRegulations());
                });            
            });

            await agent.UserAnswers.deleteSigns().then(checksum =>{
                console.log(checksum)
                if(typeof checksum !== 'undefined'){
                    dispatch(resetAnswersSignsLocal()).then(response => {
                        dispatch(computeProgressInSigns());
                        showToast('custom-success' , 'Postęp dla nauki zresetowany');
                    });   
                }     
            });

        }catch(error){
            console.log(error);
            dispatch(closeModal());
        }
        dispatch(closeModal())
    }

    useEffect(() => {
    } , [user.userLoading , user.user])

    const handleDeleteUserAccount = () => {
        const content = new ModalContent(
                "Error",
                null,
                null,
                null,
                () => dispatch(closeModal()),
                null,
                null,
                null,
                true
              )
        dispatch(showModal(content));  
    }

    return (
        <>
        { user.user === null && <SmallSpinner isText={true}/>}
        { user.user !== null &&
            <>
            <div className='Tab__Container'>
                <div>
                    <b>Wybór kategorii</b>
                    <Dropdown 
                        content={`Kategoria prawa jazdy: ${user.selectedCategoryToShow}`} 
                        items={dropdownsItem} 
                        onClick={handleChangeCategory} 
                        loading={user.userLoading}
                        disabled={!user.isActivePremium}
                        selectedCategoryToShow={user.selectedCategoryToShow}/> 
                </div>

                <div>
                    <b>Resetowanie postępów</b>
                    <CustomButton 
                        onClick={handleQuestionProgressReset} 
                        content={"Reset postępu w pytaniach"}/>
                    <CustomButton 
                        onClick={handleLearningProgressReset} 
                        content={"Reset postępu w nauce"}/>
                </div>

                <div>
                    <b>Usuwanie konta</b>
                    <CustomButton
                        onClick={handleDeleteUserAccount} 
                        content={"Usuń konto"}
                        color={"redBorder"}/>
                </div>

                <div>
                    <b>Ustawienia</b>
                    <CustomCheckbox 
                        onChange={handleChangeAutoPlay} 
                        name="autoPlay" 
                        type="checkbox" 
                        text="Autoodtwarzanie filmów" 
                        isChecked={user.user.options.autoPlay}/>
                    <CustomCheckbox 
                        onChange={handleChangeTimeInExam} 
                        name="timeInExam" 
                        type="checkbox" 
                        text="Licznik czasu przy egzaminie" 
                        isChecked={user.user.options.timeInExam} />
                </div>

            </div>
            </>
         }
        </>
        
    )
}

export default Account
