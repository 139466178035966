import React  from 'react';
import Container from 'react-bootstrap/Container';
import './Terms.css';
import TermsParagraph from './TermsParagraph';
import { GiScales } from 'react-icons/gi';
import ReactHtmlParser from 'react-html-parser';

const Terms = (props) => {

    let termsParagraph = props.data.paragraph.map((item) =>
    <TermsParagraph data={item} key={item.header} isAcknowledgmentsPage={props.isAcknowledgmentsPage} />
    )

    return (
        <Container fluid="xl">
            <div className="PrivacyPolicySection">
                <div className="PrivacyPolicySection__container">
                    <div className="PrivacyPolicySection__mainTitle">
                        <h5>
                            {props.data.mainTitle}
                        </h5>
                        <p>
                            {props.data.mainDesc}
                        </p>
                    </div>
                    <div>
                    {
                        props.isAcknowledgmentsPage &&
                        <>
                            {props.data.licensesDefenition.map((item, index)=> 
                            <>
                            <b key={index}><GiScales className="License__icon"/>{item.license}</b>
                                
                                {item.desc.map((item, index) => 
                                    <p key={index}>{ReactHtmlParser(item)}</p>
                                )}
                            </>
                             )}
                             
                        </>        
                    }
                    </div>
                    {termsParagraph}
                </div>
            </div>
        </Container>
    )
}

export default Terms
