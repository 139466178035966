import React , { useEffect , useState } from 'react';
import SectionDashboard from '../../components/dashboard/section/Section';
import { useSelector  } from 'react-redux';
import { Section } from '../../models/Section'
import SmallSpinner from '../../components/others/spinner/SmallSpinner'

const Learning = () => {

    const signsStore = useSelector((state) => state.signs);
    const regulationsStore = useSelector((state) => state.regulations);
    const isActivePremium = useSelector((state) => state.user.isActivePremium);

    const pathname = window.location.pathname;

    const mainSignsSection = [
        new Section("S", "signs", "Znaki", '/nauka/znaki' , false),
        new Section("R"  , "regulations" , "Przepisy" , '/nauka/przepisy' , false)
    ]
    const mainBreadcrumb = [{text:'Nauka', link:'/nauka'}]
    const signBreadcrumb = [{ text:'Nauka', link:'/nauka' },{ text:'Znaki', link:'/nauka/znaki' }]
    const regulationsBreadcrumb = [{ text:'Nauka', link:'/nauka' },{ text:'Przepisy', link:'/nauka/przepisy' }]

    const [ loading , setLoading ] = useState(true)
    const [ section , setSection ] = useState();
    const [ breadcrumb , setBreadcrumb ] = useState();
    const [ progress , setProgress ] = useState();

    useEffect(() => {

        if(isActivePremium !== null && Object.keys(signsStore.signsProgress).length !== 0 && Object.keys(regulationsStore.regulationsProgress).length !== 0){
            let nameOfPage = pathname.substring(pathname.lastIndexOf('/') + 1);
            switch(nameOfPage) {
                case 'znaki':
                    setSection(signsStore.signsSections);
                    setBreadcrumb(signBreadcrumb);
                    setProgress(new Map(Object.entries(signsStore.signsProgress)));
                    break;
                case 'przepisy':
                    setSection(regulationsStore.regulationsSections);
                    setBreadcrumb(regulationsBreadcrumb);
                    setProgress(new Map(Object.entries(regulationsStore.regulationsProgress)));
                    break;
                default:
                    setSection(mainSignsSection);
                    setBreadcrumb(mainBreadcrumb);
                    let mapSigns = new Map(Object.entries(signsStore.signsProgress));
                    let mapRegulations = new Map(Object.entries(regulationsStore.regulationsProgress));
                    let mapLearning = new Map([...mapSigns, ...mapRegulations])
                    setProgress(mapLearning);
                    break;
            }
            setTimeout(()=>setLoading(false), 200)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname , signsStore.loading , signsStore.signsProgress , regulationsStore.loading , regulationsStore.regulationsProgress ]);
   
    return (
        <>
            { loading && 
                 <SmallSpinner 
                 isText={true}
                 isCenter={true}
              />
            }  
            { !loading && 
              <SectionDashboard 
                colorOfProgressCircle={'#41B62B'} 
                title={breadcrumb} 
                data={section} 
                progress={progress} 
                messageForToast={null}
                isActivePremium={isActivePremium}
                />  
            }
        </> 
    )
}

export default Learning
