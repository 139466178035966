export const TermsOfUseData = {
    id: 0,
    mainTitle: "Warunki korzystania z serwisu",
    mainDesc: "Niniejszy regulamin określa zasady korzystania z serwisu internetowego www.prawojazdy.pro, dostępnego dla użytkowników w celu nauki i przygotowania się do egzaminu na prawo jazdy. Korzystanie z serwisu oznacza akceptację regulaminu oraz jego postanowień.",
    paragraph:
    [
        {
            header:"§1. Użyte definicje w niniejszym regulaminie oznaczają:",
            desc: 
            [
                "1.	Użytkownik: osobę fizyczną, która musi akceptować niniejszy regulamin i przejść pomyślnie rejestrację.",
                "2.	Konto użytkownika: profil osoby fizycznej, który zostaje utworzony po pomyślnym przejściu procedury rejestracji.",
                "3. Serwis: serwis internetowy www.prawojazdy.pro.",
                "4. Operator serwisu: NECODEM sp. z o.o. z siedzibą w Drzonowie 20 66-008, wpisana do rejestru przedsiębiorców pod numerem KRS 0000978044, REGON 52234652700000, NIP 9731086009."
            ]
        },
        {
            header:"§2. Zasady korzystania z serwisu:",
            desc: 
            [
                "1. Aby w pełni korzystać z serwisu użytkownik musi zaakceptować regulamin. Użytkownik podczas rejestracji wyraża zgodę na wszystkie warunki tego regulaminu oraz jest obowiązany do przestrzegania regulaminu.",
                "2. Zabronione jest rozpowszechnianie materiału znajdującego się w serwisie bez zgody na piśmie operatorów serwisu.",
                "3. Zabrania się narażanie serwisu na starty finansowe poprzez dzielenie się kontem użytkownika z osobami nieupoważnionymi do posługiwania się nim."
            ]
        },
        {
            header:"§3. Świadczone usługi:",
            desc: 
            [
                "1. Rejestracja jest darmowa.",
                "2. Konto użytkownika dzieli się na dwa rodzaje: konto demo i konto premium.",
                "3. Zarejestrowany użytkownik ma dostęp zarówno do serwisu, jak i aplikacji mobilnej.",
                "4. Konto demo upoważnia do wykorzystywania serwisu z ograniczonym dostępem do treści. Użytkownik posiadający konto demo ma dostęp egzaminu składającego się z trzech stałych zestawów pytań egzaminacyjnych na kategorie B. Konto demo daje dostęp do pierwszych sekcji dla działów nauki: znaków i przepisów oraz dla pytań podstawowych i specjalistycznych.",
                "5. Konto demo upoważnia do wykorzystywania aplikacji mobilnej z pełnym dostępem do treści. Aplikacja mobilna dla użytkowników posiadających konto demo posiada reklamy.",
                "6. W celu rozszerzenia konta demo na konto premium należy wybrać pakiet i wpłacić określoną należność. Konto premium zostanie dodane w momencie przetworzenia przesłanej płatności za wybrany pakiet. Średni czas przetworzenia płatności wynosi poniżej minuty.",
                "7. Konto premium upoważnia do pełnego dostępu do serwisu oraz do aplikacji mobilnej bez reklam."
            ]
        },
        {
            header:"§4. Płatności:",
            desc: 
            [
                "1. Operatorem płatności w serwisie oraz aplikacji mobilnej są <a href=\"https://www.przelewy24.pl\">przelewy24</a>.",
                "2. Operatorem płatności w aplikacji mobilnej jest google play billing.",
                "3. Wszystkie płatności są jednorazowe i upoważniają do pełnego dostępu do serwisu oraz aplikacji mobilnej bez reklam na określony czas w wybranym pakiecie premium."
            ]
        },
        {
            header:"§5. Obowiązki i prawa operatorów serwisu:",
            desc: 
            [
                "1. Operator serwisu jest odpowiedzialny za zapewnienie prawidłowej funkcjonalności technicznej serwisu.",
                "2. Operator serwisu zastrzega sobie możliwość zmiany usług i rodzaju działania. Zalicza się do tego zaprzestanie działalności oraz usunięcie wszystkich zgromadzonych danych w bazie danych.",
                "3. Operator serwisu może czasowo wstrzymać dostępność do serwisu i aplikacji mobilnej w sytuacji od niego niezależnych.",
                "4. W przypadku gdy operator serwisu stwierdzi, że użytkownik celowo stara się utrudniać działanie serwisu może usunąć jego konto użytkownika.",
                "5. Operator serwisu zastrzega sobie prawo do tworzenia zmian w regulaminie w ważnych sytuacjach, takich jak m.in. zmiany w prawie, zmiany technologiczne w serwisie lub zmiany zasad korzystania z serwisu. Operator serwisu poinformuje o zmianie użytkowników za pomocą wiadomości e-mail.",
                "6. Operator serwisu zastrzega sobie możliwość usunięcia konta użytkownika w przypadku braku aktywności przez okres 6 miesięcy.",
                "7. Operator serwisu nie odpowiada za działanie systemów płatniczych.",
                "8. Operator serwisu zastrzega sobie prawo do zmiany cen za swoje usługi."
            ]
        },
        {
            header:"§6. Wymagania sprzętowe serwisu:",
            desc: 
            [
                "1. Do poprawnego działania serwisu wymagany jest komputer lub telefon z dostępem do internetu.",
                "2. Wymagana jest przeglądarka internetowa w przypadku korzystania z komputera lub przeglądarka mobilna w przypadku korzystania z serwisu za pomocą telefonu.",
                "3. Wspierane przeglądarki: Opera - minimalna wersja 44, Chrome - minimalna wersja 57, Mozilla Firefox - minimalna wersja 67, Microsoft Edge - minimalna wersja 80, Safari - minimalna wersja 11.1.",
                "4. W celu poprawnego działania aplikacji wymagane jest włączenie zapisywania plików cookies i innych danych witryny."
            ]
        },
        {
            header:"§7. Wymagania sprzętowe aplikacji mobilnej:",
            desc: 
            [
                "1. Do poprawnego działania aplikacji mobilnej wymagany jest telefon z systemem android.",
                "2. Aplikacja może działać bez dostępu do internetu."
            ]
        },
        {
            header:"§8. Reklamacje:",
            desc: 
            [
                "1. Reklamacje można składać na adres e-mail: kontakt@prawojazdy.pro.",
                "2. Operator serwisu zoobowiązuje się do rozpatrzenia reklamacji w terminie do 14 dni roboczych. Odpowiedź zostanie przesłana na adres e-mail podany w treści reklamacji.",
                "3. Rozwiązanie umowy zawartej poprzez kupno wybranego pakietu następuje w momencie usunięcia konta przez użytkownika w momencie, gdy posiadał aktywne konto premium lub w przypadku permanentnego braku możliwości świadczenia usług przez operatora serwisu spowodowanych przez niezależne przyczyny.",
                "4. Zakup konta premium jest treścią cyfrową co oznacza, że nie obowiązuje odstąpienie od umowy w terminie do 14 dni roboczych od jej zawarcie.",
                "5. Operator serwisu nie przyjmuję zwrotów zakupionych dostępów do konta premium. Użytkownik ma możliwość darmowego zweryfikowania usługi przed zdecydowaniem się na zakup pełnego dostępu do wszystkich funkcjonalności w aplikacji i do uzyskania wersji aplikacji mobilnej nieposiadającej reklam.",
                "6. Zwrot usługi jest możliwy w przypadku permanentnego problemu z prawidłowym funkcjonowaniem serwisu lub aplikacji mobilnej."
            ]
        }
    ]
}
