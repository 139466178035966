import React , { useState , useEffect } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import Question from '../../components/dashboard/question/Question';
import Layout from '../../components/dashboard/layout/Layout';
import Clue from '../../components/dashboard/clue/Clue';
import "./Dashboard.css";
import "../../components/dashboard/clue/Clue.css";
import { getActiveQuestion } from '../../redux/actions/wrongActions';
import { showToast , resetModalSettings } from '../../redux/actions/notificationActions';
import { useHistory } from "react-router-dom";
import SmallSpinner from '../../components/others/spinner/SmallSpinner';
import Separator from '../../components/dashboard/separator/Separator';

const Wrongs = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [loading , setLoading] = useState(true);

    const activeQuestion = useSelector((state) => state.wrongs.activeQuestion);
    const amountOfWrongs  = useSelector((state) => state.wrongs.amountOfWrongs);
    const user = useSelector((state) => state.user.user);

    const breadcrumb = [
        { text:'Błędy', link:'/błędy' },
    ]

    useEffect(() => {
        if(user !== null && amountOfWrongs !== null ){
            setLoading(true);

            if(activeQuestion === null){
    
                dispatch(getActiveQuestion()).then(() => {
                    if(activeQuestion !== null && activeQuestion !== undefined){
                        setLoading(false);
                    }          
                });
                
            }
            if(activeQuestion !== null && activeQuestion !== undefined){
                setLoading(false);
            }
            if((activeQuestion === null || activeQuestion === undefined) && amountOfWrongs === 0){
                handleCheckAccess();
            }
        }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeQuestion, user, amountOfWrongs])

    function handleCheckAccess(){
        
        if(amountOfWrongs === 0){
            history.push("/konto");
            dispatch(showToast("Brak zapisanych błędów." , "success")).then(() => {
                dispatch(resetModalSettings());
            })   
        }

    }

    return (
        <>
            {
                 loading && 
                 activeQuestion === null &&
                 user === null &&
                 <SmallSpinner 
                    isText={true}
                    isCenter={true}
                 />
            }
            {
                !loading &&
                activeQuestion !== null &&
                user !== null &&
                <Layout title={breadcrumb}>
                    <div className="Dashboard__flex">
                            <Question data={activeQuestion} type={"wrongs"}/>
                            <div className='DeparmentClue--flex'>
                            {
                                (activeQuestion.tip1 || activeQuestion.tip2 || activeQuestion.tip3 ) &&
                                <div className="DeparmentClue">
                                    {  activeQuestion.tip1 && <Clue link={activeQuestion.tip1Link} content={activeQuestion.tip1} />} 
                                    {  activeQuestion.tip2 && <><Separator/><Clue link={activeQuestion.tip2Link} content={activeQuestion.tip2} /></>} 
                                    {  activeQuestion.tip3 && <><Separator/><Clue link={activeQuestion.tip3Link} content={activeQuestion.tip3} /></>} 
                                </div>      
                            } 
                            </div>      
                    </div>      
                </Layout>
            }   
        </>
    )
}   

export default Wrongs
