import React from 'react';
import Item from '../item/Item';
import Layout from '../layout/Layout';

const Section = (props) => {

    return (
        <>
            <Layout title={props.title}>
                <div className='Item__Container'>
                {
                    props.data.map((item, i) =>     
                        <Item 
                            key={i} 
                            section={item.section} 
                            title={item.title} 
                            link={item.link} 
                            progress={props.progress === undefined ? null : props.progress.get(item.section)} 
                            color={props.colorOfProgressCircle}
                            messageForToast={props.messageForToast}
                            disabled={props.isActivePremium ? false : item.disabledInDemoVersion }
                            activeResetProgress={props.activeResetProgress}
                            type={props.type}
                        /> 
                    )
                }
                </div>
            </Layout>

        </>
    )
}

export default Section
