import React from 'react'
import './MockupSection.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import ReactHtmlParser from 'react-html-parser';

const MockupSection = (props) => {

    return (
        <div className="MockupSection">
            <Container fluid="xl">
                        <Row style={props.data.componentDirection}>
                                <Col md className='MockupSection__column'>
                                    <div className={ props.imageOnLeft ? "MockupSection__column-img--left" : "MockupSection__column-img--right"}>
                                        <Image  alt="Aplikacja mobilna prawojazdy.pro dla telefonów komórkowych z systemem operacyjnym android" src={props.data.img} fluid/>
                                    </div>
                                </Col>
                                <Col md>
                                    <div className="MockupSection__column-text">

                                        <div className="MockupSection__flex-text">
                                            <div className='icon-flex'>
                                                {
                                                    props.data.icons.map((item, key) =>
                                                        <div className="svg-rwd " key={key}>
                                                            <img src={item} alt="icon"/>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div>
                                                <h2>{props.data.headerText}</h2>
                                                <p>{ReactHtmlParser(props.data.paragraphText)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                        </Row>
            </Container>
        </div>
    )
}

export default MockupSection
