import { BasicQuestionsSection } from "../data/Sections/BasicQuestionsSection";
import { RegulationsSections } from "../data/Sections/RegulationsSections";
import { SignsSections } from "../data/Sections/SignsSections";
import { SpecialistQuestionsSection } from "../data/Sections/SpecialistQuestionsSection";
import history from "./history";

const ValidateAccessForUserWithoutPremium = ( section , selectedCategory ) => {
    let sections = BasicQuestionsSection.concat(SpecialistQuestionsSection(selectedCategory) , SignsSections , RegulationsSections);

    console.log(section);
    console.log(sections);

    try {

        if(Array.isArray(section))
        {
            if(!section.some(isNaN))
            {
                section = section.join(".");
                return CheckAccess(section , sections);
            }
            let sectionBySelectedCategory = section.find(s => s.includes(selectedCategory));
            return CheckAccess(sectionBySelectedCategory , sections);
        }

        return CheckAccess(section , sections);

    } catch (error) {
        history.push("/plan-premium");
        return false;
    }

}

function CheckAccess(section , sections){

    let result = sections.find(s => s.section === section);
    
    if(result.disabledInDemoVersion){
        history.push("/plan-premium");
        return false;
    }

    return true;

}

export default ValidateAccessForUserWithoutPremium;