import { ActionQuestionsTypes } from '../contants/action-types';
import agent from '../../api/agent';
import { UserProgressService } from '../../services/UserProgressService';
import { getAmountOfWrongs, resetActiveQuestion } from '../../redux/actions/wrongActions';
import store from '../store'
import { CryptoSha1 } from '../../helpers/Crypto';
import { addQuestionsAnswers } from './answersActions';

export const getQuestionsFromApi = () => async dispatch => {
    try{
        const questions = await agent.Questions.getQuestions();
        console.log(questions);
        localStorage.setItem("questions" , JSON.stringify(questions));
        dispatch( {
            type: ActionQuestionsTypes.SAVE_QUESTIONS,
            payload: questions
        })
    }catch (error){
        console.log(error)
    }
};

export const getQuestionsAnswersFromApi = () => async dispatch => {
    try{
        const answers = await agent.UserAnswers.getQuestions()
        console.log(answers.questionsAnswers);

        let answersString = JSON.stringify(answers.questionsAnswers);
        localStorage.setItem("questionsAnswers" , answersString);

        let answersChecksum = await CryptoSha1(answersString)
        let answersTimestamp = Date.now()      
        dispatch(saveQuestionsAnswersChecksumAndTimestamp(answersChecksum, answersTimestamp))

        dispatch( {
            type: ActionQuestionsTypes.SAVE_QUESTIONS_ANSWERS,
            payload: answers.questionsAnswers
        })
    }catch (error){
        console.log(error)
    }
};

export const saveQuestionsAnswersChecksumAndTimestamp = (answersChecksum, answersTimestamp) => {

    localStorage.setItem("questionsAnswersTimestamp", answersTimestamp)
    localStorage.setItem("questionsAnswersChecksum", answersChecksum)

    return{
        type: ActionQuestionsTypes.SAVE_QUESTIONS_ANSWERS_CHECKSUM_TIMESTAMP,
        payload: { answersChecksum , answersTimestamp }
    };

};

export const saveQuetionsAnswersLocal = (answersToSave) => async dispatch => {
    try{    
        const updatedQuestionsAnswers = store.getState().questions.questionsAnswers
        answersToSave.forEach((value, key) => {
            updatedQuestionsAnswers[key] = value
        })

        // console.log("saveQuetionsAnswersLocal updatedQuestionsAnswers")
        // console.log(updatedQuestionsAnswers)

        let answersString = JSON.stringify(updatedQuestionsAnswers)
        localStorage.setItem("questionsAnswers", answersString);
        
        let answersChecksum = await CryptoSha1(answersString)
        let answersTimestamp = Date.now()      
        dispatch(saveQuestionsAnswersChecksumAndTimestamp(answersChecksum, answersTimestamp))
    
        dispatch({
            type: ActionQuestionsTypes.SAVE_QUESTIONS_ANSWERS,
            payload: updatedQuestionsAnswers
        })
    }catch (error){
        console.log(error)
    }
};

export const computeProgressInQuestions = (category) => async dispatch => {
    try{

        console.log("computeProgressInQuestions")

        let selectedCategory;
    
        if (typeof category === 'undefined'){
            selectedCategory = store.getState().user.user.options.category;
        }else{
            selectedCategory = category;
        }
        
        let questionsAnswers = store.getState().questions.questionsAnswers;
        
        let questions = store.getState().questions.questions;
        let questionsProgress = new UserProgressService().combineProgressQuestionsSections(questions , questionsAnswers, selectedCategory);
    
        dispatch(getAmountOfWrongs());
    
        dispatch({
            type: ActionQuestionsTypes.UPDATE_PROGRESS_IN_QUESTIONS,
            payload: questionsProgress
        })

    }catch(error){
        console.log(error);
    }
};

export const resetAnswersQuestionsLocal = () => async dispatch => {
    try{
        const questionsAnswers = store.getState().questions.questionsAnswers;
        const newQuestionsAnswers = {...questionsAnswers};
        
        Object.keys(newQuestionsAnswers).forEach(function(key){ newQuestionsAnswers[key] = 0}); 
    
        let answersString = JSON.stringify(newQuestionsAnswers)
        localStorage.setItem("questionsAnswers", answersString);
        
        let answersChecksum = await CryptoSha1(answersString)
        let answersTimestamp = Date.now()      
        dispatch(saveQuestionsAnswersChecksumAndTimestamp(answersChecksum, answersTimestamp)) 
    
        dispatch({
            type: ActionQuestionsTypes.SAVE_QUESTIONS_ANSWERS,
            payload: newQuestionsAnswers
        })
    }catch(error){
        console.log(error);
    }
}

export const deleteProgresForSection = (section, type) => async dispatch => {

    try{

        let questionsIds;
        const category = store.getState().user.user.options.category;
        const questionsAnswers = store.getState().questions.questionsAnswers;
        const questions = store.getState().questions.questions;

        if (type === 'p'){
            questionsIds = getQuestionIdsForBasicQuestions(category, questions, section);
        }else{
            questionsIds = getQuestionIdsForSpecialistQuestions(category, questions, section);
        }

        const questionsIdsToResetProgress = questionsIds.filter(id => questionsAnswers[id.toString()] !== 0);

        const questionsToReset = new Map();

        questionsIdsToResetProgress.forEach(id => {
            questionsToReset.set(id.toString(), 0);
        })

        dispatch(addQuestionsAnswers(questionsToReset, true));
        dispatch(resetActiveQuestion());

        dispatch({
            type: ActionQuestionsTypes.DELETE_PROGRESS_FOR_SECTION,
            payload: null
        })  

    }catch(error){
        console.log(error);
    }
}

const getQuestionIdsForBasicQuestions = (category, questions, section) => {
    return questions.filter(q => 
                                q.category.includes(category) && 
                                q.type === 'p' && 
                                q.section === section
                            ).map((item) => {return item.id});  
}

const getQuestionIdsForSpecialistQuestions = (category, questions, section) => {
    let numberFromSection = section.match(/\d+/)[0];

    if (numberFromSection === '1') {
        return questions.filter(q => 
                                    q.section.includes(numberFromSection) && 
                                    q.section.length === 2 && 
                                    q.category.includes(category) && 
                                    q.type === 's'
                                ).map((item) => {return item.id});  
    }

    return questions.filter(q => 
                                q.section.includes(numberFromSection) && 
                                q.category.includes(category) && 
                                q.type === 's'
                            ).map((item) => {return item.id});  
}