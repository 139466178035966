import React from 'react'
import { Link } from 'react-router-dom'
import './LayoutLink.css'

const LayoutLink = (props) => {

    if(props.data !== null && props.data !== undefined)
    return (
        <div className='LayoutLink__Container' style={{ marginBottom: props.withoutBottomMargin ? "0px" : "15px"}}>
            {
                props.data.map((item, index) =>     
                    index !== props.data.length - 1 ?
                        <Link key={index} to={item.link}>{item.text}</Link>
                    :
                        <p key={index}>{item.text}</p>
                )
            }

        </div>
    )
    else
    return(
        <>
        </>
    )
}

export default LayoutLink
