import { Section } from '../../models/Section';

export const BasicQuestionsSection = [
    new Section("O", "W1", "1. Znaki ostrzegawcze",  '/dzialy/czesc-podstawowa/ostrzegawcze-znaki' , false),
    new Section("O", "W2", "2. Znaki zakazu, nakazu",  '/dzialy/czesc-podstawowa/znaki-zakazu-nakazu' , true),
    new Section("O", "W3", "3. Znaki informacyjne, kierunku i miejscowości, uzupełniające",  '/dzialy/czesc-podstawowa/znaki-informacyjne-kierunku-i-miejscowosci-uzupełniajace' , true),
    new Section("O", "W4", "4. Znaki drogowe poziome",  '/dzialy/czesc-podstawowa/znaki-drogowe-poziome' , true),
    new Section("O", "W5", "5. Sygnały świetlne, sygnały dawane przez kierującego ruchem", '/dzialy/czesc-podstawowa/sygnaly-swietlne-sygnaly-dawane-przez-kierujacego-ruchem' , true),
    new Section("O", "W6", "6. Włączanie się do ruchu, skrzyżowania równorzędne",  '/dzialy/czesc-podstawowa/wlaczanie-sie-do-ruchu-skrzyzowania-rownorzedne' , true),
    new Section("O", "W7", "7. Skrzyżowania ze znakami określającymi pierwszeństwo przejazdu",  '/dzialy/czesc-podstawowa/skrzyzowania-ze-znakami-okreslajacymi-pierwszenstwo-przejazdu' , true), 
    new Section("O", "W8", "8. Skrzyżowania z sygnalizacją świetlną",  '/dzialy/czesc-podstawowa/skrzyzowania-z-sygnalizacja-swietlna' , true),
    new Section("O", "W9", "9. Skrzyżowania lub przejścia dla pieszych z kierujących ruchem, miejsca przystanków komunikacji publicznej",  '/dzialy/czesc-podstawowa/skrzyzowania-lub-przejscia-dla-pieszych-z-kierujacych-ruchem' , true),
    new Section("O", "W10", "10. Pozycja pojazdu na drodze, wjazd i zjazd ze skrzyżowania, zatrzymanie i postój",  '/dzialy/czesc-podstawowa/pozycja-pojazdu-na-drodze-wjazd-i-zjazd-ze-skrzyzowania-zatrzymanie-i-postoj' , true),
    new Section("O", "W11", "11. Zmiana pasa ruchu, zmiana kierunku jazdy",  '/dzialy/czesc-podstawowa/zmiana-pasa-ruchu-zmiana-kierunku-jazdy' , true),
    new Section("O", "W12", "12. Wyprzedzanie", '/dzialy/czesc-podstawowa/wyprzedzanie' , true),
    new Section("O", "W13", "13. Omijanie, wymijanie, cofanie", '/dzialy/czesc-podstawowa/omijanie-wymijanie-cofanie' , true), 
    new Section("O", "W14", "14. Używanie świateł zewnętrznych i sygnałów pojazdu",  '/dzialy/czesc-podstawowa/uzywanie-swiatel-zewnetrznych-i-sygnalow-pojazdu' , true),
    new Section("O", "W15", "15. Znaczenie zachowania szczególnej ostrożności w stosunku do innych użytkowników drogi, wysiadanie z pojazdu, zabezpieczenie pojazdu",  '/dzialy/czesc-podstawowa/znaczenie-zachowania-szczegolnej-ostroznosci' , true),
    new Section("O", "W16", "16. Zachowanie wobec pieszego, wobec osoby o ograniczonej możliwości poruszania się",  '/dzialy/czesc-podstawowa/zachowanie-wobec-pieszego' , true), 
    new Section("O", "W17", "17. Zachowanie wobec rowerzysty i dzieci",  '/dzialy/czesc-podstawowa/zachowanie-wobec-rowerzysty-i-dzieci' , true),
    new Section("O", "W18", "18. Zachowanie na przejazdach kolejowych i tramwajowych" ,  '/dzialy/czesc-podstawowa/zachowanie-na-przejazdach-kolejowych-i-tramwajowych' , true),
    new Section("O", "W19", "19. Ogólne zasady określające zachowanie kierowcy w momencie awarii lub wypadku, udzielanie pierwszej pomocy przedmedycznej",  '/dzialy/czesc-podstawowa/ogolne-zasady-okreslajace-zachowanie-kierowcy' , true),
    new Section("O", "W20", "20. Spostrzeganie, ocena sytuacji i podejmowanie decyzji, szczególnie w zakresie czasu reakcji oraz zmian w zachowaniu za kierownicą, spowodowanych wpływem alkoholu, leków i produktów leczniczych", '/dzialy/czesc-podstawowa/spostrzeganie-ocena-sytuacji-i-podejmowanie-decyzji' , true)
]