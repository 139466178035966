import { RegulationsSections } from '../../data/Sections/RegulationsSections';
import { ActionRegulationsTypes } from '../contants/action-types';
import { getLocalStorageItemOrDefault, getLocalStorageItemParsedJsonOrDefault } from '../../helpers/GetLocalStorageItemOrDefault';
import 'core-js/es/object/from-entries';

const initialState = {
    regulationsSections: RegulationsSections ,
    regulations: getLocalStorageItemParsedJsonOrDefault('regulations', []),
    regulationsAnswers: getLocalStorageItemParsedJsonOrDefault('regulationsAnswers', []),
    regulationsAnswersChecksum: getLocalStorageItemOrDefault('regulationsAnswersChecksum', ""),
    regulationsAnswersTimestamp: getLocalStorageItemOrDefault('regulationsAnswersTimestamp', ""),
    regulationsProgress: {}
}

export const regulationReducer = ( state = initialState, { type , payload } ) => {

    switch (type) {
        case ActionRegulationsTypes.SAVE_REGULATIONS:
            return {
                ...state,
                regulations: payload
            }
        case ActionRegulationsTypes.SAVE_REGULATIONS_ANSWERS:
            return {
                ...state,
                regulationsAnswers: payload,
            }
        case ActionRegulationsTypes.SAVE_REGULATIONS_ANSWERS_CHECKSUM_TIMESTAMP:
            return {
                ...state,
                regulationsAnswersChecksum: payload.answersChecksum,
                regulationsAnswersTimestamp: payload.answersTimestamp,
            }
        case ActionRegulationsTypes.UPDATE_PROGRESS_IN_REGULATIONS:
            return {
                ...state,
                regulationsProgress: Object.fromEntries(payload)
            }
        default: return state
    }
};