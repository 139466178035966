import React from 'react'
import './CustomCheckbox.css'

const CustomCheckbox = (props) => {
    return (
        <div className='CustomCheckbox'>
            <label>
                <input 
                name={props.name}
                type={props.type}
                onChange={props.onChange}
                checked={!!props.isChecked}
                value={props.name || ''}
                />
                {props.text}
            </label>
        </div>
    )
}

export default CustomCheckbox
