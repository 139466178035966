import React, { useEffect, useState, useCallback } from 'react';
import './AccountInformation.css';
import AccountPremiumInformation from '../accountPremiumInformation/AccountPremiumInformation';
import AccountQuickStart from '../accountQuickStart/AccountQuickStart';
import { AiOutlineUser , AiOutlineCar } from "react-icons/ai";
import { FaFacebookSquare } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";

const AccountInformation = (props) => {

    const [ userDisplayName , setUserDisplayName ] = useState();
    const [ providerId ] = useState(props.user.providerData[0].providerId);

    const showUserDisplayName = useCallback(() => {
        if(providerId === "google.com" || providerId === 'facebook.com'){
            setUserDisplayName(props.user.displayName);
        }else if(providerId === "password"){
            setUserDisplayName(props.user.email)
        }
    }, [providerId, props.user.displayName, props.user.email])

    useEffect(() => {
        showUserDisplayName();
    }, [showUserDisplayName])

    let providerIcon = <AiOutlineUser className='ProviderIcon--blue'/>;
    if(providerId === "google.com"){
        providerIcon = <FcGoogle className='ProviderIcon--white'/>;
    }else if(providerId === 'facebook.com'){
        providerIcon = <FaFacebookSquare className='ProviderIcon--white'/>;
    }

    return (
        <div className='AccountInformation__Container'>
            <div className='AccountInformation__Header'>
                <div className='AccountInformation__Header--left'>
                    {providerIcon}
                    <div>
                        <p>
                            <span style={{fontWeight: 500}}>Zalogowany jako: </span>
                            <br/>
                            {userDisplayName}
                        </p>
                    </div>
                </div>
                <div className='AccountInformation__Header--right'>
                    <AiOutlineCar />
                    <p>
                        <span style={{fontWeight: 500}}>Wybrana kategoria: </span>
                        <br />
                        {props.selectedCategory}
                    </p>
                </div>
            </div>
            <div className="AccountInformation__Separator" />
                <AccountPremiumInformation />             
            <div className="AccountInformation__Separator" />   
                <AccountQuickStart />            
        </div>
    )
}

export default AccountInformation

