import React from 'react';
import { useSelector } from 'react-redux';
import Alert from '../../others/alert/Alert';

const DemoExamsCounter = () => {

    const demoUsedExams = useSelector((state) => state.user.user.demoUsedExams);
    const isActivePremium = useSelector((state) => state.user.isActivePremium);

    if(isActivePremium)
    return(
        <></>
    )
    else
    return (
        <Alert 
            type="DemoExamsCounterInformation"
            demoUsedExams={demoUsedExams}
        />
    )
}

export default DemoExamsCounter