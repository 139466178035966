import React, { useEffect , useState } from 'react';
import Layout from '../../components/dashboard/layout/Layout';
import SelectedPremiumInformation from '../../components/paymentSummary/selectedPremiumInformation/SelectedPremiumInformation';
import Summary from '../../components/paymentSummary/summary/Summary';
import { useParams, useHistory } from "react-router-dom";
import { PricingColumnData } from '../../data/PricingColumnData';
import SmallSpinner from '../../components/others/spinner/SmallSpinner';

const PaymentSummary = () => {

    const { id } = useParams();
    const history = useHistory();

    const [ premiumData , setPremiumData ] = useState();
    const [ loading, setLoading ] = useState(true);

    const breadcrumb = [
        { text:'Podsumowanie płatności', link:'/podsumowanie-platnosci' },
    ]

    useEffect(() => {

        const data = PricingColumnData.find(item => item.id === id);

        if(data === undefined){
            history.push('/plan-premium');
        }else{
            setPremiumData(data);
            setLoading(false);
        }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    if(loading)
    return(
        <SmallSpinner 
            isText={true}
            isCenter={true}
        />
    )
    else
    return(
        <Layout title={breadcrumb}>
        <div className="Dashboard__account Dashboad__paymentSummary">   
            <div className='Dashboard__account--left Dashboad__paymentSummary--left'>
                <SelectedPremiumInformation 
                    premiumData={premiumData}
                />
            </div> 
                <Summary 
                    premiumData={premiumData}
                />
        </div>
    </Layout>
    )
}

export default PaymentSummary