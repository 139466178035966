import React , { useState , useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import './Question.css';
import CustomButton from '../../../components/others/button/CustomButton';
import ReactPlayer from 'react-player/lazy';
import { useSelector, useDispatch  } from 'react-redux';
import { addQuestionsAnswers } from '../../../redux/actions/answersActions'
import { sendAnswerToExam, setNextActiveQuestion, setIsMovieEnded , setSelectedAnswer } from '../../../redux/actions/examActions';
import { useToastManager } from '../../../contexts/ToastManagerContext';
import { deleteQuestionFromWrong , getActiveQuestion , moveQuestionToEndOfList } from '../../../redux/actions/wrongActions';
import { setActiveQuestion , deleteQuestionFromExList , moveQuestionFromExList } from '../../../redux/actions/departamentActions';
import SmallSpinner from '../../others/spinner/SmallSpinner';
import ExamInformationItem from '../../exams/examInformationItem/ExamInformationItem';
import ExamTimer from '../../exams/examTimer/ExamTimer';
import { showModal , closeModal } from '../../../redux/actions/notificationActions';
import { ModalContent } from '../../../models/ModalContet';
import QuestionCounter from '../../exams/questionCounter/QuestionCounter';
import EndExamButton from '../../exams/endExamButton/EndExamButton';
import QuestionTimer from '../../exams/questionTimer/QuestionTimer';
import usePrevious from '../../../helpers/customHooks/UsePrevious';

const Question = (props) => {

    let modalContent = new ModalContent(
        "Success",
        "Dobra odpowiedź!",
        "Usunąć z grupy pytań na które udzieliłeś błędnej odpowiedzi?",
        "Nie",
        () => moveQuestion(props.data.id),
        "Tak",
        () => deleteQuestion(props.data.id)
    );

    let link = ""
    if(props.data !== undefined && props.data.media !== undefined && props.data.media.length > 0){
        link = 'https://prawojazdy.pro/media/' + props.data.media;
    } 

    const dispatch = useDispatch();
    const isStartMovieInExam = useSelector((state) => state.exam.isStartMovie);
    const currentIndex = useSelector((state) => state.exam.currentIndex);
    const selectedAnswer = useSelector((state) => state.exam.selectedAnswer);
    const baseQuestionNumber = useSelector((state) => state.exam.baseQuestionNumber);
    const specialistQuestionNumber = useSelector((state) => state.exam.specialistQuestionNumber);
    const autoPlayEnabled = useSelector((state) => state.user.user.options.autoPlay);
    const timeInExamEnabled = useSelector((state) => state.user.user.options.timeInExam);

    const { showToast } = useToastManager();
    const [ loading , setLoading ] = useState(false);
    const [ idOfActiveButton  , setIdOfActiveButton ] = useState(0);
    const [ examForSmallDevices , setExamForSmallDevices] = useState(false);
    const [ resetTimer , setResetTimer ] = useState();
    const previousQuestionMedia = usePrevious(props.data.media);

    function getExtraClass(number){
       switch(props.type){
            case "examSummary":
                let color = "inactive";
                if(props.data.trueAnswer === number){
                    color = "green--inactive";
                }
                if(props.data.userAnswer === number && props.data.userAnswer !== props.data.trueAnswer){
                    color = "red--inactive";
                }
                return color;
            case "exam":
                return "exam";
            default:
                return undefined;
       }
    }

    function selectAnswer(e){

        let selectedAnswer = parseInt(e.target.value);
        let answer = new Map();
        answer.set(props.data.id.toString() , props.data.trueAnswer === selectedAnswer ? 2 : 1 );

        switch(props.type) {
            case 'exam':
                dispatch(setSelectedAnswer(selectedAnswer));
                setIdOfActiveButton(selectedAnswer);
                break;
            case 'wrongs':
                displayTosat(selectedAnswer)
                if(props.data.trueAnswer === selectedAnswer){
                    dispatch(showModal(modalContent));
                }
                break;
            case 'examSummary':
                break;
            default:
                displayTosat(selectedAnswer)
                dispatch(addQuestionsAnswers(answer))
                const type = props.data.type === 'p' ? 'basicQuestions' : 'specialistQuesions';
                dispatch(setActiveQuestion(type))
                break;
            }
    }

    function displayTosat (selectedAnswer){
        if(props.data.trueAnswer === parseInt(selectedAnswer)){
            showToast('success');
            dispatch(deleteQuestionFromExList(props.data.id.toString()));
        }else{
            showToast('error');
            dispatch(moveQuestionFromExList(props.data.id.toString()));
        }
    }

    function deleteQuestion(id){
        dispatch(deleteQuestionFromWrong(id)).then(() => {
          dispatch(addQuestionsAnswers(new Map([[id.toString(), 2]])))
            dispatch(getActiveQuestion());
            dispatch(closeModal());
        }) 
    }
    
    function moveQuestion(id){
        dispatch(moveQuestionToEndOfList(id)).then(() => {
            dispatch(getActiveQuestion());
            dispatch(closeModal());
        })
    }

    useEffect(() => {
        if(window.innerWidth <= 1023 && props.type === 'exam' && !examForSmallDevices)
            setExamForSmallDevices(true);
           
        if(previousQuestionMedia !== props.data.media){
            
            if(props.type !== 'exam'){
                setLoading(true);
            }
            
            if(props.type === 'exam'){
                setLoading(true);
                if(previousQuestionMedia === undefined || (previousQuestionMedia.includes("mp4") && props.data.media.includes("mp4"))){
                    setTimeout(() => setLoading(false), 250)
                }
            }  
        }

        if(previousQuestionMedia === props.data.media && previousQuestionMedia.includes("mp4") && props.data.media.includes("mp4")){
            setLoading(true);
            setTimeout(() => setLoading(false), 250);
        }
            
        setIdOfActiveButton(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data] )

    function handleMovieEndedDuringExam(){
        if(props.type === 'exam'){
            dispatch(setIsMovieEnded(true));
        }  
    }

    useEffect(() => {
        if(props.type === 'exam'){
            function handleResize(){
                if(window.innerWidth <= 1023 && !examForSmallDevices)
                    setExamForSmallDevices(true);                        
                if(window.innerWidth >= 1024 && examForSmallDevices)
                    setExamForSmallDevices(false);              
            }
            
            window.addEventListener('resize', handleResize)
    
            return _ => { window.removeEventListener('resize', handleResize) }
        }
    })
  
    function handleNextQuestion(){
        dispatch(sendAnswerToExam(selectedAnswer));
        dispatch(setNextActiveQuestion(false));
        setResetTimer(!resetTimer);
    }

    return (
        <>
        { props.data &&
        <>
            <div className="DepartmentQuestion__container">
                {
                    props.type === 'exam' && 
                    <>
                    {
                        examForSmallDevices && 
                        <div className='EndExamButton__container'>
                            <EndExamButton finishExam={props.finishExam}/>
                        </div>                  
                    }
                    <div className='QuestionCounter__Container--Question'>
                        <QuestionCounter text={"Pytania podstawowe"} numberOfQuestion={baseQuestionNumber} maxNumberOfQuestion={20}/>
                        <QuestionCounter text={"Pytania specjalistyczne"} numberOfQuestion={specialistQuestionNumber} maxNumberOfQuestion={12}/>
                    </div>
                    {
                        examForSmallDevices && timeInExamEnabled && 
                        <QuestionTimer questionMedia={props.data.media} resetTimer={resetTimer} type={props.data.type}/>
                    }  
                    <div className='InformationDuringExam__Top'>              
                            <ExamInformationItem text={"Wartość punktowa"} value={props.data.points} IsHidden={true}/>
                            <ExamInformationItem text={"Aktualna kategoria"} value={props.selectedCategory} IsHidden={true}/>
                        {
                            examForSmallDevices &&
                            <QuestionCounter numberOfQuestion={currentIndex + 1} maxNumberOfQuestion={32} IsHidden={true}/>
                        }
                        {
                            timeInExamEnabled &&
                            <ExamTimer finishExam={props.finishExam}/>
                        }
                    </div>
                    </>  
                }
                <div className="DepartmentQuestion__media" style={loading ? {display: "flex"} : {} }>
                        {
                            loading &&
                            <div className='DepartmentQuestion__LoadingMedia'>
                                <SmallSpinner isText = {true} />
                            </div> 
                        }      
                        {
                            link.includes("mp4") 
                            && props.type === 'exam' 
                            && !timeInExamEnabled
                            &&
                            <> 
                                <ReactPlayer 
                                    key={props.data.id}
                                    url={link} 
                                    playing={autoPlayEnabled} 
                                    controls={true} 
                                    muted={true} 
                                    onEnded={() => handleMovieEndedDuringExam()}
                                    width="100%"
                                    height="auto"
                                    style={!loading ? {} : { display: 'none' }}
                                    onReady={() => setTimeout(() => setLoading(false), 100)}
                                /> 
                            </>
                        }              
                        {
                            link.includes("mp4") 
                            && isStartMovieInExam 
                            && props.type === 'exam' 
                            && timeInExamEnabled
                            &&
                            <> 
                                <ReactPlayer 
                                    key={props.data.id}
                                    url={link} 
                                    playing={autoPlayEnabled} 
                                    controls={true} 
                                    muted={true} 
                                    onEnded={() => handleMovieEndedDuringExam()}
                                    width="100%"
                                    height="auto"
                                    style={!loading ? {} : { display: 'none' }}
                                    onReady={() => setTimeout(() => setLoading(false), 100)}
                                /> 
                            </>
                        } 
                        {
                            link.includes("mp4") 
                            &&  !isStartMovieInExam 
                            && props.type === 'exam' 
                            && timeInExamEnabled 
                            &&
                            <> 
                                <Image 
                                    style={!loading ? {} : { display: 'none' }}
                                    alt="Grafika przed uruchomieniem filmu podczas egzaminu"
                                    src={"https://prawojazdy.pro/media/playweb.jpg"} 
                                    fluid 
                                    onLoad={() => setTimeout(() => setLoading(false), 100)}
                                />
                            </>
                        }
                        {
                            link.includes("mp4") && props.type !== 'exam' && 
                            <> 
                                <ReactPlayer 
                                    key={props.data.id}
                                    url={link} 
                                    playing={autoPlayEnabled} 
                                    controls={true} 
                                    muted={true} 
                                    width="100%"
                                    height="auto"
                                    style={!loading ? {} : { display: 'none' }}
                                    onReady={() => setTimeout(() => setLoading(false), 100)}
                                />
                            </>
                        } 
                        {
                            link.includes("jpg") && 
                            <> 
                                <Image 
                                    style={!loading ? {} : { display: 'none' }}
                                    src={link} 
                                    alt="Oficjalne zdjęcie powiązane z pytaniem"
                                    fluid 
                                    onLoad={() => setTimeout(() => setLoading(false), 100)}                                
                                />
                            </>
                        }
                        {
                            link === "" && 
                            <> 
                                <Image 
                                    style={!loading ? {} : { display: 'none' }}
                                    src={"https://prawojazdy.pro/media/brak.jpg"} 
                                    alt="Brak mediów dla pytania"
                                    fluid 
                                    onLoad={() => setTimeout(() => setLoading(false), 180)}
                                />
                            </>
                        }
                </div>
                <div className="DepartmentQuestion__question">
                    <p>{props.data.question}</p>
                </div>
                <div className="DepartmentQuestion__buttonContainer">
                    {
                        props.data.type === 'p' && 
                        <div className='ButtonContainer__basic'>
                            <CustomButton value={1} color={getExtraClass(1)} content={"Tak"} onClick={selectAnswer} idOfActiveButton={idOfActiveButton}></CustomButton> 
                            <CustomButton value={2} color={getExtraClass(2)} content={"Nie"} onClick={selectAnswer} idOfActiveButton={idOfActiveButton}></CustomButton>
                        </div>
                    }
                    {
                        props.data.type === 's' && 
                        <div className='ButtonContainer__specialized'>
                            <CustomButton value={1} color={getExtraClass(1)} content={props.data.answer1} onClick={selectAnswer} idOfActiveButton={idOfActiveButton}></CustomButton>
                            <CustomButton value={2} color={getExtraClass(2)} content={props.data.answer2} onClick={selectAnswer} idOfActiveButton={idOfActiveButton}></CustomButton>
                            <CustomButton value={3} color={getExtraClass(3)} content={props.data.answer3} onClick={selectAnswer} idOfActiveButton={idOfActiveButton}></CustomButton>
                        </div>
                    }
                    {
                        props.type === 'exam' && examForSmallDevices && 
                        <div>
                            <CustomButton 
                            content={'Następne pytanie'} 
                            widthInPercent={100}
                            onClick={() => handleNextQuestion()}
                            />  
                        </div>                  
                    }
                </div>

            </div>
        </>
        
        }
        </>
    )
}

export default Question
