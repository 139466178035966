import React from 'react';
import './SelectedPremiumInformation.css';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';

const SelectedPremiumInformation = (props) => {
  
  let BulletPointList =  props.premiumData.bulletPointList.map((item, i) =>
    <li key={i}>{item}</li>
  )

  return (
    <div className='SelectedPremiumInformation__Container'>
        <h6>Wybrany pakiet:</h6>
        <div className='SelectedPremiumInformationItem'>
            <div className='SelectedPremiumInformationItem__Header'>
                <h6>{props.premiumData.firstText}</h6>
                <IoCheckmarkCircleOutline />
            </div>
            <p>{props.premiumData.secondText}</p>
            <ul className='ul--confirmation'>
                {BulletPointList}
            </ul>
        </div>
    </div>
  )
}

export default SelectedPremiumInformation