import { SignsSections } from '../data/Sections/SignsSections';
import { RegulationsSections } from '../data/Sections/RegulationsSections';
import { BasicQuestionsSection } from '../data/Sections/BasicQuestionsSection';
import { SpecialistQuestionsSection } from '../data/Sections/SpecialistQuestionsSection';

const basicQuestionsSection = BasicQuestionsSection
const signsSections = SignsSections.filter(s => s.section !== 'title').map(s => s.section);
const regulationsSections = RegulationsSections.filter(s => s.section !== 'title').map(s => s.section);

export class UserProgressService {
    
    combineProgressQuestionsSections(questions , questionsAnswers, category){
        //console.log("combineProgressQuestionsSections")
        let questionsSectionProgress = new Map();
    
        basicQuestionsSection.forEach( section => {

            let sectionCount = questions.filter(q => q.section === section.section && q.category.includes(category) && q.type === 'p').length;
            let sectionCountTrueAnswers = questions.filter(q => q.section === section.section && q.category.includes(category) && questionsAnswers[q.id.toString()] === 2 && q.type === 'p').length;
            let progress = this.computeProgressInPercent(sectionCount, sectionCountTrueAnswers);
                
            questionsSectionProgress.set(section.section , progress);
        })

        const specialistQuestionsSection = SpecialistQuestionsSection(category);

        specialistQuestionsSection.forEach( section => {

            let numberFromSection = section.section.match(/\d+/)[0];
            let sectionCount;
            let sectionCountTrueAnswers;

            if (numberFromSection === '1'){
                sectionCount = questions.filter(q => q.section.includes(numberFromSection) &&
                                                     q.section.length === 2 && 
                                                     q.type === 's' &&
                                                     q.category.includes(category)).length;


                sectionCountTrueAnswers = questions.filter(q => q.section.includes(numberFromSection) &&
                                                                q.section.length === 2 &&
                                                                q.type === 's' &&
                                                                q.category.includes(category) &&
                                                                questionsAnswers[q.id.toString()] === 2).length;
            }else{
                sectionCount = questions.filter(q => q.section.includes(numberFromSection) &&
                                                     q.type === 's' &&
                                                     q.category.includes(category)).length;


                sectionCountTrueAnswers = questions.filter(q => q.section.includes(numberFromSection) &&
                                                                q.category.includes(category) &&
                                                                q.type === 's' &&
                                                                questionsAnswers[q.id.toString()] === 2).length;
            }

            let progress = this.computeProgressInPercent(sectionCount, sectionCountTrueAnswers);
            //console.log("section:"+section.section+" count:"+sectionCount+" countTrue:"+sectionCountTrueAnswers+" progress:"+progress)
            questionsSectionProgress.set(section.section , progress);
        })

        let allCount = questions.filter(q => q.category.includes(category)).length;
        let allCountTrueAnswers = questions.filter(q => q.category.includes(category) && questionsAnswers[q.id.toString()] === 2).length;
        let allProgress = this.computeProgressInPercent(allCount , allCountTrueAnswers);
        questionsSectionProgress.set('all' , allProgress);

        let allCountBasic = questions.filter(q => q.type === 'p' && q.category.includes(category)).length;
        let allCountBasicTrueAnswers = questions.filter(q => q.type === 'p' && q.category.includes(category) && questionsAnswers[q.id.toString()] === 2).length;
        let allProgressBasic = this.computeProgressInPercent(allCountBasic , allCountBasicTrueAnswers);
        questionsSectionProgress.set('basic' , allProgressBasic);

        let allCountSpecialist = questions.filter(q => q.type === 's' && q.category.includes(category)).length;
        let allCountSpecialistTrueAnswers = questions.filter(q => q.type === 's' && q.category.includes(category) && questionsAnswers[q.id.toString()] === 2).length;
        let allProgressSpecialist = this.computeProgressInPercent(allCountSpecialist , allCountSpecialistTrueAnswers);
        questionsSectionProgress.set('specialist' , allProgressSpecialist);

        return questionsSectionProgress;
    }

    combineProgressSignsSections(signs , signsAnswers){
        //console.log("combineProgressSignsSections")
 
        let signsSectionProgress = new Map();
        signsSections.forEach( section => {
            let sectionCount = signs.filter(s => s.section === section).length;
            let sectionCountTrueAnswers = signs.filter(s => s.section === section && signsAnswers[s.id.toString()] !== 0).length;
            let progress = this.computeProgressInPercent(sectionCount, sectionCountTrueAnswers);
            signsSectionProgress.set(section , progress);
        })

        let allCountTrueAnswers = signs.filter(s => signsAnswers[s.id.toString()] !== 0).length;
        let allProgress = this.computeProgressInPercent(signs.length , allCountTrueAnswers);
        signsSectionProgress.set('signs' , allProgress);
        return signsSectionProgress;
    }


    combineProgressRegulationsSections(regulations , regulationsAnswers){
        //console.log("combineProgressRegulationsSections")
        let regulationsSectionProgress = new Map();
        regulationsSections.forEach( section => {
            if(section.includes("title") || section.length < 3) {
                return;
            }
            
            let mainSection = parseInt(section.substring(0, 1));
            let subSection = parseInt(section.substring(2));
            if(mainSection != null && subSection != null){
                let sectionCount = regulations.filter(r => r.type === 'p' && r.section === mainSection && r.subSection === subSection).length;
                let sectionCountTrueAnswers = regulations.filter(r => r.type === 'p' && r.section === mainSection && r.subSection === subSection && regulationsAnswers[r.id.toString()] !== 0).length;
                let progress = this.computeProgressInPercent(sectionCount, sectionCountTrueAnswers);
                //console.log("main:" + mainSection + " subSection:" +subSection +" sectionCount:"+sectionCount+" sectionCountTrueAnswers:"+sectionCountTrueAnswers +" progress:"+progress)
                regulationsSectionProgress.set(section , progress);
            }
        })

        let allCount = regulations.filter(r => r.type === 'p').length;
        let allCountTrueAnswers = regulations.filter(r => r.type === 'p' && regulationsAnswers[r.id.toString()] !== 0).length;
        let allProgress = this.computeProgressInPercent(allCount, allCountTrueAnswers);
        regulationsSectionProgress.set('regulations' , allProgress);

        return regulationsSectionProgress;
    }

    combineProgressLearning(regulations , regulationsAnswers, signs , signsAnswers){
   
        let allCountRegulations = regulations.filter(r => r.type === 'p').length;
        let allCountRegulationsTrueAnswers = regulations.filter(r => r.type === 'p' && regulationsAnswers[r.id.toString()] !== 0).length;
        
        let allCountSigns = signs.length
        let allCountSignsTrueAnswers = signs.filter(s => signsAnswers[s.id.toString()] !== 0).length;

        let allCountEducation = allCountRegulations + allCountSigns
        let allCountEducationTrueAnswers = allCountRegulationsTrueAnswers + allCountSignsTrueAnswers

        let allEducationProgress = this.computeProgressInPercent(allCountEducation, allCountEducationTrueAnswers);
        return allEducationProgress;
    }
   
    computeProgressInPercent(all , solved){
        if(all === 0) return 0;
        if(all === solved) return 100;
        let onePercent = all/100.0;
        return Math.floor(solved/onePercent);
    }


}
