import React from 'react'
import './InformationSection.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { InformationSectionBoxData } from '../../../data/InformationSectionBoxData'
import InformationSectionBox from './InformationSectionBox'

const InformationSection = () => {

    let informationSectionBoxList =  InformationSectionBoxData.map((item) =>
        <InformationSectionBox data={item} key={item.id}/>
    )

    return (
        <div className="InformationSection">
            <Container fluid="xl">
                    <div className="InformationSection__header">
                            <p>Profesjonalnie Cię przygotujemy</p>
                    </div>
                    <div className="Section__container">
                        <Row>
                            {informationSectionBoxList}
                        </Row>
                    </div>
            </Container>
        </div>
    )
}

export default InformationSection
