import store from '../redux/store';
import { showModal } from '../redux/actions/notificationActions'
import { createAlertForDisabledLocalStorage } from '../models/ModalContet';

export function CheckLocalstorageIfEnable(){
    let test = "test";
    try{
        localStorage.setItem(test , test);
        localStorage.removeItem(test);
        return true;
    }catch(e){
        console.log(e);
        store.dispatch(showModal(createAlertForDisabledLocalStorage()));
        return false;
    }
}