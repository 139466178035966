import { getSignsAnswersFromApi, computeProgressInSigns, saveSignsAnswersLocal } from '../redux/actions/signActions';
import { getRegulationsAnswersFromApi, computeProgressInRegulations, saveRegulationsAnswersLocal } from '../redux/actions/regulationActions';
import { getQuestionsAnswersFromApi, computeProgressInQuestions, saveQuetionsAnswersLocal } from '../redux/actions/questionActions';
import store from '../redux/store';
import agent from '../api/agent';
import { refreshPage } from '../redux/actions/answersActions';
import 'core-js/es/object/from-entries';

export class ChecksumCheckerService {

    synchronizeQuestionsAnswers(checksumApi, timestampApi, apiResetFlag) {
        // console.log("ChecksumCheckerService checkQuestionsAnswers")
        synchronizeAnswers(
            checksumApi,
            timestampApi,
            apiResetFlag,
            "questionsAnswers",
            getQuestionsAnswersAndComputeProgress,
            computeProgressInQuestions,
            agent.UserAnswers.patchQuestions,
            new ChecksumCheckerService().synchronizeQuestionsAnswers,
            getQuestionsAnswersFromApi,
            saveQuetionsAnswersLocal,
            store.getState().questions.questionsAnswers
        )
    }

    synchronizeSignsAnswers(checksumApi, timestampApi, apiResetFlag) {
        // console.log("ChecksumCheckerService checkQuestionsAnswers")
        synchronizeAnswers(
            checksumApi,
            timestampApi,
            apiResetFlag,
            "signsAnswers",
            getSignsAnswersAndComputeProgress,
            computeProgressInSigns,
            agent.UserAnswers.patchSigns,
            new ChecksumCheckerService().synchronizeSignsAnswers,
            getSignsAnswersFromApi,
            saveSignsAnswersLocal,
            store.getState().signs.signsAnswers
        )
    }

    
    synchronizeRegulationsAnswers(checksumApi, timestampApi, apiResetFlag) {
        // console.log("ChecksumCheckerService checkQuestionsAnswers")
        synchronizeAnswers(
            checksumApi,
            timestampApi,
            apiResetFlag,
            "regulationsAnswers",
            getRegulationsAnswersAndComputeProgress,
            computeProgressInRegulations,
            agent.UserAnswers.patchRegulations,
            new ChecksumCheckerService().synchronizeRegulationsAnswers,
            getRegulationsAnswersFromApi,
            saveRegulationsAnswersLocal,
            store.getState().regulations.regulationsAnswers
        )
    }

}

//private functions
function getSignsAnswersAndComputeProgress(refresh) {
    store.dispatch(getSignsAnswersFromApi()).then(() => {
        store.dispatch(computeProgressInSigns())
        if (refresh){
            store.dispatch(refreshPage('signs', true));
        }
    })
}

function getQuestionsAnswersAndComputeProgress() {
    store.dispatch(getQuestionsAnswersFromApi()).then(() => {
        store.dispatch(computeProgressInQuestions())
    })
}

function getRegulationsAnswersAndComputeProgress(refresh) {
    store.dispatch(getRegulationsAnswersFromApi()).then(() => {
        store.dispatch(computeProgressInRegulations())
        if (refresh){
            store.dispatch(refreshPage('regulations', true));
        }
    })
}

function synchronizeAnswers(
    checksumApi,
    apiTimestamp,
    apiResetFlag,
    nameAnswers,
    getAnswersAndComputeProgressCallback,
    computeProgressCallback,
    patchAnswersCallback,
    checkerServiceCheckAnswersCallback,
    getAnswersFromApiCallback,
    saveAnswersLocalCallback,
    answers
) {
    // console.log("checkSum "+ nameAnswers +" checksum:"+checksumApi)
    console.log("ChecksumCheckerService checksum() name " + nameAnswers)
    // console.log("checksum() checksumApi "+checksumApi)
    // console.log("checksum() timestampApi "+timestampApi)


    //wyciagamy stan aktualny
    let localChecksum = localStorage.getItem(nameAnswers + "Checksum")
    let localTimestamp = localStorage.getItem(nameAnswers + "Timestamp")

    //jesli checksumLocal i timestampLocal są udenfinied lub null, tzn ze nie ma odpowiedzi wiec zaciągamy 
    if (localChecksum === undefined || localChecksum === null || localTimestamp === undefined || localTimestamp === null || localChecksum !== checksumApi || Array.isArray(answers)) {
        console.log("" + nameAnswers + " checksumLocal === undefined || checksumLocal === null || timestampLocal === undefined || timestampLocal === null || localChecksum !== checksumApi")
        let refresh = false;
        if (nameAnswers === 'signsAnswers' || nameAnswers === 'regulationsAnswers'){
            refresh = true;
        }
        getAnswersAndComputeProgressCallback(refresh);
        return
    }

    localTimestamp = Number(localTimestamp)
    apiTimestamp = Number(apiTimestamp)

    if (localChecksum === checksumApi) {
        console.log("" + nameAnswers + " checksumLocal === checksumApi")
        store.dispatch(computeProgressCallback())
        return
    }


    console.log("" + nameAnswers + " checksumLocal !== checksumApi")
    //  console.log("checksumApi:"+checksumApi)
    //  console.log("checksumLoc:"+checksumLocal)
    console.log("timestampApi:" + apiTimestamp)
    console.log("timestampLoc:" + localTimestamp)
    //wysylamy odpowiedzi na serwer


    let localStorageString = localStorage.getItem(nameAnswers)
    let localStorageObject = JSON.parse(localStorageString)
    let localAnswersMap = new Map(Object.entries(localStorageObject));

    if (apiResetFlag) {
        synchronizeAnswersWithResetEnabled(
            nameAnswers,
            localTimestamp,
            apiTimestamp,
            localAnswersMap,
            getAnswersFromApiCallback,
            computeProgressCallback,
            patchAnswersCallback,
            checkerServiceCheckAnswersCallback,
            saveAnswersLocalCallback
        )
    }
    if (!apiResetFlag) {
        synchronizeAnswersTwoWay(
            nameAnswers,
            localTimestamp,
            apiTimestamp,
            localAnswersMap,
            getAnswersFromApiCallback,
            computeProgressCallback,
            patchAnswersCallback,
            checkerServiceCheckAnswersCallback,
            saveAnswersLocalCallback
        )
    }
}


function synchronizeAnswersWithResetEnabled(
    nameAnswers,
    localTimestamp,
    apiTimestamp,
    localAnswersMap,
    getAnswersFromApiCallback,
    computeProgressCallback,
    patchAnswersCallback,
    checkerServiceCheckAnswersCallback,
    saveAnswersLocalCallback
) {
    if (localTimestamp > apiTimestamp) {
        console.log("" + nameAnswers + " timestampLocal > timestampApi")
        //robimy tablice pytan, wypelniamy ją odpowiedziami i wysylkamy na serwer :D
    
        let answersToSend = new Map();
        console.log("answersToSend")
        localAnswersMap.forEach((value, key) => {
            if (value !== 0) {
                answersToSend.set(key, value)
            }
        })
        console.log("answerToSend:")
        console.log(answersToSend)

        if(answersToSend.size > 0){
            patchAnswersCallback(Object.fromEntries(answersToSend))
            .then(response => {
                console.log("RESPONSE:")
                console.log(response)
                checkerServiceCheckAnswersCallback(response.checksum, response.timestamp, response.reset);
                store.dispatch(computeProgressCallback())
                //dodać jakieś warunki żeby w pętli nie nakurwiał chuj wie ilek razy, max kurwa 3 razy :D 

            })
            .catch(error => {
                console.log("RESPONSE ERROR")
                console.log(error)
                //zrobić obsługę błędu
            });
        }

    }


    //pobieramy odpowiedzi z serwera i aktualizujemy
    if (localTimestamp <= apiTimestamp) {
        console.log("" + nameAnswers + " timestampLocal <= timestampApi")
        store.dispatch(getAnswersFromApiCallback()).then(() => {

            let userQuestionAnswersFromApi = new Map(Object.entries(JSON.parse(localStorage.getItem(nameAnswers))))
            let isDurationBetweenTimestampsLongerThan5min = (apiTimestamp - localTimestamp) > (60 * 1000 * 5)
            let answersToSend = new Map();

            localAnswersMap.forEach((value, key) => {
                let answerApi = userQuestionAnswersFromApi.get(key)
                if (answerApi !== undefined && answerApi !== null) {
                    if (!isDurationBetweenTimestampsLongerThan5min) {
                        if (answerApi === 0 && value !== 0) {
                            answersToSend.set(key, value)
                            userQuestionAnswersFromApi.set(key, value)
                        }
                    }
                }
            })

            if (answersToSend.size > 0) {
                store.dispatch(saveAnswersLocalCallback(answersToSend))
                patchAnswersCallback(Object.fromEntries(answersToSend))
                    .then(response => {
                        console.log("RESPONSE:")
                        console.log(response)
                        checkerServiceCheckAnswersCallback(response.checksum, response.timestamp);
                        store.dispatch(computeProgressCallback())
                        //dodać jakieś warunki żeby w pętli nie nakurwiał chuj wie ilek razy, max kurwa 3 razy :D 

                    })
                    .catch(error => {
                        console.log("RESPONSE ERROR")
                        console.log(error)
                        //zrobić obsługę błędu
                    });
            }

            store.dispatch(computeProgressCallback())
        })

    }
}

function synchronizeAnswersTwoWay(
    nameAnswers,
    localTimestamp,
    apiTimestamp,
    localAnswersMap,
    getAnswersFromApiCallback,
    computeProgressCallback,
    patchAnswersCallback,
    checkerServiceCheckAnswersCallback,
    saveAnswersLocalCallback
) {

    store.dispatch(getAnswersFromApiCallback()).then(() => {
        let userQuestionAnswersFromApi = new Map(Object.entries(JSON.parse(localStorage.getItem(nameAnswers))))
        let answersToUpdateAPI = new Map();
        let answersToUpdateLocal = new Map();

        localAnswersMap.forEach((value, key) => {
            
            let answerApi = userQuestionAnswersFromApi.get(key)
            let answerLocal = value

            if (answerApi !== undefined && answerApi !== null) {
                if (answerLocal !== answerApi) {
                    //gdy odpowiedz na serwerze jest pusta
                    if (answerApi === 0) {
                        //to jezeli odpowiedz lokalna nie jest pusta
                        if (answerLocal !== 0) {
                            //dodaj lokalna do listy wyslania na serwer
                            answersToUpdateAPI.set(key, answerLocal)
                        }
                    } else {
                        //gdy odpowiedz na serwerze nie jest pusta
                        //i gdy odpowiedz lokalna jest pusta
                        if (answerLocal === 0) {
                            answersToUpdateLocal.set(key, answerApi)
                        } else {
                            //lub gdy odpowiedz lokalna nie jest pusta priorytetuj wedlug timestampów
                            if (apiTimestamp < localTimestamp) {
                                answersToUpdateAPI.set(key, answerLocal)
                            }
                            if (apiTimestamp >= localTimestamp) {
                                answersToUpdateLocal.set(key, answerApi)
                            }
                        }
                    }
                }
            }
        })

        if (answersToUpdateLocal.size > 0) {
            store.dispatch(saveAnswersLocalCallback(answersToUpdateLocal))
        }

        if (answersToUpdateAPI.size > 0) {
            patchAnswersCallback(Object.fromEntries(answersToUpdateAPI))
                .then(response => {
                    console.log("RESPONSE:")
                    console.log(response)
                    checkerServiceCheckAnswersCallback(response.checksum, response.timestamp, response.reset);
                    store.dispatch(computeProgressCallback())
                    //dodać jakieś warunki żeby w pętli nie nakurwiał chuj wie ilek razy, max kurwa 3 razy :D 
                })
                .catch(error => {
                    console.log("RESPONSE ERROR")
                    console.log(error)
                    //zrobić obsługę błędu
                }); 
        }

        store.dispatch(computeProgressCallback())
    })
   
}

