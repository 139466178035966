import { ActionAnswersTypes } from '../contants/action-types';
import { getLocalStorageItemOrDefault } from '../../helpers/GetLocalStorageItemOrDefault';
const initialState = {
    questionsAnswersQueue: getLocalStorageItemOrDefault('queueAnswersQuestions', "x"),
    questionsAnswersResetFlag: false,
    signsAnswersQueue: getLocalStorageItemOrDefault('queueAnswersSigns', ""),
    regulationsAnswersQueue: getLocalStorageItemOrDefault('queueAnswersRegulations', ""),
    timestamp: Date.now() - 1000 * 30,
    timestampResque: Date.now(),
    isStarted: false,
    refreshSignsPage: false,
    refreshRegulationsPage: false
}

/* dodać zmienna timestampt i sprawdzac w funkcji co sekunde kiedy ostatni raz byla wywolywana,
 jesli dalej niz 60 sekund uruchomic funkcje i zmienic timestamp - pozwoli to wymusic uruchomienie funkcji po 
 zakonczonym  egzamine " */

export const answersReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionAnswersTypes.ADD_QUESTIONS_ANSWERS:
            return {
                ...state,
                questionsAnswersQueue: payload.stringifyQueueAnswers,
                questionsAnswersResetFlag: payload.resetFlag
            }
        case ActionAnswersTypes.CLEAR_QUESTIONS_ANSWERS_QUEUE:
            return {
                ...state,
                questionsAnswersQueue: "",
                questionsAnswersResetFlag: false
            }
        case ActionAnswersTypes.ADD_SIGNS_ANSWERS:
            return {
                ...state,
                signsAnswersQueue: payload
            }
        case ActionAnswersTypes.CLEAR_SIGNS_ANSWERS_QUEUE:
            return {
                ...state,
                signsAnswersQueue: ""
            }
        case ActionAnswersTypes.ADD_REGULATIONS_ANSWERS:
            return {
                ...state,
                regulationsAnswersQueue: payload
            }
        case ActionAnswersTypes.CLEAR_REGULATIONS_ANSWERS_QUEUE:
            return {
                ...state,
                regulationsAnswersQueue: ""
            }
        case ActionAnswersTypes.TIMESTAMP_NOW:
            return {
                ...state,
                timestamp: Date.now()
            }
        case ActionAnswersTypes.TIMESTAMP_RESQUE:
            return {
                ...state,
                timestampResque: Date.now()
            }
        case ActionAnswersTypes.SET_IS_STARTED:
            return {
                ...state,
                isStarted: payload
            }
        case ActionAnswersTypes.SET_REFRESH_PAGE:
            if (payload.pageToRefresh === 'signs'){
                return {
                    ...state,
                    refreshSignsPage: payload.value 
                }
            }
            if (payload.pageToRefresh === 'regulations'){
                return {
                    ...state,
                    refreshRegulationsPage: payload.value 
                }
            }
            break;
        default: return state
    }

};