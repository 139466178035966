export const ActionQuestionsTypes = {
    SAVE_QUESTIONS: "SAVE_QUESTIONS",
    SAVE_QUESTIONS_ANSWERS: "SAVE_QUESTIONS_ANSWERS",
    SAVE_QUESTIONS_ANSWERS_CHECKSUM_TIMESTAMP: "SAVE_QUESTIONS_ANSWERS_CHECKSUM_TIMESTAMP",
    UPDATE_PROGRESS_IN_QUESTIONS: "UPDATE_PROGRESS_IN_QUESTIONS",
    DELETE_PROGRESS_FOR_SECTION: "DELETE_PROGRESS_FOR_SECTION"
}

export const ActionSignsTypes = {
    SAVE_SIGNS: "SAVE_SIGNS",
    SAVE_SIGNS_ANSWERS: "SAVE_SIGNS_ANSWERS",
    SAVE_SIGNS_ANSWERS_CHECKSUM_TIMESTAMP: "SAVE_SIGNS_ANSWERS_CHECKSUM_TIMESTAMP",
    UPDATE_PROGRESS_IN_SIGNS: "UPDATE_PROGRESS_IN_SIGNS"
}

export const ActionRegulationsTypes = {
    SAVE_REGULATIONS: "SAVE_REGULATIONS",
    SAVE_REGULATIONS_ANSWERS: "SAVE_REGULATIONS_ANSWERS",
    SAVE_REGULATIONS_ANSWERS_CHECKSUM_TIMESTAMP: "SAVE_REGULATIONS_ANSWERS_CHECKSUM_TIMESTAMP",
    UPDATE_PROGRESS_IN_REGULATIONS: "UPDATE_PROGRESS_IN_REGULATIONS"
}

export const ActionExamTypes = {
    GET_QUESTIONS_FOR_EXAM: "GET_QUESTIONS_FOR_EXAM",
    SET_NEXT_ACTIVE_QUESTION: "SET_NEXT_QUESTION",
    CLEAR_EXAM_DATA: "CLEAR_EXAM_DATA",
    SEND_ANSWER_TO_EXAM: "SEND_ANSWER_TO_EXAM",
    COMPUTE_EXAM_RESULT: "COMPUTE_EXAM_RESULT",
    SET_SUMMARY_INDEX: "SET_SUMMARY_INDEX",
    SET_IS_START_MOVIE: "SET_IS_START_MOVIE",
    SET_IS_MOVIE_ENDED: "SET_IS_MOVIE_ENDED",
    SET_SELECTED_ANSWER: "SET_SELECTED_ANSWER",
    SET_IS_EXAM_STARTED: "SET_IS_EXAM_STARTED",
    SET_REFRESH_EXAM_PAGE: "SET_REFRESH_EXAM_PAGE",
    SET_SHOWED_SUMMARY_EXAM_PAGE : "SET_SHOWED_SUMMARY_EXAM_PAGE",
    SET_SHOWED_EXAM_SUMMARY_MODEL: "SET_SHOWED_EXAM_SUMMARY_MODEL"
}

export const ActionDepartamentTypes = {
    SET_ACTIVE_QUESTION: "SET_ACTIVE_QUESTION",
    SET_CURRENT_SECTION:"SET_CURRENT_SECTION",
    RESET_ACTIVE_QUESTION: "RESET_ACTIVE_QUESTION",
    DELETE_QUESTION_FROM_EX_LIST: "DELETE_QUESTION_FROM_EX_LIST",
    MOVE_QUESTION_FROM_EX_LIST: "MOVE_QUESTION_FROM_EX_LIST",
    DELETE_ALL_QUESTIONS_FROM_EX_LIST: "DELETE_ALL_QUESTIONS_FROM_EX_LIST" 
}

export const ActionWrongTypes = {
    GET_ACTIVE_QUESTION: "GET_ACTIVE_QUESTION",
    DELETE_QUESTION_FROM_WRONGS: "DELETE_QUESTION_FROM_WRONGS",
    MOVE_QUESTION_TO_END_OF_LIST: "MOVE_QUESTION_TO_END_OF_LIST",
    GET_AMOUNT_OF_WRONGS: "GET_AMOUNT_OF_WRONGS",
    RESET_ACTIVE_QUESTION: "RESET_ACTIVE_QUESTION"
}  

export const ActionAnswersTypes = {
    ADD_QUESTIONS_ANSWERS: "ADD_QUESTIONS_ANSWERS",
    CLEAR_QUESTIONS_ANSWERS_QUEUE: "CLEAR_QUESTIONS_ANSWERS_QUEUE",
    ADD_SIGNS_ANSWERS: "ADD_SIGNS_ANSWERS",
    CLEAR_SIGNS_ANSWERS_QUEUE: "CLEAR_SIGNS_ANSWERS_QUEUE",
    ADD_REGULATIONS_ANSWERS: "ADD_REGULATIONS_ANSWERS",
    CLEAR_REGULATIONS_ANSWERS_QUEUE: "CLEAR_REGULATIONS_ANSWERS_QUEUE",
    TIMESTAMP_NOW: "TIMESTAMP_NOW",
    TIMESTAMP_RESQUE: "TIMESTAMP_RESQUE",
    SET_IS_STARTED: "SET_IS_STARTED",
    SET_REFRESH_PAGE: "SET_REFRESH_PAGE",
}

export const ActionUserTypes = {
    GET_USER: "GET_USER",
    SET_LAST_TIME_REFRESH_TOKEN_DATE: "SET_LAST_TIME_REFRESH_TOKEN_DATE",
    SET_CATEGORY: "SET_CATEGORY",
    SET_AUTO_PLAY: "SET_AUTO_PLAY",
    SET_TIME_IN_EXAM: "SET_TIME_IN_EXAM",
    SET_LOADING: "SET_LOADING",
    LOG_OUT: "LOG_OUT",
    REMOVE_PREMIUM: "REMOVE_PREMIUM",
    INCREMENT_DEMO_USED_EXAMS_NUMBER: "INCREMENT_DEMO_USED_EXAMS_NUMBER"
}

export const ActionNotificationTypes = {
    SHOW_TOAST: "SHOW_TOAST",
    RESET_TOAST_SETTINGS: "RESET_TOAST_SETTINGS",
    SHOW_MODAL: "SHOW_MODAL",
    CLOSE_MODAL: "CLOSE_MODAL",
    SET_LOADING_IN_MODAL: "SET_LOADING_IN_MODAL",
    CHANGE_TYPE_OF_MODAL: "CHANGE_TYPE_OF_MODAL"
}