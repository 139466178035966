import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { auth } from '../Firebase';

export default function PrivateRouteDashboard({ component : Component, ...rest}) {

    return (
        <Route
            {...rest}
            render={(props) => auth.currentUser === null ? <Redirect to="/logowanie"/> : <Component {...props}/>}
        />
    )
}
