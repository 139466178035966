import React , { useEffect } from 'react';
import InformationSection from '../../components/website/informationSection/InformationSection';
import MainSection from '../../components/website/mainSection/MainSection';
import NavigationBar from '../../components/website/navigationBar/NavigationBar';
import MockupSection from '../../components/website/mockupSection/MockupSection';
import {MockupSectionData} from '../../data/MockupSectionData';
import SliderSection from '../../components/website/slidereSection/SliderSection';
import PricingSection from '../../components/website/pricingSection/PricingSection';
import FooterSection from '../../components/website/footerSection/FooterSection';
import CookieModal from '../../components/website/cookiesModal/CookieModal';
import { CookiesInformationData } from '../../data/CookiesInformationData';
import MobileSection from '../../components/website/mobileSection/MobileSection';
import detectBrowserVersion from '../../helpers/DetectBrowserVersion';
import Modal from '../../components/others/modal/Modal';

const Website = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
        detectBrowserVersion();
    });

    return (
        <>
            <Modal />
            <NavigationBar />
            <MainSection />
            <InformationSection />
            <MockupSection data={MockupSectionData[0]} imageOnLeft={true}/>
            <SliderSection />
            <MockupSection data={MockupSectionData[1]} /> 
            <MobileSection />
            <PricingSection />
            <FooterSection />
            <CookieModal data={CookiesInformationData}/>
        </>
    )
}

export default Website
