import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Carousel, { slidesToShowPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import './SliderSection.css';
import { FaQuoteRight  } from 'react-icons/fa';
import { QuoteData } from '../../../data/QuoteData';
import Quote from './Quote';

const SliderSection = () => {

    let QuoteList =  QuoteData.map((item) =>
    <Quote data={item} key={item.id}/>
    )

    return (
        <div className="SliderSection">
            <Container fluid="xl">
                <div className="SliderSection__header">
                    <div className="SliderSection__icon">
                        <FaQuoteRight />
                    </div>
                    <p>Sprawdź co mówią o nas nasi użytkownicy</p>
                </div>
            <Row>
                <Carousel
                plugins={[
                    'infinite',
                    'arrows',
                    {
                    resolve: slidesToShowPlugin,
                    options: {
                    numberOfSlides: 3
                    }
                    },
                ]}

                breakpoints={{
                    1260: {
                        plugins: [
                            'infinite',
                        {
                            resolve: slidesToShowPlugin,
                            options: {
                            numberOfSlides: 2
                            }
                        },
                        ]
                    },
                    750: {
                    plugins: [
                        'infinite',
                    {
                        resolve: slidesToShowPlugin,
                        options: {
                        numberOfSlides: 1
                        }
                    },
                    ]
                    }
                }}
                >
                    {QuoteList}                         
                </Carousel>
            </Row>
            </Container>
        </div>
    )
}

export default SliderSection
