import React from 'react';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import "./Alert.css";

const Alert = (props) => {

  let AlertContentElements;

  if(props.data !== undefined){
      AlertContentElements = props.data.map((item, i) => <li key={item.id}>{item.text}</li>)
  }

  const getTextAboutAmountOfDemoExams = () => {
      const numberOfRemainingExams = 3 - props.demoUsedExams;

      switch(numberOfRemainingExams) {
        case 3:
            return 'Do wykorzystania pozostały trzy próbne egzaminy.'
        case 2:
            return 'Do wykorzystania pozostały dwa próbne egzaminy.'
        case 1:
            return 'Do wykorzystania pozostał jeden próbny egzamin.'
        default:
            return 'Wykorzystałeś wszystkie próbne egzaminy';
      }
  }

  if(props.type === "ExamInformation")
  return (
    <div className="Alert__container information">
      
        <div className="Alert__title">
            <IoIosInformationCircleOutline 
              className='icon--information'
            />
            <h6 className="information">Zasady egzaminu</h6>
        </div>

        <div className="Alert__content">              
            <ul className='ul--information'>
                {AlertContentElements}
            </ul>
        </div>

    </div>
  )
  else if(props.type === "DemoExamsCounterInformation")
  return(
    <div className={props.demoUsedExams !== 3 ? "Alert__container information" : "Alert__container warning"}>
      
      <div className="Alert__title">
          <IoIosInformationCircleOutline 
            className={props.demoUsedExams !== 3 ? 'icon--information' : 'icon--warning'}
          />
          <h6 className={props.demoUsedExams !== 3 ? 'information' : 'warning'} style={{width: '85%'}}> 
            {getTextAboutAmountOfDemoExams()}
          </h6>
      </div>

      <div className="Alert__content">     
        <ul className= {props.demoUsedExams !== 3 ? 'ul--information' : 'ul--information--warning'}>
          <li>Wersja demonstracyjna zawiera trzy darmowe egzaminy dla kategorii B.</li>
          <li>W wersji premium masz dostęp do wszystkich pytań dla wszystkich kategorii na prawo jazdy.</li>
        </ul>         
      </div>

    </div>
  )
  else
  return (
    <></>
  )
  
}

export default Alert
