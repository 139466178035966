import React from 'react';
import './QuestionCounter.css';

const QuestionCounter = (props) => {
  return (
    <div className={ props.IsHidden ? 'QuestionCounter HiddenQuestionCounter' : 'QuestionCounter'}>
      <p>{props.text}</p>
      <div className='Cover--large'>{props.numberOfQuestion}/{props.maxNumberOfQuestion}</div>
    </div>
  )
}

export default QuestionCounter