import { ActionNotificationTypes } from '../contants/action-types';
import { ModalContent } from '../../models/ModalContet';

const initialState = {
    showToast: false,
    toastMessage: "",
    type: "",
    showModal: false,
    modalContent: new ModalContent("","","","","","",""),
    loading: false
}

export const notificationReducer = ( state = initialState, { type , payload } ) => {

    switch (type) {
        case ActionNotificationTypes.SHOW_TOAST:
            return{
                ...state,
                showToast: true,
                toastMessage: payload.toastMessage,
                type: payload.type
            }
        case ActionNotificationTypes.RESET_TOAST_SETTINGS:
            return{
                ...state,
                showToast: payload.showToast,
                toastMessage: payload.toastMessage,
                type: payload.type
            }
        case ActionNotificationTypes.SHOW_MODAL:
            return{
                ...state,
                showModal: true,
                modalContent: payload
            }
        case ActionNotificationTypes.CLOSE_MODAL:
            return{
                ...state,
                showModal: false,
                loading: false,
                modalContent: payload
            }
        case ActionNotificationTypes.SET_LOADING_IN_MODAL:
            return{
                ...state,
                loading: payload
            }
        case ActionNotificationTypes.CHANGE_TYPE_OF_MODAL:
            return{
                ...state,
                modalContent: {
                    ...state.modalContent,
                    type: payload
                }
            }
        default: return state
    }

};

