import account from '../images/konto.svg';
import exam from '../images/icotest.svg';
import learning from '../images/iconauka.svg';
import errors from '../images/icobledy.svg';
import departments from '../images/icodzialy.svg';
import settings from '../images/icoopcje.svg';
import CheckAccessToWrongs from '../helpers/CheckAccessToWrongs';
import RefreshExamPageDuringExamSummary from '../helpers/RefreshExamPageDuringExamSummary';

export const SidebarData = [
    {
        id:1,
        link: '/konto',
        img: account,
        alt: 'konto',
        fun: null
    },
    {
        id:2,
        link: '/egzamin',
        img: exam,
        alt: 'egzamin',
        fun: RefreshExamPageDuringExamSummary
    },
    {
        id:3,
        link: '/nauka',
        img: learning,
        alt: 'nauka',
        fun: null
    },
    {
        id:4,
        link: '/bledy',
        img: errors,
        alt: 'błędy',
        fun: CheckAccessToWrongs
    },
    {
        id:5,
        link: '/dzialy',
        img: departments,
        alt: 'działy',
        fun: null
    },
    {
        id:6,
        link: '/opcje',
        img: settings,
        alt: 'opcje',
        fun: null
    }
]