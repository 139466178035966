import React , { useEffect , useState }from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import Badge from '../../others/badge/Badge';
import { useSelector , useDispatch } from 'react-redux';
import { createAlertDuringExamModalContent } from '../../../models/ModalContet';
import { showModal , closeModal } from '../../../redux/actions/notificationActions';
import { setIsExamStarted } from '../../../redux/actions/examActions';

const SidebarItem = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const amountOfWrongs = useSelector((state) => state.wrongs.amountOfWrongs);
    const isExamStarted = useSelector((state) => state.exam.isExamStarted);
    const showedSummaryExamPage = useSelector((state) => state.exam.showedSummaryExamPage);
    const [ extraClass , setExtraClass ] = useState("Sidebar__item");
    const url = window.location.pathname.split('/').pop();

    const AlertDuringExamData = createAlertDuringExamModalContent(handleCheckAccess);
    
    function handleCheckAccess(){
        if(isExamStarted){
            dispatch(setIsExamStarted(false));
            dispatch(closeModal());
        }
        if(props.data.fun !== null){
            const result = props.data.fun();
            if(result){
                history.push(props.data.link);
            }
        }else{
            history.push(props.data.link);
        }
    }

    function handleIfExamStarted(){
        if(isExamStarted){
            dispatch(showModal(AlertDuringExamData));
        }else{
            handleCheckAccess();
        }
    }

    useEffect(() => {
        
        const isExamPage = url === "egzamin";

        if(isExamPage){
            if(props.data.alt === 'egzamin'){
                setExtraClass("Sidebar__item Sidebar__item--active");
            }       
        }
        else if(extraClass === "Sidebar__item Sidebar__item--active"){
            setExtraClass("Sidebar__item");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    } , [url])
    
    return (
        <>
        {
            (props.data.fun !== null) && 
            props.data.alt === 'błędy' &&
            <div
                onClick={handleIfExamStarted}
                style={{cursor: 'pointer'} }
            > 
            <Badge number={amountOfWrongs}/>
                {
                    ((amountOfWrongs === null) || (amountOfWrongs === 0)) &&
                    <div className="Sidebar__item">
                    <li>
                        <img src={props.data.img} alt={props.data.alt} />
                    </li>
                    </div>
                }
                { 
                ((amountOfWrongs !== null ) && (amountOfWrongs > 0)) &&
                <>
                    {
                        isExamStarted && 
                        <div className="Sidebar__item">
                        <li>
                            <img src={props.data.img} alt={props.data.alt} />
                        </li>
                        </div>
                    }
                    {
                        !isExamStarted &&
                        <NavLink to={props.data.link}> 
                        <div 
                            className='Sidebar__item'
                        >
                            <li>
                                <img src={props.data.img} alt={props.data.alt} />
                            </li>
                        </div>
                        </NavLink>  
                    }
                </>           
                }
            </div>
        }
        {
            (props.data.fun !== null || isExamStarted) && 
            props.data.alt !== 'błędy' && props.data.alt !== 'egzamin' &&
            <div
            onClick={handleIfExamStarted}
            style={{cursor: 'pointer'} }
            > 
                <div className={extraClass}>
                    <li>
                        <img src={props.data.img} alt={props.data.alt} />
                    </li>
                </div>
            </div>
        }
        {
            props.data.fun === null && 
            props.data.alt !== 'egzamin' &&
            !isExamStarted && 
            <NavLink to={props.data.link}> 
            <div 
                className='Sidebar__item'
            >
                <li>
                    <img src={props.data.img} alt={props.data.alt} />
                </li>
            </div>
            </NavLink>
        }
        {
            props.data.fun !== null && 
            props.data.alt === 'egzamin' &&
            !isExamStarted && 
            !showedSummaryExamPage &&
            <NavLink to={props.data.link}> 
            <div 
                className='Sidebar__item'
            >
                <li>
                    <img src={props.data.img} alt={props.data.alt} />
                </li>
            </div>
            </NavLink>
        }
        {
            props.data.fun !== null &&
            ((isExamStarted && !showedSummaryExamPage) || (!isExamStarted && showedSummaryExamPage)) && 
            props.data.alt !== 'błędy' && props.data.alt === 'egzamin' && 
            <div
            onClick={handleIfExamStarted}
            style={{cursor: 'pointer'} }
            > 
                <div className={extraClass}>
                    <li>
                        <img src={props.data.img} alt={props.data.alt} />
                    </li>
                </div>
            </div>
        }
        </>
    )
}

export default SidebarItem
