import { ActionRegulationsTypes } from '../contants/action-types';
import agent from '../../api/agent';
import { UserProgressService } from '../../services/UserProgressService';
import store from '../store'
import { CryptoSha1 } from '../../helpers/Crypto';

export const getRegulationsFromApi = () => async dispatch => {
    try{
        const regulations = await agent.Regulations.getRegulations();
        console.log(regulations);
        localStorage.setItem("regulations" , JSON.stringify(regulations));
        dispatch( {
            type: ActionRegulationsTypes.SAVE_REGULATIONS,
            payload: regulations
        })
    }catch (error){
        console.log(error)
    }
};

export const getRegulationsAnswersFromApi = () => async dispatch => {
    try{
        const answers = await agent.UserAnswers.getRegulations()
        console.log(answers.regulationsAnswers);

        let answersString = JSON.stringify(answers.regulationsAnswers);
        localStorage.setItem("regulationsAnswers" , answersString);

        let answersChecksum = await CryptoSha1(answersString)
        let answersTimestamp = Date.now()      
        dispatch(saveRegulationsAnswersChecksumAndTimestamp(answersChecksum, answersTimestamp))

        dispatch( {
            type: ActionRegulationsTypes.SAVE_REGULATIONS_ANSWERS,
            payload: answers.regulationsAnswers
        })
    }catch (error){
        console.log(error)
    }
};

export const saveRegulationsAnswersChecksumAndTimestamp = (answersChecksum, answersTimestamp) => {

    localStorage.setItem("regulationsAnswersTimestamp", answersTimestamp)
    localStorage.setItem("regulationsAnswersChecksum", answersChecksum)

    return{
        type: ActionRegulationsTypes.SAVE_REGULATIONS_ANSWERS_CHECKSUM_TIMESTAMP,
        payload: { answersChecksum , answersTimestamp }
    };

};

export const saveRegulationsAnswersLocal = (answersToSave) => async dispatch => {
    try{    
        const updatedRegulationsAnswers = store.getState().regulations.regulationsAnswers
        answersToSave.forEach((value, key) => {
            updatedRegulationsAnswers[key] = value
        })

        // console.log("saveQuetionsAnswersLocal updatedQuestionsAnswers")
        // console.log(updatedQuestionsAnswers)

        let answersString = JSON.stringify(updatedRegulationsAnswers)
        localStorage.setItem("regulationsAnswers", answersString);
        
        let answersChecksum = await CryptoSha1(answersString)
        let answersTimestamp = Date.now()      
        dispatch(saveRegulationsAnswersChecksumAndTimestamp(answersChecksum, answersTimestamp))
    
        dispatch({
            type: ActionRegulationsTypes.SAVE_REGULATIONS_ANSWERS_LOCAL,
            payload: updatedRegulationsAnswers
        })
    }catch (error){
        console.log(error)
    }
};

export const computeProgressInRegulations = (regulationsAnswers) => {

    if(regulationsAnswers === undefined){
        regulationsAnswers = store.getState().regulations.regulationsAnswers;
    }

    let regulations = store.getState().regulations.regulations
    let regulationsProgress =  new UserProgressService().combineProgressRegulationsSections(regulations, regulationsAnswers);

    return{
        type: ActionRegulationsTypes.UPDATE_PROGRESS_IN_REGULATIONS,
        payload: regulationsProgress
    };

};

export const resetAnswersRegulationsLocal = () => async dispatch => {

    const regulationsAnswers = store.getState().regulations.regulationsAnswers;
    const newRegulationsAnswers = {...regulationsAnswers};
    
    Object.keys(newRegulationsAnswers).forEach(function(key){ newRegulationsAnswers[key] = 0}); 

    let answersString = JSON.stringify(newRegulationsAnswers)
    localStorage.setItem("regulationsAnswers", answersString);
    
    let answersChecksum = await CryptoSha1(answersString)
    let answersTimestamp = Date.now()      
    dispatch(saveRegulationsAnswersChecksumAndTimestamp(answersChecksum, answersTimestamp)) 

    dispatch({
        type: ActionRegulationsTypes.SAVE_REGULATIONS_ANSWERS,
        payload: newRegulationsAnswers
    })
}