import React  from 'react';
import './Layout.css';
import LayoutLink from '../layoutLink/LayoutLink';
import ProgressCircle from '../progressCircle/ProgressCircle';
import CustomButton from '../../others/button/CustomButton';

const Layout = (props) => {

    const getClassName = () => {
        let dashboardClassName = "DashBoardLayout__Container";
        if(props.isNotTopMargin === undefined || props.isNotTopMargin === null){
            dashboardClassName += " DashBoardLayout__Container--topMargin";
        }
        if(props.isPricingDashboardPage === true){
            dashboardClassName += " PricingDashBoardLayout"
        }
        return dashboardClassName;
    }

    return (
        <div className="DashBoardLayoutSection">
            <div className={getClassName()}>
                {
                    props.isActiveButtonInExam &&
                    <div style={{marginBottom: '15px'}}>
                        <CustomButton content={"Następny egzamin"} onClick={props.nextEgzam} widthInPercent="100" color="white"/>
                    </div>
                    
                }
                <div className="DashBoardLayout__Title" style={ props.isProgressCircleActive ? { marginBottom : '15px' } : {}}>
                    <LayoutLink data={props.title} withoutBottomMargin={props.withoutBottomMargin}/>
                    {
                        props.isProgressCircleActive &&
                        <ProgressCircle 
                            progress={props.progress} 
                            color={props.color}
                            secondColor={'#FFFFFF'}
                        />
                    }
                    
                </div>
                {props.children}
            </div>
        </div>
    )
}

export default Layout
