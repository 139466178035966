import React from 'react';
import './PricingColumn.css';
import BulletPoint from './BulletPoint';
import CustomButton from '../../others/button/CustomButton';
import { useHistory } from "react-router-dom";

const PricingColumn = (props) => {

    const history = useHistory()

    let BulletPointList =  props.data.bulletPointList.map((item) =>
    <BulletPoint data={item} key={item}/>
    )

    return ( 
            <div className="PricingColumn__container">
            <div className={props.data.isPopular}>
                {props.data.popularText}
            </div>
            <div className="PricingColumn">
                <div className="PricingColumn__header">
                    <p>{props.data.header}</p>
                </div>
                <div className="PricingColumn__row">
                    <div className="PricingColumn__price">
                        <p>{props.data.price} zł</p>
                    </div>
                    <div className="PricingColumn__text">
                        <p>{props.data.firstText}</p>
                        <p>{props.data.secondText}</p>
                    </div>
                    <div className="BulletPoint__container">
                        {BulletPointList}
                    </div>
                    <div className="PricingColumn__button">
                        {
                            props.isInMainPage &&
                            <CustomButton 
                                color="blue"
                                widthInPercent="100"
                                content={"Kup teraz"}
                                onClick={() => history.push(`/rejestracja?premiumCode=${props.data.id}`)}
                            />
                        }
                        {
                            !props.isInMainPage &&
                            <CustomButton 
                                color="blue"
                                widthInPercent="100"
                                content={"Kup teraz"}
                                onClick={() => history.push(`/podsumowanie-platnosci/${props.data.id}`)}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PricingColumn
