import React, { useState , useEffect } from 'react';
import { useSelector  } from 'react-redux';
import AccountInformation from '../../components/account/accountInformation/AccountInformation';
import AccountProgress from '../../components/account/accountProgress/AccountProgress';
import Layout from '../../components/dashboard/layout/Layout';
import { UserProgressService } from '../../services/UserProgressService';
import { useAuth } from '../../contexts/AuthContext';
import SmallSpinner from '../../components/others/spinner/SmallSpinner';
import { useUserPremiumTimer } from '../../contexts/UserPremiumTimerContext';
import agent from '../../api/agent';
import { useDispatch } from 'react-redux';
import { getUser } from '../../redux/actions/userActions';
import { showModal } from '../../redux/actions/notificationActions';
import { useHistory } from 'react-router-dom';
import { getAlertToModal, waitForNextRequest, translateStatus } from '../../helpers/CheckStatusOfPaymentHelper';

const Account = () => {
    
    const { currentUser } = useAuth();
    const { loadingTimer } = useUserPremiumTimer();
    const dispatch = useDispatch();
    const history = useHistory();

    let params = new URLSearchParams(window.location.search);
    const sessionId = params.get('sessionId');

    const questionStore = useSelector((state) => state.questions);
    const regulationsStore = useSelector((state) => state.regulations);
    const signsStore = useSelector((state) => state.signs);
    const userStore = useSelector((state) => state.user);
    const isActivePremium = useSelector((state) => state.user.isActivePremium);

    const userProgressServie = new UserProgressService();

    const [ learningProgress , setLearningProgress ] = useState();
    const [ questionsProgress , setQuestionsProgress ] = useState();
    const [ loading, setLoading ] = useState(true);
    const [ user , setUser ] = useState();
    const [ selectedCategory , setSelectedCategory ] = useState();
    const [ smallSpinnerText , setSmallSpinnerText] = useState();
    const [ startCheckStatusOfPayment , setStartCheckStatusOfPayment ] = useState(false);

    const breadcrumb = [
        { text:'Konto', link:'/konto' },
    ]

    useEffect(() => {
        setProgressForQuestions();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionStore.questionsProgress])

    function setProgressForQuestions(){
        const progress = new Map(Object.entries(questionStore.questionsProgress))
        setQuestionsProgress(progress.get('all'));
    }

    useEffect(() => {
        setProgressForLearning();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regulationsStore.regulations, regulationsStore.regulationsAnswers, signsStore.signs, signsStore.signsAnswers])

    function setProgressForLearning(){
        if(
            regulationsStore.regulations.length !== 0 && signsStore.signs.length !== 0 &&
            regulationsStore.regulationsAnswers.length !== 0 && signsStore.signsAnswers !== 0
        ){
            const progress = userProgressServie.combineProgressLearning(
                regulationsStore.regulations, 
                regulationsStore.regulationsAnswers, 
                signsStore.signs, 
                signsStore.signsAnswers
                );
            setLearningProgress(progress);
        }
    }
    
    useEffect(() => {
        window.scrollTo(0 , 0);
    },[])

    useEffect(() => {
    
        if(isActivePremium !== null && userStore.selectedCategoryToShow !== null && !loadingTimer){
            if(currentUser !== null){
                setUser(currentUser);
            } 
            if(sessionId !== null && !startCheckStatusOfPayment){
                checkStatusOfPayment();
                setSelectedCategory(userStore.selectedCategoryToShow);
            }else if(sessionId === null){
                setSelectedCategory(userStore.selectedCategoryToShow);
                setTimeout(()=>setLoading(false), 200)
            }                                
        }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser, userStore.selectedCategoryToShow, loadingTimer])
    
    const checkStatusOfPayment = async () =>
    {
        setStartCheckStatusOfPayment(true);
        try{

            let status;
            let numberOfRepeatsOfStatus = 0;
            let lastStatus = '';

            const timeRangeBeetwenRequests = [3000 , 10000, 20000, 30000];
            let timeToWaitForNextRequest = timeRangeBeetwenRequests[numberOfRepeatsOfStatus];

            const MAX_NUMBER_OF_REPEATS_OF_STATUS = 3;

            setSmallSpinnerText("Przetwarzanie płatności");
    
            while(status !== 'PREMIUM_ADDED')
            {
                await waitForNextRequest(timeToWaitForNextRequest);
  
                status = await agent.Payments.checkPaymentStatus(sessionId);

                if(status === 'VERIFIED_FAIL'){
                    showInformationAboutProcessingPayment('VERIFIED_FAIL');
                    return;
                }else if(lastStatus === status){
                    numberOfRepeatsOfStatus++;
                    timeToWaitForNextRequest = timeRangeBeetwenRequests[numberOfRepeatsOfStatus];
                }else{
                    setSmallSpinnerText(translateStatus(status));
                    numberOfRepeatsOfStatus = 0;
                }

                lastStatus = status;

                if(numberOfRepeatsOfStatus > MAX_NUMBER_OF_REPEATS_OF_STATUS){
                    showInformationAboutProcessingPayment('VERIFIED_PAYMENT');
                    return;
                }

            }
            dispatch(getUser()).then(() => {
                if(isActivePremium){
                    showInformationAboutProcessingPayment(status);  
                    return;
                };                       
            });   

            deleteQueryParameter();   
            resetLoadingAndSmallSpinnerText();
            
        }catch(ex){
            console.log(ex);
            resetLoadingAndSmallSpinnerText();
            deleteQueryParameter();
        }
    }
    
    function showInformationAboutProcessingPayment(paymentStatus){
        const modalContent = getAlertToModal(paymentStatus);
        dispatch(showModal(modalContent));
        deleteQueryParameter();   
        resetLoadingAndSmallSpinnerText();
    }

    function deleteQueryParameter(){
        if(params.has('sessionId')){
            params.delete('sessionId');
            history.replace({
                search: params.toString(),
            })
        }
    }

    function resetLoadingAndSmallSpinnerText(){
        setLoading(false);
        setSmallSpinnerText();
    }

    if(loading)
    return(
        <SmallSpinner
            isText={true}
            isCenter={true}
            customText={smallSpinnerText}
        />
    )
    else
    return (
        <Layout title={breadcrumb}>
            <div className="Dashboard__account">   
                <div className='Dashboard__account--left'>
                    <AccountProgress 
                        color={"#0080FD"}
                        progress={questionsProgress}
                        title={"Pytania"}
                    />
                    <AccountProgress 
                        color={"#41B62B"}
                        progress={learningProgress}
                        title={"Nauka"}
                    />
                </div> 
                <AccountInformation 
                    user={user}
                    selectedCategory={selectedCategory}
                />  
            </div>
        </Layout>
    )
}

export default Account