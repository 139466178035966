import React from 'react'
import { useField , ErrorMessage } from 'formik'
import ReactHtmlParser from 'react-html-parser'

const CheckboxField = ({...props}) => {
    const [field] = useField(props);
    return (
        <>
        <div className="form-group">
            <div className="UserPanel__LabelCheckbox form-check">
            <label 
                className="form-check-label"
                >
                <input 
                name={props.name}
                type={props.type}
                className="form-check-input"
                {...field}{...props}
                />
                {ReactHtmlParser(props.text)}
                </label>
            </div>
            <ErrorMessage component="div" name={field.name} className='UserPanel__errorDisplay'/>
        </div>

        </>
    )
}

export default CheckboxField
