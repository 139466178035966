import { ActionWrongTypes } from '../contants/action-types';

const initialState = {
    exList: [],
    activeQuestion: null,
    amountOfWrongs: null
}

export const wrongReducer = ( state = initialState, { type , payload } ) => {

    let newExList;

    switch (type) {
        case ActionWrongTypes.GET_ACTIVE_QUESTION:
            return {
                ...state,
                activeQuestion: payload
            }
        case ActionWrongTypes.DELETE_QUESTION_FROM_WRONGS:
      
            if(state.exList.includes(payload)){
                newExList = [ ...state.exList];
                newExList = newExList.filter(item => item !== payload);
                console.log(newExList);
            }
            
            if(typeof newExList !== 'undefined'){
                return {
                    ...state,
                    exList: newExList
                } 
            }
            else{
                return {
                    ...state
                }  
            }


        case ActionWrongTypes.MOVE_QUESTION_TO_END_OF_LIST:     

            newExList = [ ...state.exList];

            if(state.exList.includes(payload)){                           
                newExList = newExList.filter(item => item !== payload);
            }

            newExList.push(payload);
            console.log(newExList);

            if(typeof newExList !== 'undefined'){
                return {
                    ...state,
                    exList: newExList
                } 
            }
            else{
                return {
                    ...state
                }  
            }
        case ActionWrongTypes.GET_AMOUNT_OF_WRONGS:  
            return {
                ...state,
                amountOfWrongs: payload
            }

        case ActionWrongTypes.RESET_ACTIVE_QUESTION:
            return{
                ...state,
                activeQuestion: payload
            }

        default: return state
    }

};