import store from '../redux/store';
import { showToast , resetModalSettings } from '../redux/actions/notificationActions';

function CheckAccessToWrongs(){

    const amountOfWrongs  = store.getState().wrongs.amountOfWrongs;
    if(amountOfWrongs){
        return true;
    }else{
        store.dispatch(showToast("Brak zapisanych błędów." , "success")).then(() => {
            store.dispatch(resetModalSettings());
        })        
        return false;
    }
}

export default CheckAccessToWrongs;


