import { BasicQuestionsSection } from "../../data/Sections/BasicQuestionsSection";
import { ActionQuestionsTypes } from '../contants/action-types';
import { getLocalStorageItemOrDefault, getLocalStorageItemParsedJsonOrDefault } from '../../helpers/GetLocalStorageItemOrDefault';
import 'core-js/es/object/from-entries';

/* dodać obsługę questionsChecksum */

const initialState = {
    basicQuestionsSections: BasicQuestionsSection, 
    questions: getLocalStorageItemParsedJsonOrDefault('questions', []),
    questionsChecksum: null,
    questionsAnswers: getLocalStorageItemParsedJsonOrDefault('questionsAnswers', []), 
    questionsAnswersChecksum: getLocalStorageItemOrDefault('questionsAnswersChecksum', ""),
    questionsAnswersTimestamp: getLocalStorageItemOrDefault('questionsAnswersTimestamp', ""),
    questionsProgress: {}
}

export const questionReducer = ( state = initialState, { type , payload } ) => {

    switch (type) {
        case ActionQuestionsTypes.SAVE_QUESTIONS:
            return {
                ...state,
                questions: payload
            }
        case ActionQuestionsTypes.SAVE_QUESTIONS_ANSWERS:
            return {
                ...state,
                questionsAnswers: payload
            }
        case ActionQuestionsTypes.SAVE_QUESTIONS_ANSWERS_CHECKSUM_TIMESTAMP:
            return {
                ...state,
                questionsAnswersChecksum: payload.answersChecksum,
                questionsAnswersTimestamp: payload.answersTimestamp,
            }
        case ActionQuestionsTypes.UPDATE_PROGRESS_IN_QUESTIONS:
            return {
                ...state,
                questionsProgress: Object.fromEntries(payload)
            }
        case ActionQuestionsTypes.DELETE_PROGRESS_FOR_SECTION:
            return {
                ...state
            }
        default: return state
    }

};