import React from 'react';
import Error from '../../components/others/error/Error';

const InternalServerError = () => {
  return (
        <Error
            errorCode={500}
            title={"Wewnętrzny błąd serwera!"} 
            content={"Przepraszamy, wystąpił nieoczekiwany błąd podczas przetwarzania twojego żądania. Spróbuj ponownie za jakiś czas."}
        />
  )
};

export default InternalServerError;
