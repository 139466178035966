import { Section } from '../../models/Section';

export const RegulationsSections = [
            new Section("regulations", "title", "Dział 1" , '' , false),
            new Section("regulations", "1.1", "1. Podstawowe definicje", '/nauka/przepisy/podstawowe-definicje' , false),
            new Section("regulations", "title", "Dział 2",'' , false),
            new Section("regulations", "2.1", "1. Przepisy ogólne (Art. 3-10)", '/nauka/przepisy/przepisy-ogolne' , true),
            new Section("regulations", "2.2", "2. Ruch pieszych (Art. 11-15)", '/nauka/przepisy/ruch-pieszych' , true),
            new Section("regulations", "title", "Dział 3\nRuch pojazdów (Art. 16-34)", '' , false),
            new Section("regulations", "3.1", "1. Zasady ogólne",'/nauka/przepisy/zasady-ogolne' , true),
            new Section("regulations", "3.2", "2. Włączanie się do ruchu (Art. 17-18a)", '/nauka/przepisy/wlaczanie-sie-do-ruchu' , true),
            new Section("regulations", "3.3", "3. Prędkość i hamowanie (Art. 19-21)", '/nauka/przepisy/predkosc-i-hamowanie' , true),
            new Section("regulations", "3.4", "4. Zmiana kierunku jazdy lub pasa ruchu (Art. 22)", '/nauka/przepisy/zmiana-kierunku-jazdy-lub-pasa-ruchu' , true),
            new Section("regulations", "3.5", "5. Wymijanie, omijanie i cofanie (Art. 23)" ,'/nauka/przepisy/wymijanie-omijanie-i-cofanie' , true),
            new Section("regulations", "3.6", "6. Wyprzedzanie (Art. 24)", '/nauka/przepisy/wyprzedzanie', true),
            new Section("regulations", "3.7", "7. Przecinanie się kierunków ruchu (Art. 25-28)", '/nauka/przepisy/przecinanie-sie-kierunkow-ruchu' , true),
            new Section("regulations", "3.8", "8. Ostrzeganie oraz jazda w warunkach zmniejszonej przejrzystości powietrza (Art. 29-30)", '/nauka/przepisy/ostrzeganie-oraz-jazda-w-warunkach-zmniejszonej-przejrzystosci-powietrza' , true),
            new Section("regulations", "3.9", "9. Holowanie (Art. 31)", '/nauka/przepisy/holowanie' , true),
            new Section("regulations", "3.10", "10. Ruch pojazdów w kolumnie (Art. 32)", '/nauka/przepisy/ruch-pojazdow-w-kolumnie' , true),
            new Section("regulations", "3.11", "11. Dodatkowe dotyczące kierujących rowerami (Art. 33)", '/nauka/przepisy/dodatkowe-dotyczace-kierujacych-rowerami' , true),
            new Section("regulations", "3.12", "12. Dodatkowe dotyczące kierujących pojazdami zaprzęgowymi (Art. 34)", '/nauka/przepisy/dodatkowe-dotyczace-kierujacych-pojazdami-zaprzegowymi' , true),
            new Section("regulations", "title", "Dział 4\nPorządek i bezpieczeństwo (Art. 38-64)", '' , false),
            new Section("regulations", "4.1", "1. Przepisy porządkowe (art. 38-45)<br />- wymagane dokumenty<br />- używanie pasów bezpieczeństwa<br />- przewożenie dzieci oraz innych pasażerów<br />- zachowanie podczas wypadku<br />- zabronione zachowania i czynności", '/nauka/przepisy/przepisy-porzadkowe' , true),
            new Section("regulations", "4.2", "2. Zatrzymanie i postój pojazdu (Art. 46-50a)",'/nauka/przepisy/zatrzymanie-i-postoj-pojazdu' , true),
            new Section("regulations", "4.3", "3. Używanie świateł zewnętrznych pojazdu (Art. 51-52)", '/nauka/przepisy/uzywanie-swiatel-zewnetrznych-pojazdu' , true) ,
            new Section("regulations", "4.4", "4. Warunki używania pojazdów w ruchu drogowym (Art. 53-64)<br />- pojazd uprzywilejowany<br />- pojazd wykonujący prace porządkowe na drodze<br />- przewożenie pasażerów samochodem ciężarowym oraz autobusem<br />- sygnały błyskowe<br />- zabronione czynności<br />- przewożenie i mocowanie ładunku<br /> -pojazd nienormatywny", '/nauka/przepisy/warunki-uzywania-pojazdow-w-ruchu-drogowym' , true)
]