import React , { useEffect } from 'react';
import Layout from '../../components/dashboard/layout/Layout';
import Pricing from '../../components/website/pricingSection/Pricing';

const PricingDashboard = () => {

    useEffect(() => {
        window.scrollTo(0 , 0);
    },[])

    return (       
        <Layout
            isPricingDashboardPage={true}
        >
            <div className="PricingDashboard__header">
                <p>Odblokuj pakiet premium w wybranym dla siebie czasie dostępu</p>
            </div>
            <Pricing/>
        </Layout>   
    )

}

export default PricingDashboard