import React from 'react';
import { PricingColumnData } from '../../../data/PricingColumnData';
import PricingColumn from './PricingColumn';
import './Pricing.css';

const Pricing = (props) => {

    let PricingColumnList =  PricingColumnData.map((item) =>
    <PricingColumn 
        data={item} 
        key={item.id} 
        isInMainPage={props.isInMainPage}
    />
    )

    return (

        <div className='Pricing__row'>
            {PricingColumnList}
        </div>
        
    )
}

export default Pricing