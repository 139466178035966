import React from 'react'
import App from './App'

const Root = () => {

    if(process.env.REACT_APP_ENV === "PRODUCTION")
    return(
        <App />
    )
    else
    return(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    )

}

export default Root