import React from 'react';
import ProgressCircle from '../../dashboard/progressCircle/ProgressCircle';
import SmallSpinner from '../../others/spinner/SmallSpinner';
import './AccountProgress.css';

const AccountProgress = (props) => {

  if(props.progress !== null && props.progress !== undefined)
  return (
    <div className='AccountProgress__Container'>
        <div className='AccountProgress__Progress'>
          <ProgressCircle             
              color={props.color}
              progress={props.progress}
          />
        </div>
        <p style={{color: props.color}}>{props.title}</p>
    </div>
  )
  else if(props.progress === null || props.progress === undefined)
  return (

      <div className='AccountProgress__Container'>
          <div className="ProgressCircle">
            <SmallSpinner isText={true}/>
          </div>
      </div>
  )
}

export default AccountProgress
