import React from 'react';
import { InformationBeforExamData } from '../../../data/InformationBeforExamData';
import Alert from '../../others/alert/Alert';
import CustomButton from '../../others/button/CustomButton';
import DemoExamsCounter from '../demoExamsCounter/DemoExamsCounter';
import './InformationBeforExam.css';

const InformationBeforExam = (props) => {
  return (
    <div className='InformationBeforExam__Container'>
        <DemoExamsCounter />
        <Alert type="ExamInformation" data={InformationBeforExamData}/>
        <CustomButton 
          content={'Zacznij egzamin'} 
          widthInPercent={100} 
          onClick={props.functionExamStarted}
          loading={props.loading}
        />
    </div>
  )
}

export default InformationBeforExam