import store from '../redux/store';
import { showToast , resetModalSettings } from '../redux/actions/notificationActions';

export const detectNetworkConnection = () => {

    const isOnline = navigator.onLine;
    if(isOnline){
        return true;
    }else{
        store.dispatch(showToast("Problem z połączeniem internetowym" , "error")).then(() => {
            store.dispatch(resetModalSettings());
        })     
        return false;
    }
}