import React , { useState } from 'react';
import './InformationDuringExam.css';
import CustomButton from '../../others/button/CustomButton';
import QuestionCounter from '../questionCounter/QuestionCounter';
import QuestionTimer from '../questionTimer/QuestionTimer';
import { useDispatch , useSelector } from 'react-redux';
import { sendAnswerToExam , setNextActiveQuestion } from '../../../redux/actions/examActions';
import EndExamButton from '../endExamButton/EndExamButton';

const InformationDuringExam = (props) => {

  const dispatch = useDispatch();
  const selectedAnswer = useSelector((state) => state.exam.selectedAnswer);
  const timeInExamEnabled = useSelector((state) => state.user.user.options.timeInExam);
  const baseQuestionNumber = useSelector((state) => state.exam.baseQuestionNumber);
  const specialistQuestionNumber = useSelector((state) => state.exam.specialistQuestionNumber);
  const [ resetTimer , setResetTimer ] = useState();

  function handleNextQuestion(){
      dispatch(sendAnswerToExam(selectedAnswer));
      dispatch(setNextActiveQuestion(false));
      setResetTimer(!resetTimer);
  }

  return (
    <>
    <div className='InformationDuringExam__Container Flex'>
        
        <div className='Flex'>  

          <EndExamButton  finishExam={props.finishExam}/>
          
          <div className='QuestionCounter__Container'>
            <QuestionCounter text={"Pytania podstawowe"} numberOfQuestion={baseQuestionNumber} maxNumberOfQuestion={20}/>
            <QuestionCounter text={"Pytania specjalistyczne"} numberOfQuestion={specialistQuestionNumber} maxNumberOfQuestion={12}/>
          </div>

          <div className='Timer__Container'>
            {
              timeInExamEnabled &&
              <QuestionTimer questionMedia={props.questionMedia} resetTimer={resetTimer} type={props.type}/>
            }           
          </div>

        </div>      
        
        <CustomButton 
          content={'Następne pytanie'} 
          widthInPercent={100}
          onClick={() => handleNextQuestion()}
          color={"exam"}
        />

    </div>
    </>
  )
}

export default InformationDuringExam