import React from 'react';
import AccountPremiumInformation from '../../../components/account/accountPremiumInformation/AccountPremiumInformation';
import SmallSpinner from '../../../components/others/spinner/SmallSpinner';
import { useUserPremiumTimer } from '../../../contexts/UserPremiumTimerContext';

const Subscription = () => {

    const { loadingTimer } = useUserPremiumTimer();

    if(loadingTimer){
        return(
            <SmallSpinner isText={true}/>
        )
    }
    else
    return (
        <div className='Tab__Container--subscription'>
            <b>Konto premium</b>
            <AccountPremiumInformation
                isInSettingPage={true}
            />
        </div>
    ) 
}

export default Subscription
