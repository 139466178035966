import Cookies from 'js-cookie';

function AcceptAlertAboutPrivateData(){
    try{
        Cookies.set("acceptCookies", true);
    }catch(e){
        console.log(e)
    }  
}

export default AcceptAlertAboutPrivateData;