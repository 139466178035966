import moment from 'moment';
import React, { useContext , useState, useEffect , useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAlertEndPremium } from '../models/ModalContet';
import { showModal } from '../redux/actions/notificationActions';
import { removePremium } from '../redux/actions/userActions';
import store from '../redux/store';

const UserPremiumTimerContext = React.createContext();

export function useUserPremiumTimer(){
    return useContext(UserPremiumTimerContext);
}

export function UserPremiumTimerProvider({ children }){

    const dispatch = useDispatch();

    const premiumActiveTo = useSelector((state) => state.user.premiumActiveTo);
    const isActivePremium = useSelector((state) => state.user.isActivePremium);
    const serverCurrentTimestamp = useSelector((state) => state.user.currentTimestamp);

    const parsedDeadline = useMemo(() => Date.parse(premiumActiveTo), [premiumActiveTo]);
    const [currentTimestamp, setCurrentTimestamp] = useState();

    const [loadingTimer, setLoadingTimer] = useState(true);

    const SECOND = 1000

    useEffect(() => {

        let interval; 

        if((isActivePremium && Date.parse(premiumActiveTo) > new Date(serverCurrentTimestamp))){
            setCurrentTimestamp(serverCurrentTimestamp);
            interval = setInterval(
                () => {
                    calculateTime();
                    setLoadingTimer(false);
                    handlerInterval(interval)
                },
                SECOND,
            );
        }

        if(isActivePremium === false){
            setLoadingTimer(false);
        }

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parsedDeadline, isActivePremium]);

    const calculateTime = () => {
        let result = moment(currentTimestamp).add(1, 's');
        setCurrentTimestamp(result);
        return result;
    }

    function handlerInterval(interval){

        const currentTime = calculateTime();

        if(Date.parse(premiumActiveTo) <= new Date(currentTime)){
            clearInterval(interval)
            dispatch(removePremium());
            const showedExamSummaryModal = store.getState().exam.showedExamSummaryModal;
            if (!showedExamSummaryModal) {
                dispatch(showModal(createAlertEndPremium()));
            }      
        }
    }

    const value = {
        loadingTimer
    }

    return(
        <UserPremiumTimerContext.Provider value={value}>
            {children}
        </UserPremiumTimerContext.Provider>
    )
}