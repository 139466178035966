import { ActionUserTypes } from '../contants/action-types';
import { showToast, resetModalSettings } from '../actions/notificationActions';
import agent from '../../api/agent';
import { ChecksumCheckerService } from '../../services/ChecksumCheckerService';
import { computeProgressInQuestions } from './questionActions';
import store from '../store';
import { resetActiveQuestion } from './wrongActions';
import history from '../../helpers/history';
import moment from 'moment';

export const getUser = () => async dispatch => {
    try{

        console.log("userActions getUser")
        let user = await agent.User.getUser();
        console.log("user:")
        console.log(user)
        let isUserUndefinied = user === undefined
        while(isUserUndefinied){
            console.log("user undefinied next atempt...")
            user = await agent.User.getUser();
            if(user !== undefined){
                console.log("user definied:")
                console.log(user)
                isUserUndefinied = false
            }
        }

        const currentTimestamp = user.currentTimestamp;

        let categoryToShow = getCategoryToShow(user.options.category);
        
        const isActivePremium = checkIfPremiumIsActive(user.premiumExpirationTimestamp, currentTimestamp);

        let premiumActiveTo;

        if(user.premiumExpirationTimestamp !== null && user.premiumExpirationTimestamp !== undefined){
            premiumActiveTo = new Date(user.premiumExpirationTimestamp).toString();
        }else{
            premiumActiveTo = null;
        }
        
        dispatch({
            type: ActionUserTypes.GET_USER,
            payload: { user , categoryToShow, isActivePremium, premiumActiveTo , currentTimestamp}
        })

        new ChecksumCheckerService().synchronizeQuestionsAnswers(user.questionsAnswersChecksum, user.questionsAnswersTimestamp, user.questionsAnswersReset)
        new ChecksumCheckerService().synchronizeSignsAnswers(user.signsAnswersChecksum, user.signsAnswersTimestamp, user.signsAnswersReset)
        new ChecksumCheckerService().synchronizeRegulationsAnswers(user.regulationsAnswersChecksum, user.regulationsAnswersTimestamp, user.regulationsAnswersReset)

    } catch (error){
        console.log(error)
    }
}

export const setLastTimeRefreshTokenDate = () => {
    return{
        type: ActionUserTypes.SET_LAST_TIME_REFRESH_TOKEN_DATE,
        payload: moment().format('YYYY-MM-DD HH:mm:ss')
    };
};

export const setCategory = (category) => async dispatch => {

    try{

        dispatch(setLoading());

        let settings = { category: category};
        let result = await agent.User.patchOptions(settings);
        
        let returnedCategory = result.category;
        let categoryToShow = getCategoryToShow(returnedCategory);
        
        dispatch({
            type: ActionUserTypes.SET_CATEGORY,
            payload: { returnedCategory , categoryToShow }
        })

        dispatch(computeProgressInQuestions(returnedCategory));
        dispatch(resetActiveQuestion());
        dispatch(showToast("Zmiany zostały poprawnie zapisane" , "success"));
        dispatch(resetModalSettings());

    }catch(error){
        console.log(error)
    }
}

export const setAutoPlay = (autoPlay) => async dispatch => {
    try{
    
        let settings = { autoPlay: autoPlay};  
        let result = await agent.User.patchOptions(settings);

        dispatch({
            type: ActionUserTypes.SET_AUTO_PLAY,
            payload: result.autoPlay
        })

        dispatch(showToast("Zmiany zostały poprawnie zapisane" , "success"));
        dispatch(resetModalSettings());

    }catch(error){
        console.log(error)
    }
}


export const setTimeInExam = (timeInExam) => async dispatch => {
    try{
    
        let settings = { timeInExam: timeInExam};  
        const result = await agent.User.patchOptions(settings);

        dispatch({
            type: ActionUserTypes.SET_TIME_IN_EXAM,
            payload: result.timeInExam
        })

        dispatch(showToast("Zmiany zostały poprawnie zapisane" , "success"));
        dispatch(resetModalSettings());

    }catch(error){
        console.log(error)
    }
}


export const setLoading = () => {

    return{
        type: ActionUserTypes.SET_LOADING,
        payload: true
    };

};


export const logOut = () => {

    history.push("/logowanie");

    return{
        type: ActionUserTypes.LOG_OUT,
        payload: null
    };

};

function getCategoryToShow(category){
    switch (category) {
        case 'A':
            return 'A, A1, A2, AM';
        case 'B':
            return 'B, B1';
        case 'C':
            return 'C, C1';
        case 'D':
            return 'D, D1';
        case 'T':
            return 'T';
        default: return null;
    }
}

const checkIfPremiumIsActive = (premiumExpirationTimestamp, currentTimestamp) => {

    if(premiumExpirationTimestamp === null){
        return false;
    }
    
    const date = new Date(premiumExpirationTimestamp);
    const currentDate = new Date(currentTimestamp);

    if(date > currentDate){
        return true;
    }else{
        return false;
    }

}

export const removePremium = () => {

    return{
        type: ActionUserTypes.REMOVE_PREMIUM,
        payload: null
    };

};

export const incrementDemoUsedExamsNumber = () => {

    let demoUsedExams = store.getState().user.user.demoUsedExams;    
    demoUsedExams++;

    return{
        type: ActionUserTypes.INCREMENT_DEMO_USED_EXAMS_NUMBER,
        payload: demoUsedExams
    };
};

