import React , { useEffect , useState } from 'react';
import { useParams } from "react-router-dom";
import Layout from '../../components/dashboard/layout/Layout';
import TranslateNameFromPathForRouting from '../../helpers/TranslateNameFromPathForRouting';
import { TranslateNameFromPathToSectionForSigns } from '../../helpers/TranslateNameFromPathToSection';
import LearningItem from '../../components/dashboard/learningItem/LearningItem';
import { useSelector } from 'react-redux';
import SmallSpinner from '../../components/others/spinner/SmallSpinner';
import ValidateUrl from '../../helpers/ValidateUrl';

const Signs = () => {

    const { id } = useParams();
    const signsStore = useSelector((state) => state.signs);
    const user = useSelector((state) => state.user.user);
    const singProgress = new Map(Object.entries(signsStore.signsProgress)); 
    const isActivePremium = useSelector((state) => state.user.isActivePremium);

    const [loading , setLoading] = useState(true);
    const [signs, setSigns] = useState(null);
    const [ nameFromPath ] = useState(TranslateNameFromPathToSectionForSigns(id));

    const breadcrumb = [
        { text:'Nauka', link:'/nauka' },
        { text:'Znaki', link:'/nauka/znaki' },
        { text: TranslateNameFromPathForRouting(id) , link:'/nauka/znaki/'+id}
    ]

    useEffect(() => {

        if(user !== null){

            let isValidateUrl = true;
            isValidateUrl = ValidateUrl(id, 'signs');

            if(!isValidateUrl || signsStore.signs.length === 0 || Array.isArray(signsStore.signsAnswers)){
                setLoading(true);
            }else{
                setSigns(signsStore.signs);
                setLoading(false);
            }

        }
        
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[signsStore.signs , user, isActivePremium, signsStore.signsAnswers])

    useEffect(() => {
        window.scrollTo(0 , 0);
    },[])

    return (
        <> 
            { loading && 
                 <SmallSpinner 
                 isText={true}
                 isCenter={true}
              />
            }  
            { 
            !loading && 
            signs !== null &&
            <Layout title={breadcrumb} isProgressCircleActive={true} progress={singProgress.get(nameFromPath)} color={'#41B62B'}>
                <div style={{marginBottom: '80px'}}>
                {
                    signs.filter((item) => item.section === nameFromPath)
                    .sort((a,b) => (a.numInSection > b.numInSection ? 1: -1))
                    .map((item, i) =>
                            <LearningItem key={i} data={item} answer={signsStore.signsAnswers[item.id.toString()]} type={'sign'}/>  
                    )
                }
                </div>
            </Layout>
            }
        </>
    )
}

export default Signs
