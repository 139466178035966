export const getLocalStorageItemOrDefault = (itemName, defaultObject) => {
    try{
        let result = localStorage.getItem(itemName);
        if(result === undefined) throw new Error('Undefined exception');
        if(result === null) throw new Error('Null exception');
        return result;
    }catch(e){
        return defaultObject;
    }
}

export const getLocalStorageItemParsedJsonOrDefault = (itemName, defaultObject) => {
    try{
        let result = JSON.parse(localStorage.getItem(itemName));
        if(result === undefined) throw new Error('Undefined exception');
        if(result === null) throw new Error('Null exception');
        return result;
    }catch(e){
        return defaultObject;
    }
}