const { createAlertForNotSupportedBrowserVersion } = require('../models/ModalContet');
const { showModal } = require('../redux/actions/notificationActions');
const { default: store } = require('../redux/store');

const detectBrowserVersion = () => {
    const { detect } = require('detect-browser');
    const browser = detect();
    let blockadeLoginAndRegistration = false;
    let lowestRequiredBrowserVersion;
    let linkToUpdate;

    try{
        switch (browser && browser.name){
            case 'edge':
                if (parseInt(browser.version) < 80){
                    blockadeLoginAndRegistration = getDataForEdge(browser);
                }
                break;
            case 'edge-ios':
                if (parseInt(browser.version) < 80){
                    blockadeLoginAndRegistration = getDataForEdge(browser);
                }
                break;
            case 'edge-chromium':
                if (parseInt(browser.version) < 80){
                    blockadeLoginAndRegistration = getDataForEdge(browser);
                }
                break;
            case 'firefox':
                if (parseInt(browser.version) < 67){
                    lowestRequiredBrowserVersion = '67';
                    linkToUpdate = 'https://support.mozilla.org/pl/kb/Aktualizacja%20Firefoksa?redirectslug=update-firefox-latest-version';
                    const modalData = createAlertForNotSupportedBrowserVersion(browser.name, browser.version, lowestRequiredBrowserVersion, linkToUpdate);
                    store.dispatch(showModal(modalData));
                    blockadeLoginAndRegistration = true;
                }
                break;
            case 'chrome':
                if (parseInt(browser.version) < 57){
                    lowestRequiredBrowserVersion = '57';
                    linkToUpdate = 'https://support.google.com/chrome/answer/95414?co=GENIE.Platform%3DDesktop&hl=pl';
                    const modalData = createAlertForNotSupportedBrowserVersion(browser.name, browser.version, lowestRequiredBrowserVersion, linkToUpdate);
                    store.dispatch(showModal(modalData));
                    blockadeLoginAndRegistration = true;
                }
                break;
            case 'opera':
                if (parseInt(browser.version) < 44){
                    lowestRequiredBrowserVersion = '44';
                    linkToUpdate = 'https://www.opera.com/pl/download';
                    const modalData = createAlertForNotSupportedBrowserVersion(browser.name, browser.version, lowestRequiredBrowserVersion, linkToUpdate);
                    store.dispatch(showModal(modalData));
                    blockadeLoginAndRegistration = true;
                }
                break;
            case 'safari':
                if (parseInt(browser.version) < 11){
                    lowestRequiredBrowserVersion = '11.1';
                    linkToUpdate = 'https://support.apple.com/pl-pl/HT204416';
                    const modalData = createAlertForNotSupportedBrowserVersion(browser.name, browser.version, lowestRequiredBrowserVersion, linkToUpdate);
                    store.dispatch(showModal(modalData));
                    blockadeLoginAndRegistration = true;
                }
                break;
            case 'samsung':
                break;
            case 'miui':
                break;
            case 'fxios':
                break;
            case 'opera-mini':
                break;
            case 'android':
                break;
            case 'ios':
                break;
            case 'pie':
                break;
            default:
                break;
        }
    }catch(ex){
        console.log(ex);
    }

    return blockadeLoginAndRegistration;
}

const getDataForEdge = (browser) => {
    const lowestRequiredBrowserVersion = '80';
    const linkToUpdate = 'https://www.microsoft.com/pl-pl/edge/download?form=MA13FJ';
    const modalData = createAlertForNotSupportedBrowserVersion(browser.name, browser.version, lowestRequiredBrowserVersion, linkToUpdate);
    store.dispatch(showModal(modalData));
    return true;
}

export default detectBrowserVersion;