import React , { useEffect } from 'react';
import NavigationBar from '../../components/website/navigationBar/NavigationBar';
import FooterSection from '../../components/website/footerSection/FooterSection';
import Terms from '../../components/website/termsSection/Terms';
import {PrivacyPolicyData} from "../../data/PrivacyPolicyData";
import CookieModal from '../../components/website/cookiesModal/CookieModal';
import { CookiesInformationData } from '../../data/CookiesInformationData';

const PrivacyPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
            <NavigationBar />
            <Terms data={PrivacyPolicyData}/>
            <FooterSection />
            <CookieModal data={CookiesInformationData}/>
        </>
    )
}

export default PrivacyPolicy
