import React, { useContext , useState, useEffect } from 'react';
import { auth } from '../Firebase';

const AuthContext = React.createContext();

export function useAuth(){
    return useContext(AuthContext);
}

export function AuthProvider({ children }){

    const [currentUser , setCurrentUser] = useState();
    const [loading , setLoading] = useState(true);
    
    async function refreshJwtToken(){
        const token = await auth.currentUser.getIdToken(true);
        return token;
    }

    async function getJwtToken(){
        const token = await auth.currentUser.getIdToken();
        return token;
    }

    function signup(email , password){
        localStorage.setItem("access_token" , Date.now().toString(36) + Math.random().toString(36).substring(2));
        return auth.createUserWithEmailAndPassword(email, password);
    }

    function login(email, password){
        localStorage.setItem("access_token" , Date.now().toString(36) + Math.random().toString(36).substring(2));
        return auth.signInWithEmailAndPassword(email, password);
    }

    function logout(){
        return auth.signOut();
    }

    function resetPassword(email){
        return auth.sendPasswordResetEmail(email)
    }

    function updateEmail(email){
        return currentUser.updateEmail(email);
    }

    function updatePassword(password){
        return currentUser.updatePassword(password);        
    }

    function loginBySocialMedia(provider){
        return auth.signInWithPopup(provider);
    }

    function verifyPasswordResetCode(actionCode){
        return auth.verifyPasswordResetCode(actionCode);
    }

    function confirmPasswordReset(actionCode, newPassword){
        return auth.confirmPasswordReset(actionCode , newPassword);
    }

    function reauthWithSocialMedia(provider){
        return currentUser.reauthenticateWithPopup(provider);
    }

    function deleteCurrentUser(){
        return currentUser.delete();
    }

    function reauthWithEmail(emailCred){
        return auth.currentUser.reauthenticateWithCredential(emailCred);
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged( user => {
            setCurrentUser(user);
            setLoading(false);      
        })
        return unsubscribe
    }, [])

    
    const value = {
        currentUser,
        getJwtToken,
        refreshJwtToken,
        login,
        logout,
        signup,
        resetPassword,
        updateEmail,
        updatePassword,
        loginBySocialMedia,
        verifyPasswordResetCode,
        confirmPasswordReset,
        reauthWithSocialMedia,
        deleteCurrentUser,
        reauthWithEmail
    }

    return(
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}