import { ActionDepartamentTypes } from '../contants/action-types';
import store from '../store'

export const setActiveQuestion = (type) =>  {

    try{
        const questions = store.getState().questions.questions;
        const questionsAnswers = store.getState().questions.questionsAnswers;
        const section = store.getState().departament.currentSection;
        const category = store.getState().user.user.options.category;
        let exList = store.getState().departament.exList;
        let activeQuestion;
    
        if(section === undefined){
            activeQuestion = null;
        }else{
    
            if(type === 'basicQuestions'){
                activeQuestion = getActiveQuestionForBasicQuestionsBySection(section, questions, questionsAnswers, exList, category);
            }else if(type === 'specialistQuesions'){
                activeQuestion = getActiveQuestionForSpecialistQuestionsBySection(section, questions, questionsAnswers, exList, category);
            }
    
            if (activeQuestion === undefined && exList.length > 0) {
                activeQuestion = questions.find(q => q.id === parseInt(exList[0])); 
            }
        }
    
        return{
            type: ActionDepartamentTypes.SET_ACTIVE_QUESTION,
            payload: activeQuestion
        };
    }catch(e){
        console.log(e);
    }  
}

const getActiveQuestionForBasicQuestionsBySection = (section, questions, questionsAnswers, exList, category) => {
    return questions.find(q => 
                                q.category.includes(category) && 
                                q.type === 'p' && 
                                q.section === section && 
                                questionsAnswers[q.id.toString()] !== 2 && 
                                !exList.includes(q.id.toString())
                        );
}

const getActiveQuestionForSpecialistQuestionsBySection = (section, questions, questionsAnswers, exList, category) => {
    let numberFromSection = section.match(/\d+/)[0];

    if(numberFromSection === '1'){
        return questions.find(q => 
                                    q.section.includes(numberFromSection) && 
                                    q.section.length === 2 && 
                                    q.category.includes(category) && 
                                    q.type === 's' &&
                                    questionsAnswers[q.id.toString()] !== 2 && 
                                    !exList.includes(q.id.toString())
                                );
    }

    return questions.find(q => 
                                q.section.includes(numberFromSection) && 
                                q.category.includes(category) && 
                                q.type === 's' &&
                                questionsAnswers[q.id.toString()] !== 2 && 
                                !exList.includes(q.id.toString())
                            );
}

export const setCurrentSection = (section) => {

    return{
        type: ActionDepartamentTypes.SET_CURRENT_SECTION,
        payload: section
    };
    
}

export const resetActiveQuestion = () => {

    const activeQuestion = null;

    return{
        type: ActionDepartamentTypes.RESET_ACTIVE_QUESTION,
        payload: activeQuestion
    };
    
}

export const deleteQuestionFromExList = (id) => {
    return{
        type: ActionDepartamentTypes.DELETE_QUESTION_FROM_EX_LIST,
        payload: id
    }
}

export const moveQuestionFromExList = (id) => {
    return{
        type: ActionDepartamentTypes.MOVE_QUESTION_FROM_EX_LIST,
        payload: id
    }
}

export const deleteAllQuestionFromExList = () => {

    const emptyArray = [];

    return{
        type: ActionDepartamentTypes.DELETE_ALL_QUESTIONS_FROM_EX_LIST,
        payload: emptyArray
    }

}