import React from 'react'
import './BulletPoint.css'
import { FaCheckCircle } from 'react-icons/fa'

const BulletPoint = (props) => {
    return (
    <div className="BulletPoint__row">
        <div className="BulletPoint__icon"><FaCheckCircle/></div> <p>{props.data}</p>
    </div>
    )
}

export default BulletPoint
