import axios from 'axios';
import history from '../helpers/history';
import store from '../redux/store';
import { showToast , resetModalSettings } from '../redux/actions/notificationActions';
import logout from '../helpers/Logout';
import refreshFirebaseToken from '../helpers/RefreshFirebaseToken';

axios.interceptors.request.use( 
    async config => {
        try{
            const token = await refreshFirebaseToken();
            config.headers.Authorization = `Bearer ${token}`;
            addApiVersion(config);
            return config;
        }catch(ex){
           await logout();
        }
    },
    error => {
        console.log(error);
        Promise.reject(error);
    }
)

const addApiVersion = (config) => {
    config.url += `?api=${process.env.REACT_APP_API_VERSION}`;
}

axios.interceptors.response.use(async response => {
    return response.data;
}, async function (error) {

    if(error.code === 'auth/network-request-failed'){
        store.dispatch(showToast("Problem z połączeniem internetowym" , "error")).then(() => {
            store.dispatch(resetModalSettings());
        })   
    }

    if(typeof error.response !== 'undefined'){

        let { status } = error.response;

        switch (status) {
            case 403:
                await logout();
                return Promise.reject(error.response.data);
            case 404:
                store.dispatch(showToast("Nie znaleziono podanej wartości" , "error"));
                store.dispatch(resetModalSettings());
                return Promise.reject(error.response.data);
            case 500:
                history.push("/wewnetrzny-blad-serwera");
                return Promise.reject(error.response.data);
            default: 
                store.dispatch(showToast("Coś poszło nie tak, spróbuj ponownie" , "error"));
                store.dispatch(resetModalSettings());
                return Promise.reject(error.response.data);
        }

    }
})

const apiPrefix = '/api';

const responseBody = (response) => response;

const requests = {
    get: (url) => axios.get(apiPrefix + url).then(responseBody),
    patch: (url , body) => axios.patch(apiPrefix + url , body).then(responseBody),
    del: (url) => axios.delete(apiPrefix + url).then(responseBody),
    post: (url , body) => axios.post(apiPrefix + url , body).then(responseBody)
}

const delUser = (token) => {
    const config = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    };

    const url = `${apiPrefix}/user?api=${process.env.REACT_APP_API_VERSION}`;

    return fetch(url, config);
}

const User = {
    getUser: () => requests.get('/user').then(responseBody),
    getAnswers: () => requests.get('/user/answers').then(responseBody),
    getOptions: () => requests.get('/user/options').then(responseBody),
    patchOptions: (options) => requests.patch('/user/options' , options),
    getInfo: () => requests.get('/info').then(responseBody),
    delUser
}

const Questions = {
    getQuestions: () => requests.get('/questions').then(responseBody),
    getQuestionsBasic: () => requests.get('/variables/questionsbasic').then(responseBody),
    getQuestionsBasicChecksum: () => requests.get('/variables/questionsbasic-checksum').then(responseBody)
}

const Signs = {
    getSigns: () => requests.get('/signs').then(responseBody)
}

const Regulations = {
    getRegulations: () => requests.get('/regulations').then(responseBody)
}

const UserAnswers = {
    getSigns: () => requests.get('/user/answers/signs').then(responseBody),
    patchSigns: (signs) => requests.patch('/user/answers/signs' , signs),
    deleteSigns: () => requests.del('/user/answers/signs').then(responseBody),
    getRegulations: () => requests.get('/user/answers/regulations').then(responseBody),
    patchRegulations: (regulations) => requests.patch('/user/answers/regulations' , regulations),
    deleteRegulations: () => requests.del('/user/answers/regulations').then(responseBody),
    getQuestions: () => requests.get('/user/answers/questions').then(responseBody),
    patchQuestions: (questions, resetParam) => requests.patch('/user/answers/questions' + resetParam , questions),
    deleteQuestions: () => requests.del('/user/answers/questions').then(responseBody)
}

const Payments = {
    registerTransaction: (sku) => requests.post(`/user/account/premium/przelewy24/register?sku=${sku}&`),
    checkPaymentStatus: (sessionId) => requests.get(`/user/account/premium/przelewy24/status?sessionId=${sessionId}&`).then(responseBody)
}

const ExamDemo = {
    getExamDemo: () => requests.get('/demo/exam').then(responseBody)
}

const agent = {
    User,
    Questions,
    Signs,
    Regulations,
    UserAnswers,
    Payments,
    ExamDemo
}

export default agent;