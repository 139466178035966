import React , { useState } from 'react';
import CustomButton from '../../others/button/CustomButton';
import { BiCookie } from 'react-icons/bi';
import './CookieModal.css';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

const CookieModal = (props) => {

  const [closeCookieModal , setCloseCookieModal] = useState(Cookies.get("acceptCookies"));

  function handleCloseCookieModal(){
      setCloseCookieModal(true);
      props.data.fun();
  }

  return (
    <>
      {
      !closeCookieModal && 
      <div className='CookieModal__container'>
        <div className='CookieModal__icon'>
            <BiCookie />
        </div> 
        <div className='CookieModal__content'>
            {props.data.content}
            {props.data.link && <Link to={props.data.link}>{props.data.linkContet}</Link>}
        </div>
        <CustomButton content={props.data.buttonContent} color={"blue"} onClick={handleCloseCookieModal}/>
    </div>
    }
    </>

  )
}

export default CookieModal