import React from 'react';
import { useField , ErrorMessage } from 'formik';
import './TextField.css';

const TextField = ({ ...props}) => {
    const [field , meta] = useField(props);

    return (
        <div className="form-group">
            <input
            className={ meta.touched && meta.error ? 'Input--large form-control is-invalid' : 'Input--large form-control'}
            placeholder={props.placeholder}
            type={props.type}
            autoComplete={props.type === 'email' ? props.type : 'off'}
            {...field}{...props}
            />
            <ErrorMessage component="div" name={field.name} className='UserPanel__errorDisplay'/>
        </div>
    )
}

export default TextField
