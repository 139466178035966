import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Layout from '../../components/dashboard/layout/Layout';
import Question from '../../components/dashboard/question/Question';
import ExamSummary from '../../components/exams/examSummary/ExamSummary';
import { getQuestionsForExam, clearExamData, setNextActiveQuestion, computeExamResult , setSelectedAnswer , setIsExamStarted } from '../../redux/actions/examActions';
import { showModal , closeModal } from '../../redux/actions/notificationActions';
import SmallSpinner from '../../components/others/spinner/SmallSpinner';
import InformationBeforExam from '../../components/exams/informationBeforExam/InformationBeforExam';
import "./Dashboard.css";
import InformationDuringExam from '../../components/exams/informationDuringExam/InformationDuringExam';
import { createAlertDuringExamModalContent, createAlertUnlockPremium } from '../../models/ModalContet';
import { useHistory } from "react-router-dom";
import QuestionsSummary from '../../components/exams/questionsSummary/QuestionsSummary';

const Exam = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const examStore = useSelector((state) => state.exam);
    const user = useSelector((state) => state.user.user);
    const isActivePremium = useSelector((state) => state.user.isActivePremium);
    const activeQuestion = examStore.activeQuestion;
    const currentIndex = examStore.currentIndex;
    const examResult = examStore.examResult;
    const isExamStarted = examStore.isExamStarted;
    const refreshExamPage = examStore.refreshExamPage;

    const [examFinished, setExamFinished] = useState(false);
    const [showQuestionsSummary, setShowQuestionsSummary] = useState(false);
    const [currentNumOfQuestion, setCurrentNumOfQuestion] = useState();
    const [hideBreadcrumb , setHideBreadcrumb] = useState(false);
    const [breadCrumbText , setBreadCrumbText] = useState('Egzamin'); 
    const [examForSmallDevices , setExamForSmallDevices] = useState(false);
    const [buttonStartExamLoading, setButtonStartExamLoading] = useState(false);
    const [loading, setLoading] = useState(isExamStarted ? false : true);
    
    const breadcrumb = [{ text: breadCrumbText, link: '/egzamin' }]
    const alertUnlockPremium = createAlertUnlockPremium();
    const alertDuringExamData = createAlertDuringExamModalContent(handleCloseAlertDuringExam);

    function handleCloseAlertDuringExam(){
        dispatch(setIsExamStarted(false));
        dispatch(closeModal());
        history.goBack();
    }

    useEffect(() => {
        if(user !== null && isExamStarted === false && !examFinished){
            if(window.innerWidth <= 1023)
                setExamForSmallDevices(true);       
            if(isActivePremium){
                dispatch(clearExamData());
                dispatch(getQuestionsForExam())
                    .then(response => {console.log(response)})
                    .catch(error => {console.log(error)});
                dispatch(setNextActiveQuestion(true));
            }
            dispatch(closeModal());
            dispatch(setSelectedAnswer(0));
            setTimeout(()=>setLoading(false), 200)
        }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user , isExamStarted]);

    useEffect(() => {
        setCurrentNumOfQuestion(currentIndex + 1)

        if(currentIndex >= 20){
            setBreadCrumbText('Część specjalistyczna');
        }

        if (currentIndex >= 32) {
            finishExam();
        }

        console.log("useEffect currentIndex changed:" + currentIndex + " currentNumOfQuestion:" + currentNumOfQuestion)
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentIndex])

    useEffect(() => {
        if(refreshExamPage){
            nextEgzam();
        }      
    //eslint-disable-next-line react-hooks/exhaustive-deps
    } , [refreshExamPage])

    useEffect(() => {
            function handleResize(){
                if(window.innerWidth <= 1023 && !examForSmallDevices)
                    setExamForSmallDevices(true);                        
                if(window.innerWidth >= 1024 && examForSmallDevices)
                    setExamForSmallDevices(false);              
            }
            
            window.addEventListener('resize', handleResize)
    
            return _ => { window.removeEventListener('resize', handleResize) }
    })

    useEffect(() => {
        if(window.innerWidth <= 1023 && !examForSmallDevices){
            setExamForSmallDevices(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeQuestion] )


    useEffect(() => {

        if(isExamStarted){
            window.addEventListener("beforeunload", alertBeforeRefreshPage);
        }
        
        return () => {
          window.removeEventListener("beforeunload", alertBeforeRefreshPage);
        };
    }, [isExamStarted]);
  
    const alertBeforeRefreshPage = (e) => {
        e.preventDefault();
        e.returnValue = "";
    };

    useEffect(() => {
        return () => {
          if (history.action === "POP" && isExamStarted) {
                dispatch(showModal(alertDuringExamData));
                history.go(1);
          }
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, isExamStarted])

    function nextEgzam() {
        setBreadCrumbText("Egzamin")
        dispatch(setIsExamStarted(false));
        setExamFinished(false)
        setShowQuestionsSummary(false)
        if(isActivePremium){
            dispatch(clearExamData());
            dispatch(getQuestionsForExam())
                .then(response => {console.log(response)})
                .catch(error => {console.log(error)});
            dispatch(setNextActiveQuestion(true));
        }
    }

    async function startExam(){
        if(await checkIfExistsAvailableExamSetsForNotActivePremiumUser()){
            setBreadCrumbText("Część podstawowa");
            dispatch(setIsExamStarted(true));
        }  
    }

    function finishExam(){
        setExamFinished(true);
        setHideBreadcrumb(true);
        dispatch(setIsExamStarted(false));
        dispatch(computeExamResult());
    }

    async function checkIfExistsAvailableExamSetsForNotActivePremiumUser(){
        if(!isActivePremium && user.demoUsedExams === 3){
            dispatch(showModal(alertUnlockPremium));
            return false;
        }else{
            setButtonStartExamLoading(true);
            dispatch(clearExamData());
            let result = false;
            await dispatch(getQuestionsForExam()).then(response => {
                dispatch(setNextActiveQuestion(true));
                result = true;
            }).catch(error => {
                console.log(error);
            });  
            setButtonStartExamLoading(false);
            return result;    
        }
    }

    function showExamResult(){
        setShowQuestionsSummary(true);
        setHideBreadcrumb(false);
        setBreadCrumbText("Podsumowanie egzaminu");
    }

    if(loading)
    return(
        <SmallSpinner 
            isText={true}
            isCenter={true}
        />
    )
    else
    return (
        <>
        {
            !hideBreadcrumb &&
            <Layout title={breadcrumb} isActiveButtonInExam={showQuestionsSummary} nextEgzam={nextEgzam}>
                    {
                        !isExamStarted && !examFinished &&
                        <>
                            <InformationBeforExam functionExamStarted={ () => startExam()} loading={buttonStartExamLoading} />
                        </>
                    }
                    {
                        isExamStarted && 
                        !examFinished && 
                        activeQuestion !== null && 
                        typeof activeQuestion !== "undefined" &&
                        <>   
                            <div className="Dashboard__flex Exam">
                                <Question type={'exam'} data={activeQuestion} selectedCategory={user.options.category} finishExam={finishExam}/>
                                {
                                    !examForSmallDevices &&
                                    <InformationDuringExam 
                                        finishExam={finishExam}
                                        questionMedia={activeQuestion.media}
                                        type={activeQuestion.type}
                                    />
                                }
                            </div>
                            
                        </>
                    }
                    {
                        showQuestionsSummary && examFinished &&
                        <QuestionsSummary />
                    }
            </Layout>
        }   
        {
            examFinished && !showQuestionsSummary &&
            <ExamSummary data={examResult} showExamResult={showExamResult} />                             
        }
        </>
    )
}


export default Exam
