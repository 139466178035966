import { combineReducers } from "redux";
import { signReducer } from './signReducer';
import { regulationReducer } from './regulationReducer';
import { questionReducer } from "./questionReducer";
import { wrongReducer } from "./wrongReducer";
import { examReducer } from "./examReducer";
import { departamentReducer } from "./departamentReducer";
import { answersReducer } from "./answersReducer";
import { userReducer } from "./userReducer";
import { notificationReducer } from "./notificationReducer";

const reducers = combineReducers({
    notifications: notificationReducer,
    signs : signReducer,
    regulations : regulationReducer,
    questions: questionReducer,
    wrongs: wrongReducer,
    exam: examReducer,
    departament: departamentReducer,
    answers: answersReducer,
    user: userReducer
});

const rootReducer = (state , action) => {

    if(action.type === 'LOG_OUT'){  
        return reducers(undefined, action);
    }

    return reducers(state , action);
}

export default rootReducer;