export class QuestionsWithPoints  {

    constructor(amountOfQuestions, numberOfpoints){
        this.amountOfQuestions = amountOfQuestions
        this.numberOfpoints = numberOfpoints
    }

    substractAmountOfQuestion(amount){
        if(this.amountOfQuestions > 0){
            return this.amountOfQuestions - amount;
        }
        return this.amountOfQuestions;
    }

}