import React , { useState , useEffect } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import Image from 'react-bootstrap/Image';
import './LearningItem.css';
import { useDispatch , useSelector } from 'react-redux';
import { addSignsAnswers, addRegulationsAnswers, refreshPage } from '../../../redux/actions/answersActions'
import ReactHtmlParser from 'react-html-parser'; 
import SmallSpinner from '../../others/spinner/SmallSpinner';
const LearningItem = (props) => {
    
    const dispatch = useDispatch();
    const link = 'https://prawojazdy.pro/tips/' + props.data.link + '.png';
    const text = props.data.text.split("\\n").join("<br />");
    const title = props.data.title !== undefined ? props.data.title.split("\\n").join(" ") : "";

    const translateAnswerToBoolean = (answer) => {
        return answer === 1 ? true : false;
    }
    
    const [ selectItem , setSelectItem] = useState(translateAnswerToBoolean(props.answer));
    const [ loadedImage , setLoadedImage ] = useState(false);

    const refreshSignsPage = useSelector((state) => state.answers.refreshSignsPage);
    const signsAnswers = useSelector((state) => state.signs.signsAnswers);
    const refreshRegulationsPage = useSelector((state) => state.answers.refreshRegulationsPage);
    const regulationsAnswers = useSelector((state) => state.regulations.regulationsAnswers);

    function clickItem(){
        setSelectItem(!selectItem);
        let answer = new Map();
        answer.set(props.data.id.toString() , selectItem === true ? 0 : 1 )
        sendAnswer(answer)
    }

    useEffect(() => {
        if (props.type === 'sign'){
            const lastAnswer = translateAnswerToBoolean(signsAnswers[props.data.id.toString()]);
            if (lastAnswer !== selectItem){
                setSelectItem(lastAnswer);
            }
            dispatch(refreshPage('signs', false));
        }
       //eslint-disable-next-line react-hooks/exhaustive-deps
    },[refreshSignsPage])

    useEffect(() => {
        if (props.type === 'regulation'){
            const lastAnswer = translateAnswerToBoolean(regulationsAnswers[props.data.id.toString()]);
            if (lastAnswer !== selectItem){
                setSelectItem(lastAnswer);
            }
            dispatch(refreshPage('regulations', false));
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshRegulationsPage])

    function sendAnswer(answer){

        switch(props.type) {
            case 'sign':
                dispatch(addSignsAnswers(answer))
                break;
            case 'regulation':
                dispatch(addRegulationsAnswers(answer))
                break;
            default:
                break;
        }
    }

    return (
        <>
        {
            !props.data.type &&
            <div className="LearningItem" onClick={clickItem}>

                <div className="LearningItem__header">
                    {props.data.numInSection}) {props.data.code} {title}
                </div>

                <div className="LearningItem__container">

                        <div className="LearningItem__img">
                            {
                                !loadedImage &&
                                <div>
                                    <SmallSpinner isText = {true} />
                                </div>
                            }
                            <Image 
                                src={link} 
                                fluid 
                                style={loadedImage ? {} : { display: 'none' }}
                                onLoad={() => setLoadedImage(true)}
                                alt={title}
                            />
                        </div>
                
                        <div className="LearningItem__text">
                            {ReactHtmlParser (text)}
                        </div>

                        <div className={selectItem ? "LearningItem__check-selected" : "LearningItem__check" }>
                            <AiFillCheckCircle/>
                        </div>

                </div> 

            </div>
        }
        {
            props.data.type === 'p' &&
            <div className="LearningItem RegulationsItem" onClick={clickItem}> 
            
                <div className="LearningItem__container RegulationsItem__container">
                            
                    <div className="RegulationsItem__text">
                        {ReactHtmlParser (text)}
                    </div>
            
                    <div className={selectItem ? "LearningItem__check-selected" : "LearningItem__check" }>
                        <AiFillCheckCircle/>
                    </div>
                  
                </div>
            </div>
        }
        {
            props.data.type === 'r' &&
            <div className="RegulationsItem__title">
                <h4>{props.data.text}</h4>
            </div>
        }
        {
            props.data.type === 'a' &&
            <div className="RegulationsItem__title">
                <h5>{props.data.text}</h5>
            </div>
        }
        </>
    )
}

export default LearningItem
