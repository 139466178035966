import history from "./history";
import { TranslateNameFromPathToSectionForBasicQuestions, TranslateNameFromPathToSectionForRegulations, TranslateNameFromPathToSectionForSigns, TranslateNameFromPathToSectionForSpecialistQuestions } from "./TranslateNameFromPathToSection";
import store from '../redux/store';
import ValidateAccessForUserWithoutPremium from "./ValidateAccessForUserWithoutPremium";

const ValidateUrl = (urlPath, typeOfPage) => {

    const section = getSection(urlPath, typeOfPage)
    const isActivePremium = store.getState().user.isActivePremium;
    const selectedCategory = store.getState().user.user.options.category

    if(section === null){
        history.push("/nie-znaleziono-strony");
        return false;
    }

    if(!isActivePremium){
        return ValidateAccessForUserWithoutPremium(section , selectedCategory);
    }

    return true;
}

const getSection = (urlPath, typeOfPage) => {
    switch(typeOfPage) {
        case 'signs':
            return TranslateNameFromPathToSectionForSigns(urlPath);
        case 'regulations':
            return TranslateNameFromPathToSectionForRegulations(urlPath);
        case 'basicQuestions':
            return TranslateNameFromPathToSectionForBasicQuestions(urlPath);
        case 'specialistQuesions':
            return TranslateNameFromPathToSectionForSpecialistQuestions(urlPath);
        default:
            return null;
    }
}

export default ValidateUrl
