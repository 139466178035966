import { ActionExamTypes } from '../contants/action-types';
import store from '../store'
import { addQuestionsAnswers } from '../../redux/actions/answersActions';
import { QuestionsWithPoints } from '../../models/QuestionsWithPoints';
import agent from '../../api/agent';
import { incrementDemoUsedExamsNumber } from './userActions';

export const getQuestionsForExam  = () => async dispatch => {
    try{
        const category = store.getState().user.user.options.category;
        const isActivePremium = store.getState().user.isActivePremium;
        let basics, specialists;
    
        if(isActivePremium){
            basics = getQuestionsForExamOfType('p', category).map(question => ({...question}));
            specialists = getQuestionsForExamOfType('s', category).map(question => ({...question}));        
            shuffleArray(basics);
            shuffleArray(specialists);
        }else{
            const { basicExamDemoQuestions, specialistsExamDemoQuestions } = await getQuestionsForExamDemo();
            basics = basicExamDemoQuestions.map(question => ({...question}));;
            specialists = specialistsExamDemoQuestions.map(question => ({...question}));;
        }
    
        let examQuestions = [...basics, ...specialists]
    
        dispatch({
            type: ActionExamTypes.GET_QUESTIONS_FOR_EXAM,
            payload: examQuestions
        });
    }catch(e){
        console.log(e);
        throw new Error('Eror with getting question for user'); 
    }
}

export const setNextActiveQuestion = (isStart=false) => {

    let examQuestions;
    let currentIndex;
    let baseQuestionNumber;
    let specialistQuestionNumber;
    let activeQuestion;

    try{
        console.log(Date.now()+" setNextActiveQuestion start ")
        examQuestions = store.getState().exam.examQuestions;
        currentIndex = store.getState().exam.currentIndex;
        baseQuestionNumber = store.getState().exam.baseQuestionNumber;
        specialistQuestionNumber = store.getState().exam.specialistQuestionNumber;
        
        if(!isStart) currentIndex++;
        activeQuestion = examQuestions[currentIndex]
    
        if(activeQuestion.type === 'p') baseQuestionNumber++;
        if(activeQuestion.type === 's') specialistQuestionNumber++;
    }catch(e){
        console.log(e);
    }

    return{
        type: ActionExamTypes.SET_NEXT_ACTIVE_QUESTION,
        payload: { activeQuestion, currentIndex, baseQuestionNumber, specialistQuestionNumber }  
    };
}

export const clearExamData = () => {
    return{
        type: ActionExamTypes.CLEAR_EXAM_DATA
    }
}

export const sendAnswerToExam = (answer) => {
    let currentIndex = store.getState().exam.currentIndex
    let examQuestions = store.getState().exam.examQuestions
    examQuestions[currentIndex].userAnswer = answer
    examQuestions[currentIndex].isCorrect = examQuestions[currentIndex].trueAnswer === parseInt(answer)
   
    const selectedAnswer = 0;
    
    return{
        type: ActionExamTypes.SEND_ANSWER_TO_EXAM,
        payload: { examQuestions , selectedAnswer }
    }
}

export const computeExamResult = () => {
    
    let examResult = [];
    examResult.isPositive = false;
    examResult.points = 0;
    examResult.positiveCount = 0;

    let examQuestions = store.getState().exam.examQuestions;
    
    examQuestions.forEach(q => {
        if(q.isCorrect === true){
            examResult.points += q.points;
            examResult.positiveCount++;
        }
    })

    if(examResult.points >= 68){
        examResult.isPositive = true
    }

    let mapOfAnswers = new Map()

    examQuestions.forEach( q => {
        if(q.isCorrect){
            mapOfAnswers.set(q.id, 2)
        } else {
            mapOfAnswers.set(q.id, 1)
        }
    })

    store.dispatch(addQuestionsAnswers(mapOfAnswers));

    return{
        type: ActionExamTypes.COMPUTE_EXAM_RESULT,
        payload: examResult
    }
}

export const setSummaryIndex = (index) => {
    return{
        type: ActionExamTypes.SET_SUMMARY_INDEX,
        payload: index
    }
}

function getQuestionsForExamOfType(type, category){
    
    let questions = store.getState().questions.questions.filter(q => q.type === type && q.category.includes(category))
    let questionsAnswers = store.getState().questions.questionsAnswers

    let { questionsWithThreePoints, questionsWithTwoPoints, questionsWithOnePoints } = getArraysWithPointsAndAmountOfQuestionsOfType(type);
    let examQuestions = [];

    examQuestions = [...getQuestionsByPointsAndPriority(questionsWithThreePoints, questions, questionsAnswers)];
    examQuestions = [...examQuestions, ...getQuestionsByPointsAndPriority(questionsWithTwoPoints, questions, questionsAnswers)];
    examQuestions = [...examQuestions, ...getQuestionsByPointsAndPriority(questionsWithOnePoints, questions, questionsAnswers)];

    return examQuestions
}

function getArraysWithPointsAndAmountOfQuestionsOfType(type){
    if(type === 'p'){
        let questionsWithThreePoints = new QuestionsWithPoints(10, 3);
        let questionsWithTwoPoints = new QuestionsWithPoints(6, 2);
        let questionsWithOnePoints = new QuestionsWithPoints(4, 1);
        return { questionsWithThreePoints, questionsWithTwoPoints, questionsWithOnePoints };
    }else{
        let questionsWithThreePoints = new QuestionsWithPoints(6, 3);
        let questionsWithTwoPoints = new QuestionsWithPoints(4, 2);
        let questionsWithOnePoints = new QuestionsWithPoints(2, 1);
        return { questionsWithThreePoints, questionsWithTwoPoints, questionsWithOnePoints };
    }
}

const getQuestionsByPointsAndPriority = (questionsWithPoints, questions, questionsAnswers) =>{
    let examQuestions = [];
    let userAnswer = 0;
    while(questionsWithPoints.amountOfQuestions > 0){
        let temporaryExamQuestions = getQuestions(questionsWithPoints, userAnswer, questions, questionsAnswers);
        questionsWithPoints.amountOfQuestions = questionsWithPoints.substractAmountOfQuestion(temporaryExamQuestions.length);
        if(userAnswer !== 2 && questionsWithPoints.amountOfQuestions > 0){
            userAnswer++;
        }
        examQuestions = [...examQuestions, ...temporaryExamQuestions];
    };
    return examQuestions;
}

const getQuestions = (questionsWithPoints, userAnswer, questions, questionsAnswers) => {

    questions = questions.filter(q => questionsAnswers[q.id.toString()] === userAnswer && q.points === questionsWithPoints.numberOfpoints);

    questions = getRandomsFromArray(questions, questionsWithPoints.amountOfQuestions);

    let questionsCount = questions.length;

    if(questionsCount >= questionsWithPoints.amountOfQuestions){
        return [ ...questions.slice(0, questionsWithPoints.amountOfQuestions)];
    }

    if(questionsCount !== 0){
        return [ ...questions.slice(0, questionsCount)];
    }

    if(questionsCount === 0){
        return [];
    }
}

function getRandomsFromArray(array, count){
    shuffleArray(array);
    return array.slice(0, count);
}

const shuffleArray = array => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
}

export const setIsStartMovie = (isStartMovie) => {
    
    return{
        type: ActionExamTypes.SET_IS_START_MOVIE,
        payload: isStartMovie 
    };
    
}

export const setIsMovieEnded = (isMovieEnded) => {
    
    return{
        type: ActionExamTypes.SET_IS_MOVIE_ENDED,
        payload: isMovieEnded 
    };
    
}

export const setSelectedAnswer = (selectedAnswer) => {
    
    return{
        type: ActionExamTypes.SET_SELECTED_ANSWER,
        payload: selectedAnswer 
    };
    
}

export const setIsExamStarted = (isExamStarted) => {

    return{
        type: ActionExamTypes.SET_IS_EXAM_STARTED,
        payload: isExamStarted
    };

}

export const setRefreshExamPage = (refreshExamPage) => {

    return{
        type: ActionExamTypes.SET_REFRESH_EXAM_PAGE,
        payload: refreshExamPage
    };

}

const getQuestionsForExamDemo = async () => {
    try{
        const demoUsedExams = store.getState().user.user.demoUsedExams;
        if(demoUsedExams !== 3){
            const result = await agent.ExamDemo.getExamDemo();
            const basicExamDemoQuestions = store.getState().questions.questions.filter(q => result.basic.includes(q.id));
            const specialistsExamDemoQuestions = store.getState().questions.questions.filter(q => result.specialist.includes(q.id));
            store.dispatch(incrementDemoUsedExamsNumber());
            return { basicExamDemoQuestions, specialistsExamDemoQuestions }
        }else{
            return [];
        }
    }catch(e){
        console.log(e);
    }
}

export const setShowedSummaryExamPage = (showedSummaryExamPage) => {

    return{
        type: ActionExamTypes.SET_SHOWED_SUMMARY_EXAM_PAGE,
        payload: showedSummaryExamPage
    };

}

export const setshowedExamSummaryModal = (showedExamSummaryModal) => {

    return{
        type: ActionExamTypes.SET_SHOWED_EXAM_SUMMARY_MODEL,
        payload: showedExamSummaryModal
    };

}