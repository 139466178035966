import React , { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextField from '../../../components/userPanel/textField/TextField';
import CustomButton from '../../../components/others/button/CustomButton';
import { useAuth } from '../../../contexts/AuthContext';
import { Alert } from 'react-bootstrap';
import '../../userPanel/UserPanel.css';
import { useToastManager } from '../../../contexts/ToastManagerContext';
import { auth } from '../../../Firebase';
import firebase from 'firebase/compat/app';
import FirebaseErrorTranslateCode from '../../../helpers/FirebaseTranslateCode';
import checkIfCurrentUserExists from '../../../helpers/CheckIfCurrentUserExists';

const ChangePassword = () => {
    
    const validate = Yup.object({
        currentPassword: Yup.string()
        .min(6 , 'Hasło musi mieć minimum 6 znaków')
        .required('Aktualne hasło jest wymagane'),
        password: Yup.string()
            .min(6 , 'Hasło musi mieć minimum 6 znaków')
            .required('Hasło jest wymagane'),
        passwordConfirm: Yup.string()
            .oneOf([Yup.ref('password'),null] ,'Hasła muszą być takie same')
            .required('Powtórzenie hasła jest wymagane')          
    })

    const [ loading , setLoading ] = useState(false)
    const [error , setError] = useState('')

    const { updatePassword , currentUser } = useAuth()
    const { showToast } = useToastManager();

    async function setNewPassword(values){
        
        setError('')
        setLoading(true)

        if(values.currentPassword === values.password){
            setError('Nowe hasło musi być inne niż aktualne');
        }else{

            try{

                await checkIfCurrentUserExists();

                const emailCred = firebase.auth.EmailAuthProvider.credential(currentUser.email , values.currentPassword);

                await auth.currentUser.reauthenticateWithCredential(emailCred).then(() => {
                    updatePassword(values.password).then(response => {
                        showToast('custom-success' , 'Hasło zostało poprawnie zmienione');
                    }).catch(error => {
                        if(error.code === 'auth/wrong-password'){
                            setError("Nie poprawne hasło.")
                        }else{
                            const polishError = FirebaseErrorTranslateCode(error.code)
                            setError(polishError)
                        }                     
                    })
                }).catch(error => {
                    const polishError = FirebaseErrorTranslateCode(error.code)
                    setError(polishError)
                })
            }
            catch (e){
                console.log(e);
                setError('Nie udało się zmienić hasła')
            }

        }

        setLoading(false)

    }

    return (
        <div className='Tab__Container'>
            <b>Zmiana hasła</b>
            <Formik
            initialValues={{
                currentPassword: '',
                password:'',
                passwordConfirm:''
            }}
            validationSchema={validate}
            onSubmit={values =>{
                setNewPassword(values)
            }}
            >
                {formik => (
                    <>
                    { error && <Alert variant="danger" className="alert-danger-custom">{error}</Alert>}
                    <Form>
                            <TextField label="Aktualne hasło" name="currentPassword" type="password" placeholder="Podaj aktualne hasło"/>
                            <TextField label="Hasło" name="password" type="password" placeholder="Nowe hasło"/>
                            <TextField label="Powtórz hasło" name="passwordConfirm" type="password" placeholder="Powtórz nowe hasło"/>
                            <CustomButton content={"Zapisz"} loading={loading} type={'submit'}/>
                    </Form>
                    </>
                )}
            </Formik>     
        </div>
    )
}

export default ChangePassword
