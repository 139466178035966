import { auth } from "../Firebase";
import logout from "./Logout";

const checkIfCurrentUserExists = async () => {
    try{
        await auth.currentUser.getIdToken(true);
    }catch(ex){
        await logout();
    }
}

export default checkIfCurrentUserExists;