import React from 'react'
import './Spinner.css'

const SmallSpinner = (props) => {
    return (
        <div 
            className={props.isText ? 'SmallSpinner__Container SmallSpinner__Container--topMargin' : 'SmallSpinner__Container SmallSpinner__Container__WithoutText'} 
            style=
            { props.isCenter ? 
            { 
                height: '100vh' , 
                position: 'fixed' , 
                top: 0 , 
                left: 0 ,
                zIndex: 1000,
                width: '100vw'        
            } 
            : {}
            }
            >
            <div className={props.isText ? "SmallSpinner SmallSpinner__Container--topMargin" : "SmallSpinner SmallSpinner__WithoutText"}></div>
            { 
                props.isText &&
                <p>
                    {props.customText && props.customText} 
                    {!props.customText && "Ładowanie"}
                </p>           
            }
        </div>
        
    )
}

export default SmallSpinner

