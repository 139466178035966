import { detect } from "detect-browser";

export default function PrepareLinkForUsersBrowser(){

    const browser = detect();

    switch(browser && browser.name) {
    case 'chrome':
        return 'https://support.google.com/accounts/answer/61416?hl=pl&co=GENIE.Platform%3DDesktop';
    case 'firefox':
        return 'https://support.mozilla.org/pl/kb/ciasteczka';
    case 'safari':
        return 'https://support.apple.com/pl-pl/guide/safari/ibrw850f6c51/mac';
    case 'opera':
        return 'https://help.opera.com/pl/latest/web-preferences/';
    case 'edge':
        return 'https://support.microsoft.com/pl-pl/office/w%C5%82%C4%85czanie-plik%C3%B3w-cookie-6b018d22-1d24-43d9-8543-3d35ddb2cb52';
    default:
        return 'https://www.google.pl/search?q=jak+w%C5%82%C4%85czy%C4%87+cookies+w+przegl%C4%85darce&ei=YoVEYvXaB6fIrgSqvojYBA&ved=0ahUKEwj1k5LioO72AhUnpIsKHSofAksQ4dUDCBg&uact=5&oq=jak+w%C5%82%C4%85czy%C4%87+cookies+w+przegl%C4%85darce&gs_lcp=Cgdnd3Mtd2l6EAMyBggAEBYQHjIGCAAQFhAeMgYIABAWEB4yBggAEBYQHjIGCAAQFhAeMgYIABAWEB46BwgAEEcQsAM6BggAEAcQHkoECEEYAEoECEYYAFDVAljVAmC1C2gCcAF4AIABfogB6wGSAQMwLjKYAQCgAQHIAQjAAQE&sclient=gws-wiz';
    }

}