import React , { useState , useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser'; 
import './Clue.css';
import Image from 'react-bootstrap/Image';
import SmallSpinner from '../../others/spinner/SmallSpinner';

const Clue = (props) => {

    const text = props.content.split("\\n").join("<br />");
    const link = 'https://prawojazdy.pro/tips/' + props.link + '.png'
    const[loading , setLoading] = useState(false);

    useEffect(() => {
        if(props.link)
            setLoading(true);

    }, [props.link] )

    return (
        <>
            <div className="DeparmentClue__container" style={loading ? {justifyContent: 'center' , paddingBottom: '20px'} : {} }>
                   <>
                        {
                            loading && 
                            <SmallSpinner 
                                isText= {true} 
                            />
                        }     
                        {
                            props.link &&               
                            <div 
                                className="DeparmentClue__img"
                                style={!loading ? {} : { display: 'none' }}
                            >
                                <Image
                                    style={!loading ? {} : { display: 'none' }}
                                    src={link} 
                                    fluid 
                                    onLoad={() => setTimeout(() => setLoading(false), 200)}
                                    alt="Grafika powiązana ze wskazówką"
                                />
                            </div>
                        }
                        {
                            !loading &&
                            <div className="DeparmentClue__text">
                                <p>{ReactHtmlParser (text)}</p>
                            </div>
                        }
                   </> 
            </div>
        </>
    )
}

export default Clue