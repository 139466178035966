import React from 'react';
import { RiLogoutBoxRLine } from 'react-icons/ri';
import { useAuth } from "../../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import logo from '../../../images/logo.svg';
import './Topnav.css';
import { useDispatch , useSelector } from 'react-redux';
import { logOut } from '../../../redux/actions/userActions';
import { showModal , closeModal } from '../../../redux/actions/notificationActions';
import { setIsExamStarted , clearExamData } from '../../../redux/actions/examActions';
import { createAlertDuringExamModalContent } from '../../../models/ModalContet';
import { useToastManager } from '../../../contexts/ToastManagerContext';
import { OverlayTrigger , Tooltip  } from 'react-bootstrap';
import { QueueAnswerService } from '../../../services/QueueAnswerService';

const Topnav = () => {

    const dispatch = useDispatch();
    const { logout } = useAuth();
    const { clearToast } = useToastManager();
    const history = useHistory();
    const isExamStarted = useSelector((state) => state.exam.isExamStarted);

    const AlertDuringExamDataWhenUserTryLogout = createAlertDuringExamModalContent(logoutHandling);
    const AlertDuringExamData = createAlertDuringExamModalContent(redirectToMainPage);

    async function handleLogout(){
        if(isExamStarted){
            dispatch(showModal(AlertDuringExamDataWhenUserTryLogout));
        }else{
            logoutHandling();
        }
    }

    async function logoutHandling(){
        try{
            new QueueAnswerService().sendAnswersFromQueuesBeforeLogout().then(async () => {
                await logout().then(() => {        
                    dispatch(logOut());
                    clearToast();   
                    if(isExamStarted){
                        dispatch(setIsExamStarted(false));
                        dispatch(clearExamData());
                    }
                });
            })
        }
        catch(e){
            console.log(e);
        }
    }

    function handleRedirectToMainPage(){
        if(isExamStarted){
            dispatch(showModal(AlertDuringExamData));
        }else{
            history.push("/konto");
        }
    }

    function redirectToMainPage(){
        history.push("/konto");
        dispatch(closeModal());
        dispatch(setIsExamStarted(false));
    }

    return (
        <>     
        <header className="TopnavSection">
            <div className="TopnavSection__container">
                <div className="TopnavSection__left">
                    <div className="TopnavSection__left-logo">  
                            <img 
                                onClick={handleRedirectToMainPage}
                                src={logo} 
                                alt="logo" />
                    </div>
                </div>
                <div className="TopnavSection__right">
                    <OverlayTrigger 
                        overlay={<Tooltip id="button-tooltip-2">Wyloguj się</Tooltip>}
                        placement="bottom"
                    >
                        <RiLogoutBoxRLine onClick={handleLogout}/>
                    </OverlayTrigger>                   
                </div>
            </div>
        </header>
        </>
    )
}

export default Topnav
