import React , { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setShowedSummaryExamPage } from '../../../redux/actions/examActions';
import Clue from '../../dashboard/clue/Clue';
import NumberTile from '../../dashboard/numberTile/NumberTile';
import Question from '../../dashboard/question/Question';
import Separator from '../../dashboard/separator/Separator';

const QuestionsSummary = () => {

    const dispatch = useDispatch();
    const examStore = useSelector((state) => state.exam);
    
    useEffect(()=>{

        dispatch(setShowedSummaryExamPage(true));

        return () => {
            dispatch(setShowedSummaryExamPage(false));
        }
    },[dispatch])

    return (
        <>
            <div className="NumberTile__container">
                {
                    examStore.examQuestions.map((item, i) =>
                        <NumberTile type={'exam'} key={i} index={i} currentIndex={examStore.examSummaryIndex} isCorrect={item.isCorrect} />
                    )
                }
            </div>
            <div className="Dashboard__flex">
                <Question type={'examSummary'} data={examStore.examQuestions[examStore.examSummaryIndex]} />
                <div className='DeparmentClue--flex'>  
                {
                    (examStore.examQuestions[examStore.examSummaryIndex].tip1 || examStore.examQuestions[examStore.examSummaryIndex].tip2 || examStore.examQuestions[examStore.examSummaryIndex].tip3 ) &&
                    <div className="DeparmentClue">
                        {examStore.examQuestions[examStore.examSummaryIndex].tip1 && <Clue link={examStore.examQuestions[examStore.examSummaryIndex].tip1Link} content={examStore.examQuestions[examStore.examSummaryIndex].tip1} />}
                        {examStore.examQuestions[examStore.examSummaryIndex].tip2 && <><Separator /><Clue link={examStore.examQuestions[examStore.examSummaryIndex].tip2Link} content={examStore.examQuestions[examStore.examSummaryIndex].tip2} /></>}
                        {examStore.examQuestions[examStore.examSummaryIndex].tip3 && <><Separator /><Clue link={examStore.examQuestions[examStore.examSummaryIndex].tip3Link} content={examStore.examQuestions[examStore.examSummaryIndex].tip3} /></>}
                    </div>
                } 
                </div>  
            </div>
        </>
    )

}

export default QuestionsSummary