import React from 'react'
import './FooterSection.css'

const FooterSection = () => {
    return (
        <div className="FooterSection">
            <div className="FooterSection__container">
                <p></p>
                <p>PRAWOJAZDY.PRO 2024</p>
                <p>Necodem Sp. z o.o.</p>
            </div>
        </div>
    )
}

export default FooterSection
