export const QuoteData = [
    {
        id: 0,
        comment:'Zdałem teorie bardzo fajna apka i było parę pytań z niej na egzaminie 💪 polecam bardzo serdecznie',
        author:'Opinia z google play'
    },
    {
        id: 1,
        comment:'Bardzo dobra i przydatna aplikacja :) baza pytań aktualna :)',
        author:'Opinia z google play'
    },
    {
        id: 2,
        comment:'Pytania się zgadzają z tymi na egzaminach. Wersja na telefon jest super. Dzięki wielkie! POLECAM!',
        author:'Opinia z google play'
    },
    {
        id: 3,
        comment:'Uczyłam się tylko z tej aplikacji do egzaminu i zdałam za pierwszym razem. Polecam Wszystkim',
        author:'Opinia z google play'
    },
    {
        id: 4,
        comment:'Szybko przechodzi do kolejnego pytania. Fajnie że pokazuje błędne odpowiedzi na koniec każdego etapu',
        author:'Opinia z google play'
    },
    {
        id: 5,
        comment:'Super aplikacja! Zdałam za 1 razem teorie, dziękuję!!!',
        author:'Opinia z google play'
    },
    {
        id: 6,
        comment:'Super aplikacja! Dzięki niemu mozna się przygotować do egzaminu polecam',
        author:'Opinia z google play'
    }
]