import React from 'react'
import CustomButton from '../../others/button/CustomButton'
import { useDispatch  } from 'react-redux';
import { showModal , closeModal } from '../../../redux/actions/notificationActions';
import { createAlertWhenUserWantEndExamModalContent } from '../../../models/ModalContet';

const EndExamButton = (props) => {

    const dispatch = useDispatch();

    const modalConent = createAlertWhenUserWantEndExamModalContent(handleFinishExam);
 
      function showModalDuringExam(){
          dispatch(showModal(modalConent));
      }
    
      function handleFinishExam(){
          props.finishExam();
          dispatch(closeModal());
      }

  return (
    <CustomButton 
        content={'Zakończ egzamin'} 
        widthInPercent={100}
        onClick={() => showModalDuringExam()}
        color={"exam"}
    />
  )
}

export default EndExamButton