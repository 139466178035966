import React , { useEffect } from 'react';
import NavigationBar from '../../components/website/navigationBar/NavigationBar';
import FooterSection from '../../components/website/footerSection/FooterSection';
import Terms from '../../components/website/termsSection/Terms';
import {TermsOfUseData} from "../../data/TermsOfUseData";
import CookieModal from '../../components/website/cookiesModal/CookieModal';
import { CookiesInformationData } from '../../data/CookiesInformationData';

const TermsOfUse = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div className="AlginItemsContainer">
            <NavigationBar />
            <Terms data={TermsOfUseData}/>
            <FooterSection />
            <CookieModal data={CookiesInformationData}/>
        </div>
    )
}

export default TermsOfUse
