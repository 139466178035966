import React from 'react';
import './NumberTile.css';
import { setSummaryIndex } from '../../../redux/actions/examActions';
import { useDispatch  } from 'react-redux';

const NumberTile = (props) => {
    
    const dispatch = useDispatch();

    function getStyle() {
        let style = "NumberTile"
        style += props.isCorrect ? " NumberTile--Correct" : " NumberTile--Incorrect"
        if(props.index === props.currentIndex){
            style += props.isCorrect ? "  Active--Correct" : " Active--Incorrect"
        }         
        return style
    }

    return (
        <>
        {         
            <div className={getStyle()} onClick={() => dispatch(setSummaryIndex(props.index))}>
                {props.index+1}
            </div>
        }
        </>
    )
}

export default NumberTile
