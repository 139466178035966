const TranslateNameFromPathForRouting = (pathName) => {
    switch(pathName) {
        case 'znaki-ostrzegawcze':
            return 'Znaki ostrzegawcze';
        case 'znaki-zakazu':
            return 'Znaki zakazu';
        case 'znaki-nakazu':
            return 'Znaki nakazu'
        case 'znaki-informacyjne':
            return 'Znaki informacyjne'
        case 'znaki-kierunku-i-miejscowosci':
            return 'Znaki kierunku i miejscowości'
        case 'znaki-uzupelniajace':
            return 'Znaki uzupełniające'
        case 'znaki-dodatkowe':
            return 'Znaki dodatkowe'
        case 'znaki-poziome':
            return 'Znaki poziome'
        case 'tabliczki-do-znakow':
            return 'Tabliczki do znaków'
        case 'syngalizacja-swietlna':
            return 'Syngalizacja świetlna'
        case 'sygnaly-dawane-przez-kierujacego-ruchem':
            return 'Sygnały dawane przez kierującego ruchem'
        case 'podstawowe-definicje':
            return 'Podstawowe definicje'
        case 'przepisy-ogolne':
            return 'Przepisy ogólne'
        case 'ruch-pieszych':
            return 'Ruch pieszych'
        case 'zasady-ogolne':
            return 'Zasady ogólne'
        case 'wlaczanie-sie-do-ruchu':
            return 'Włączanie się do ruchu'
        case 'predkosc-i-hamowanie':
            return 'Prędkość i hamowanie'
        case 'zmiana-kierunku-jazdy-lub-pasa-ruchu':
            return 'Zmiana kierunku jazdy lub pasa ruchu'
        case 'wymijanie-omijanie-i-cofanie':
            return 'Wymijanie, omijanie i cofanie'
        case 'wyprzedzanie':
            return 'Wyprzedzanie'
        case 'przecinanie-sie-kierunkow-ruchu':
            return 'Przecinanie się kierunków ruchu'
        case 'ostrzeganie-oraz-jazda-w-warunkach-zmniejszonej-przejrzystosci-powietrza':
            return 'Ostrzeganie oraz jazda w warunkach zmniejszonej przejrzystości powietrza'
        case 'holowanie':
            return 'Holowanie'
        case 'ruch-pojazdow-w-kolumnie':
            return 'Ruch pojazdów w kolumnie'
        case 'dodatkowe-dotyczace-kierujacych-rowerami':
            return 'Dodatkowe dotyczące kierujących rowerami'
        case 'dodatkowe-dotyczace-kierujacych-pojazdami-zaprzegowymi':
            return 'Dodatkowe dotyczące kierujących pojazdami zaprzęgowymi'
        case 'przepisy-porzadkowe':
            return 'Przepisy porządkowe'
        case 'zatrzymanie-i-postoj-pojazdu':
            return 'Zatrzymanie i postój pojazdu'
        case 'uzywanie-swiatel-zewnetrznych-pojazdu':
            return 'Używanie świateł zewnętrznych pojazdu'
        case 'warunki-uzywania-pojazdow-w-ruchu-drogowym':
            return 'Warunki używania pojazdów w ruchu drogowym'
        case 'ostrzegawcze-znaki':
            return 'Znaki ostrzegawcze'
        case 'znaki-zakazu-nakazu':
            return 'Znaki zakazu, nakazu'
        case 'znaki-informacyjne-kierunku-i-miejscowosci-uzupełniajace':
            return 'Znaki informacyjne, kierunku i miejscowości, uzupełniające'
        case 'znaki-drogowe-poziome':
            return 'Znaki drogowe poziome'
        case 'sygnaly-swietlne-sygnaly-dawane-przez-kierujacego-ruchem':
            return 'Sygnały świetlne, sygnały dawane przez kierującego ruchem'
        case 'wlaczanie-sie-do-ruchu-skrzyzowania-rownorzedne':
            return 'Włączanie się do ruchu, skrzyżowania równorzędne'
        case 'skrzyzowania-ze-znakami-okreslajacymi-pierwszenstwo-przejazdu':
            return 'Skrzyżowania ze znakami określającymi pierwszeństwo przejazdu'
        case 'skrzyzowania-z-sygnalizacja-swietlna':
            return 'Skrzyżowania z sygnalizacją świetlną'
        case 'skrzyzowania-lub-przejscia-dla-pieszych-z-kierujacych-ruchem':
            return 'Skrzyżowania lub przejścia dla pieszych z kierujących ruchem, miejsca przystanków komunikacji publicznej'
        case 'pozycja-pojazdu-na-drodze-wjazd-i-zjazd-ze-skrzyzowania-zatrzymanie-i-postoj':
            return 'Pozycja pojazdu na drodze, wjazd i zjazd ze skrzyżowania, zatrzymanie i postój'
        case 'zmiana-pasa-ruchu-zmiana-kierunku-jazdy':
            return 'Zmiana pasa ruchu, zmiana kierunku jazdy'
        case 'omijanie-wymijanie-cofanie':
            return 'Omijanie, wymijanie, cofanie'
        case 'uzywanie-swiatel-zewnetrznych-i-sygnalow-pojazdu':
            return 'Używanie świateł zewnętrznych i sygnałów pojazdu'
        case 'znaczenie-zachowania-szczegolnej-ostroznosci':
            return 'Znaczenie zachowania szczególnej ostrożności'
        case 'zachowanie-wobec-pieszego':
            return 'Zachowanie wobec pieszego, wobec osoby o ograniczonej możliwości poruszania się'
        case 'zachowanie-wobec-rowerzysty-i-dzieci':
            return 'Zachowanie wobec rowerzysty i dzieci'
        case 'zachowanie-na-przejazdach-kolejowych-i-tramwajowych':
            return 'Zachowanie na przejazdach kolejowych i tramwajowych'
        case 'ogolne-zasady-okreslajace-zachowanie-kierowcy':
            return 'Ogólne zasady zachowania w momencie wypadku lub awarii, udzielanie pierwszej pomocy przedmedycznej'
        case 'spostrzeganie-ocena-sytuacji-i-podejmowanie-decyzji':
            return 'Spostrzeganie, ocena sytuacji i podejmowanie decyzji'
        case 'dopuszczalne-predkosci-pojazdu-ograniczenia':
            return 'Dopuszczalne prędkości pojazdu, ograniczenia'
        case 'wyposazenie-pojazdu-zwiazane-z-bezpieczenstwem':
            return 'Wyposażenie pojazdu związane z bezpieczeństwem'
        case 'zasady-odnoszace-sie-do-rodzaju-transportu':
            return 'Zasady odnoszące się do rodzaju transportu'
        case 'odstepy-i-hamowanie-pojazdu':
            return 'Odstępy i hamowanie pojazdu'
        case 'czynniki-ryzyka-zwiazane-z-roznymi-warunkami-drogowymi':
            return 'Czynniki ryzyka związane z różnymi warunkami drogowymi'
        case 'technika-kierowania-pojazdem':
            return 'Technika kierowania pojazdem'
        case 'czynniki-bezpieczenstwa':
            return 'Czynniki bezpieczeństwa odnoszące się do pojazdu, ładunku i przewożonych osób'
        case 'obowiazki-wlasciciela-pojazdu':
            return 'Obowiązki właściciela pojazdu'
        case 'aspekty-mechaniczne-zwiazane-z-zachowaniem-bezpieczenstwa-na-drodze':
            return 'Aspekty mechaniczne związane z zachowaniem bezpieczeństwa na drodze'
        case 'akcje-ratunkowe':
            return 'Akcje ratunkowe'
        case 'rozne-pola-widzenia-kierowcow':
            return 'Różne pola widzenia kierowców'
        default:
            return null;
    }
}

export default TranslateNameFromPathForRouting
