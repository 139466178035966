import React from 'react';
import Error from '../../components/others/error/Error';

const NotFound = () => {
  return (
    <Error
        errorCode={404}
        title={"Nie znaleziono strony!"} 
        content={"Przepraszamy, strona o podanym adresie nie istnieje."}
    />
  )
}

export default NotFound