import { ActionWrongTypes } from '../contants/action-types';
import store from '../store';

export const getActiveQuestion = () => async dispatch => {

    let category = store.getState().user.user.options.category;
    let activeQuestion = null;
    let questions = store.getState().questions.questions;
    let questionsAnswers = store.getState().questions.questionsAnswers;
    let exList = store.getState().wrongs.exList;

    activeQuestion = questions.find(q => q.category.includes(category) && questionsAnswers[q.id.toString()] === 1 && !exList.includes(q.id));

    console.log(activeQuestion);

    if (activeQuestion === undefined && exList.length > 0) {
        activeQuestion = questions.find(q => q.id === exList[0]);
    }

    if(activeQuestion === undefined){
        activeQuestion = null;
    }

    dispatch({
        type: ActionWrongTypes.GET_ACTIVE_QUESTION,
        payload: activeQuestion
    })
}

export const deleteQuestionFromWrong = (id) => async dispatch => {

    dispatch({
        type: ActionWrongTypes.DELETE_QUESTION_FROM_WRONGS,
        payload: id
    })
}

export const moveQuestionToEndOfList = (id) => async dispatch => {

    dispatch( {
        type: ActionWrongTypes.MOVE_QUESTION_TO_END_OF_LIST,
        payload: id
    })

}

export const getAmountOfWrongs = () => {
    try{
        let category = store.getState().user.user.options.category;
        let questionsAnswers = store.getState().questions.questionsAnswers;
        
        let questions = store.getState().questions.questions;
        let amountOfWrongs = questions.filter(q => q.category.includes(category) && questionsAnswers[q.id.toString()] === 1).length;
        
        return{
            type: ActionWrongTypes.GET_AMOUNT_OF_WRONGS,
            payload: amountOfWrongs
        }
    }catch(ex){
        console.log(ex);
    }
}

export const resetActiveQuestion = () => {
    return {
        type: ActionWrongTypes.RESET_ACTIVE_QUESTION,
        payload: null
    }
}