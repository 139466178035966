import React , { useState , useEffect } from 'react';
import { QuestionTimerContent } from '../../../models/QuestionTimerContent';
import { setNextActiveQuestion , setIsStartMovie , setIsMovieEnded , sendAnswerToExam } from '../../../redux/actions/examActions';
import './QuestionTimer.css';
import { useDispatch , useSelector } from 'react-redux';
import CustomButton from '../../others/button/CustomButton';

const QuestionTimer = (props) => {

    let data;
    
    if(props.type === 'p'){
        data = [
            new QuestionTimerContent(0 , "Czas na zapoznanie się z pytaniem" , 20),
            new QuestionTimerContent(1 , "Czas na udzielenie odpowiedzi" , 15)
        ]
    }else{
        data = [new QuestionTimerContent(0 , "Czas na udzielenie odpowiedzi" , 50)]
    }

    const dispatch = useDispatch();
    const isMovieEnded = useSelector((state) => state.exam.isMovieEnded);
    const selectedAnswer = useSelector((state) => state.exam.selectedAnswer);

    const classWithTransition = "Progress Progress--transition";
    const classWithoutTransition = "Progress Progress--withoutTransition";

    const [ time , setTime ] = useState(data[0].time);
    const [ idQuestionTimerContent , setIdQuestionTimerContent] = useState(data[0].id);
    const [ progress , setProgress ] = useState(0);
    const [ text , setText ] = useState(data[0].text);
    const [ tenPercent , setTenPercent ] = useState((100/data[0].time));
    const [ buttonIsVisibility , setButtonIsVisibility ] = useState(false);
    const [ progressClass , setProgressClass ] = useState(classWithTransition);
    
    function getNextQuestiontimerContent(id){
        let nextId;
        if(id === 0) nextId = 1;  
        if(id === 1) nextId = 0;
        setProgressClass(classWithoutTransition);
        setTime(data[nextId].time);
        setIdQuestionTimerContent(data[nextId].id)
        setProgress(0);
        setText(data[nextId].text);
        setTenPercent((100/data[nextId].time));
    }
       
    useEffect(()=>{

        let interval = setInterval(() => {
            setProgressClass(classWithTransition);
            if(idQuestionTimerContent === 1 && !isMovieEnded && props.questionMedia.includes("mp4")){
                clearInterval(interval);
            }else{
                if (time > 0) {
                    setTime(time - 1);
                    setProgress(progress + tenPercent);
                }
                if(time === 0 && idQuestionTimerContent === 0){
                    if(props.questionMedia.includes("mp4")){
                        dispatch(setIsStartMovie(true));
                        setButtonIsVisibility(false);
                    }
                    if(props.type === 'p'){
                        getNextQuestiontimerContent(idQuestionTimerContent)
                    }
                }
                if(time === 0 && (idQuestionTimerContent === 1 || props.type === 's' )){
                    dispatch(sendAnswerToExam(selectedAnswer));
                    dispatch(setNextActiveQuestion(false))
                    if(props.type === 'p'){
                        getNextQuestiontimerContent(idQuestionTimerContent);
                    }else{
                        resetQuestionTimer();
                    }
                }
            }       
        }, 1000)
        return ()=> {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [time , progress , isMovieEnded]);

    useEffect(()=>{
        if(props.questionMedia.includes("mp4")){
            dispatch(setIsStartMovie(false));
            dispatch(setIsMovieEnded(false));
            setButtonIsVisibility(true);
        }
        if(!props.questionMedia.includes("mp4")){
            setButtonIsVisibility(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.questionMedia])

    function handleStartButton(){
        dispatch(setIsStartMovie(true));
        setButtonIsVisibility(false);
        getNextQuestiontimerContent(idQuestionTimerContent);     
    }

    useEffect(()=>{
        resetQuestionTimer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.resetTimer])

    function resetQuestionTimer(){
        setProgressClass(classWithoutTransition);
        setTime(data[0].time);
        setIdQuestionTimerContent(data[0].id)
        setProgress(0);
        setText(data[0].text);
        setTenPercent((100/data[0].time));
    }

    return (
        <>
            <p className='QuestionTimer__text'>{text}</p>
            {

                props.questionMedia.includes("mp4") && buttonIsVisibility &&
                <div className='Center'>
                    <CustomButton 
                    content={'Start'} 
                    heightInPixels={29}
                    onClick={() => handleStartButton()}
                    color={"exam"}
                    />    
                    <div className='Timer' style={{color: progress > 50 ? '#EFF2F5' : '#707070'}}>
                        <div className='Timer__inside'>
                            <div className={progressClass} style={{width: `${progress}%`}}/>
                            <span className='NumberOfSeconds'>{time}s</span>
                        </div>
                    </div>
                </div>  
            }        
            {
                 props.questionMedia.includes("mp4") && !buttonIsVisibility &&
                <div className='Timer' style={{color: progress > 51 ? '#EFF2F5' : '#707070'}}>
                    <div className='Timer__inside'>
                        <div className={progressClass} style={{width: `${progress}%`}}/>
                        <span className='NumberOfSeconds'>{time}s</span>
                    </div>
                </div>
            }
            {
                !props.questionMedia.includes("mp4") &&
                <div className='Timer' style={{color: progress > 51 ? '#EFF2F5' : '#707070'}}>
                    <div className='Timer__inside'>
                        <div className={progressClass} style={{width: `${progress}%`}}/>
                        <span className='NumberOfSeconds'>{time}s</span>
                    </div>
                </div>
            }
        </>
    )
}

export default QuestionTimer