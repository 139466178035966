import React from 'react';
import SmallSpinner from '../spinner/SmallSpinner';
import './Dropdown.css';

const Dropdown = (props) => {

    return (
        <div className='Dropdown'>
            <button className='Dropdown__button'>
                {!props.loading && props.content}
                {props.loading && <SmallSpinner/>}                 
            </button>
            <div className={!props.loading ? 'Dropdown__content' : 'Dropdown__content__loading'} onClick={props.onClick}>
                {
                    props.items.filter(item => item.text !== props.selectedCategoryToShow).map((item, i) =>     
                        // eslint-disable-next-line
                        <a key={i} data-value={item.value}>{item.text}</a>
                    )
                }
            </div>
        </div> 
    )
}

export default Dropdown

