import { QueueAnswerService } from './QueueAnswerService';
import store from '../redux/store';
import { timestampResque } from '../redux/actions/answersActions';

export class ResqueQueueService {

    start(){

        window.addEventListener('visibilitychange', handlerVisibilitychange);

        window.addEventListener('beforeunload', handlerBeforeunload);

        window.addEventListener('unload', handlerUnload);

        //dopracowac mouseout
        window.addEventListener('mouseout', handlerMouseout);

        window.addEventListener('mouseleave', handlerMouseleave);

        window.addEventListener('keydown', handlerKeydown);
    }

    stop(){
        window.removeEventListener("visibilitychange", handlerVisibilitychange);

        window.removeEventListener('beforeunload', handlerBeforeunload);

        window.removeEventListener('unload', handlerUnload);

        window.removeEventListener('mouseout', handlerMouseout);

        window.removeEventListener('mouseleave', handlerMouseleave);

        window.removeEventListener('keydown', handlerKeydown);
    }

}

const handlerVisibilitychange = () => {
    if (document.visibilityState === 'hidden') {
        possiblyCloseFunctionTrigerred("visibility change hidden")
    }
}

const handlerBeforeunload = () => {
    possiblyCloseFunctionTrigerred("before unload");
}

const handlerUnload = () => {
    possiblyCloseFunctionTrigerred("unload")
}

const handlerMouseout = (mouseEvent) => {
    if(mouseEvent.clientY < 0){
        //console.log("mouseout")
        //console.log(mouseEvent.clientY)
        possiblyCloseFunctionTrigerred("mouse out")
    }
}

const handlerMouseleave = () => {
    possiblyCloseFunctionTrigerred("mouse leave");
}

const handlerKeydown = (keyPressed) => {
    if(keyPressed.key !== undefined){
        console.log("keydown: "+keyPressed.key)
        if (keyPressed.key === "F5") {
            possiblyCloseFunctionTrigerred()
        }
        else if (keyPressed.key.toUpperCase() === "CONTROL") {
            possiblyCloseFunctionTrigerred()
        }
        else if (keyPressed.key.toUpperCase() === "ALT") {
            possiblyCloseFunctionTrigerred()
        }
        else if (keyPressed.key.toUpperCase() === "META") {
            possiblyCloseFunctionTrigerred()
        }
    }
}

//private functions
function possiblyCloseFunctionTrigerred(triggeredBy){
    console.log("possiblyClose triggeredBy:"+triggeredBy)
    //dodac maxymalnie raz na 3 sekundy wywolywanie
    var timeNow = Date.now();
    var timeLast = new Date(store.getState().answers.timestampResque)
    var durrationBetween = timeNow - timeLast
    // console.log("timeNow:"+timeNow)
    // console.log("timeLast:"+timeLast)
    // console.log("durrationBetween:"+durrationBetween)
    if(durrationBetween > 3000){
        store.dispatch(timestampResque())
        resqueQueueAnswers()
    }
}


function resqueQueueAnswers(){
    console.log("resqueQueueAnswers()!")
    new QueueAnswerService().sendAnswersFromQueues()
}