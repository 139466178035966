import { auth } from '../Firebase';

export const ifChromeMobileBrowserSignInWithRedirect = async ( provider ) => {
    const browser = getBrowser();
    
    if (chromeMobile(browser))
        await auth.signInWithRedirect(provider);
}

const chromeMobile = ( browser ) => browser 
    && browser.name === 'chrome'
    && browser.os === 'Android OS'

const getBrowser = () => {
    const { detect } = require('detect-browser');
    return detect();
}

export const ifChromeMobileBrowserSignReauthenticateWithRedirect = async ( provider ) => {
    const browser = getBrowser();
    
    if (chromeMobile(browser))
        await auth.currentUser.reauthenticateWithRedirect(provider);
}


