import { ActionUserTypes } from '../contants/action-types';

const initialState = {
    user: null,
    selectedCategoryToShow: null,
    userLoading: false,
    isActivePremium: null,
    premiumActiveTo: null,
    currentTimestamp: null,
    lastTimeRefreshTokenDate: null
}

export const userReducer = (state = initialState, { type, payload }) => {

    switch (type) {

        case ActionUserTypes.GET_USER:
            return {
                ...state,
                user: payload.user,
                selectedCategoryToShow: payload.categoryToShow,
                isActivePremium: payload.isActivePremium,
                premiumActiveTo: payload.premiumActiveTo,
                currentTimestamp: payload.currentTimestamp
            }
        case ActionUserTypes.SET_LAST_TIME_REFRESH_TOKEN_DATE:
            return {
                ...state,
                lastTimeRefreshTokenDate: payload
            }
        case ActionUserTypes.SET_CATEGORY:
            console.log(payload.category);
            return {
                ...state,
                user: {
                    ...state.user,
                    options: {
                        ...state.user.options,
                        category: payload.returnedCategory
                    }
                },
                selectedCategoryToShow: payload.categoryToShow,
                userLoading: false
            }
        case ActionUserTypes.SET_AUTO_PLAY:
            return {
                ...state,
                user: {
                    ...state.user,
                    options: {
                        ...state.user.options,
                        autoPlay: payload
                    }
                }
            }
        case ActionUserTypes.SET_TIME_IN_EXAM:
            return {
                ...state,
                user: {
                    ...state.user,
                    options: {
                        ...state.user.options,
                        timeInExam: payload
                    }
                }
            }
        case ActionUserTypes.SET_LOADING:
            return {
                ...state,
                userLoading: true
            }
        case ActionUserTypes.LOG_OUT:
            localStorage.removeItem("access_token");
            localStorage.clear();
            return{
                ...state
            }
        case ActionUserTypes.INCREMENT_DEMO_USED_EXAMS_NUMBER:
            return{
                ...state,
                user: {
                    ...state.user,
                    options:{
                        ...state.user.options
                    },
                    demoUsedExams: payload
                }
            }
        case ActionUserTypes.REMOVE_PREMIUM:
            return{
                ...state,
                isActivePremium: false
            }
        default: return state
    }

};

