import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { auth } from '../Firebase';

export default function PrivateRouteWebsite({ component : Component, ...rest}) {

    useEffect(() => {

        window.addEventListener('storage', handleLoginInAnotherOpenTabs)
    
        return _ => { window.removeEventListener('storage', handleLoginInAnotherOpenTabs) }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleLoginInAnotherOpenTabs = async e => {
        if(e.key === 'access_token' && !e.oldValue && e.newValue) {         
            window.location.reload();    
        }
    }

    return (
        <Route
            {...rest}
            render={(props) => auth.currentUser !== null ? <Redirect to="/konto"/> : <Component {...props}/>}
        />
    )
}