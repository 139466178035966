import iconNauka from '../images/iconauka.svg'
import iconDziały from '../images/icodzialy.svg'
import iconEgzamin from '../images/icotest.svg'

export const InformationSectionBoxData = [
    {
        id: 0,
        img: iconNauka,
        alt: 'Ikona działu nauka',
        headerText: 'Efektywny sposób nauki',
        paragraphText: 'Prawojazdy.pro to najlepszy serwis e-learningowy przygotowany przy pomocy doświadczonych nauczycieli kodeksu drogowego, który pozwoli Ci przygotować się do egzaminu i zdać go za pierwszym razem.'
    },
    {
        id: 1,
        img: iconDziały,
        alt: 'Ikona działu działy',
        headerText: 'Wszystkie kategorie',
        paragraphText: 'Oficjalna, aktualna i kompletna baza pytań WORD wraz z wyjaśnieniami zawierająca wszystkie kategorie prawa jazdy: A, A1, AM, B, B1, C, C1, D, D1, T'
    },
    {
        id: 3,
        img: iconEgzamin,
        alt: 'Ikona egzaminu',
        headerText: 'Egzamin taki jak w WORD',
        paragraphText: 'Testy posiadają formę jednakową jak na egzaminie państwowym w Wojewódzkim Ośrodku Ruchu Drogowego. Możliwość wykonywania ich wielokrotnie umożliwi Ci poznać wszystkie pytania.'
    }
]