import React , { useEffect } from 'react'
import NavigationBar from '../../components/website/navigationBar/NavigationBar'
import FooterSection from '../../components/website/footerSection/FooterSection'
import CookieModal from '../../components/website/cookiesModal/CookieModal';
import { CookiesInformationData } from '../../data/CookiesInformationData';
import Terms from '../../components/website/termsSection/Terms';
import { AcknowledgmentsData } from '../../data/AcknowledgmentsData';

const Acknowledgments = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
            <NavigationBar /> 
            <Terms data={AcknowledgmentsData} isAcknowledgmentsPage={true}/>
            <FooterSection />
            <CookieModal data={CookiesInformationData}/>
        </>
    )
}

export default Acknowledgments
