import { Form, Formik } from 'formik';
import React , { useState } from 'react';
import { Alert } from 'react-bootstrap';
import CustomButton from '../../others/button/CustomButton';
import TextField from '../../userPanel/textField/TextField';
import * as Yup from 'yup';
import { useToastManager } from '../../../contexts/ToastManagerContext';
import { useAuth } from '../../../contexts/AuthContext';
import FirebaseErrorTranslateCode from '../../../helpers/FirebaseTranslateCode';
import firebase from 'firebase/compat/app';
import { useDispatch } from 'react-redux';
import { changeTypeOfModal } from '../../../redux/actions/notificationActions';

const ReauthWithEmailForm = (props) => {

    const dispatch = useDispatch();

    const [ loading, setLoading ] = useState(false);
    const [error , setError] = useState('');
    const { showToast } = useToastManager();
    const { reauthWithEmail } = useAuth();
    
    
    const validate = Yup.object({
        email: Yup.string()
            .email('Adres email jest nie poprawny')
            .required('Adres email jest wymagany'),
        password: Yup.string()
            .min(6 , 'Hasło musi mieć minimum 6 znaków')
            .required('Hasło jest wymagane')           
    })
    
    const reauth = async (values) => {
        setError('')
        setLoading(true)

        try{
            const credential = firebase.auth.EmailAuthProvider.credential(values.email , values.password);

            await reauthWithEmail(credential).then(() => {
                showToast('custom-success' , 'Udało się poprawnie ponownie zalogować');
                props.showReauthWithEmailForm(false);
                dispatch(changeTypeOfModal('Error'));
            }).catch(error => {
                const polishError = FirebaseErrorTranslateCode(error.code)
                setError(polishError)
            })
        }catch(e){
            console.log(e);
            setError("Wystąpił problem podczas ponownego logowania")
        }


        setLoading(false)
    }

    return (
        <Formik
        initialValues={{
          email:'',
          password: ''
        }}
          validationSchema={validate}
          onSubmit={values =>{
                reauth(values);
        }}
        >
        {formik => (
          <>
              { error && 
              <Alert 
                  variant="danger" 
                  className="alert-danger-custom UpdateEmailForm__TextField">{error}
              </Alert>}
              <div className="Modal__body">
                    W celu zapewnienia najwyższego bezpieczeństwa, operacja usunięcia konta wymaga ponownego zalogowania.
              </div>
              <Form className="UpdateEmailForm__TextField">
                  <TextField 
                    label="Email" 
                    name="email" 
                    type="email" 
                    placeholder="Adres email"
                  />
                  <TextField 
                    label="Hasło" 
                    name="password" 
                    type="password" 
                    placeholder="Hasło"
                  />
                  <div className="Modal__footer">
                      <CustomButton 
                          content={"Anuluj"} 
                          onClick={() => props.closeModal()}
                      />
                      <CustomButton 
                          content={"Zaloguj"} 
                          loading={loading} 
                          type={'submit'}
                          color="green"
                      />
                  </div>
              </Form>
          </>
        )}
        </Formik>
    )

}

export default ReauthWithEmailForm;
