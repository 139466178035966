import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
});

export const facebookProvider = new firebase.auth.FacebookAuthProvider().setCustomParameters({auth_type: 'reauthenticate', display: 'popup'});

export const googleProvider = new firebase.auth.GoogleAuthProvider().setCustomParameters({prompt: 'select_account', display: 'popup'});

export const auth = app.auth();

export default app;