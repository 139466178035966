import { auth } from "../Firebase";
import { logOut } from "../redux/actions/userActions";
import store from "../redux/store";

const logout = async () => {
    auth.signOut().then(() =>{
        store.dispatch(logOut());
    });
}

export default logout;