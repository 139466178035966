import React from 'react';
import { Form, Card, Container} from 'react-bootstrap';
import logo from "../../../images/logo.svg";
import { Link } from "react-router-dom";
import './Error.css';
import CustomButton from '../button/CustomButton';

const Error = (props) => {
  return (
    <Container className="d-flex align-items-center justify-content-center UserPanel__Container UserPanelSection">
        <div className="w-100" style={{ maxWidth: "400px"}}>
        <Card>
            <Card.Body>

                <div className="UserPanel__Logo Action-logo">
                    <Link to='/'>
                        <img src={logo} alt="logo" />
                    </Link>
                </div>

                <Form>
                    <Form.Group className="text-center">
                    <h1 className="ErrorCode">{props.errorCode}</h1>
                    <h4 className="Action-h4">{props.title}</h4>
                    <p>{props.content}</p>
                    <Link to='/'>
                        <CustomButton 
                            color="blue"
                            widthInPercent="100"
                            content={"Powrót do strony głównej"}
                        />
                    </Link>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
        </div>
    </Container>
  )
}

export default Error