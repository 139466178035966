export const InformationBeforExamData = [
    {
        id: 0,
        text: 'Egzamin składa  się z 32 pytań: 20 pytań z zakresu wiedzy podstawowej oraz 12 pytań z zakresu wiedzy specjalistycznej.'
    },
    {
        id: 1,
        text: 'W celu uzyskania pozytywnego wyniku testu należy uzyskać przynajmniej 68 punktów na 74 możliwych.'
    },
    {
        id: 2,
        text: 'Podczas trwania egzaminu nie możesz cofnąć się do wcześniejszych pytań.'
    },
    {
        id: 3,
        text: 'Całkowity czas trwania egzaminu to 25 minut.'
    },
    {
        id: 4,
        text: 'W każdym pytaniu jedynie jedna z wszystkich odpowiedzi jest odpowiedzią poprawną.'
    },
    {
        id: 5,
        text: 'Zaczęty egzamin nie może zostać wstrzymany.'
    },
    {
        id: 6,
        text: 'Po egzaminie masz wgląd do wszystkich odpowiedzi wraz ze wskazówkami co do poprawnych odpowiedzi.'
    }
]
