import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { GiScales } from 'react-icons/gi';

const TermsParagraph = (props) => {
    return (
        <div className="TermsParagraph__container">
            <h6>
                {ReactHtmlParser(props.data.header)}
            </h6>
            {
                props.isAcknowledgmentsPage &&
                <>
                    <GiScales className="License__icon"/>
                    {props.data.license}
                </>        
            }
            {props.data.desc.map((item)=> 
                <p key={item}>
                {ReactHtmlParser(item)}
                </p>
            )}
        </div>
    )
}

export default TermsParagraph
