import React , { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from '../components/dashboard/layout/Layout';
import { addQuestionsAnswers } from "../redux/actions/answersActions";
import { getQuestionsForExam } from '../redux/actions/examActions';
import CustomButton from '../components/others/button/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import './Test.css';
import { Questions, QuestionWithInformation, SectionCount, QuestionCount, AnswerCount } from "./TestModels";
//import { testCorrectnessQuestionsForDemoExams } from './TestCorrectnessQuestionsForDemoExams';

const GetQuestionsForExamTest = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const examQuestions = useSelector((state) => state.exam.examQuestions);
    const questionsAnswers = useSelector((state) => state.questions.questionsAnswers);
    
    const [ showData, setShowData ] = useState(false);
    const [ start, setStart ] = useState(null);
    const [ loading, setLoading ] = useState(true);

    const [ examSetNumber, setExamSetNumber ] = useState(0);
    const [ questionsArray, setQuestionsArray ] = useState([]);
    const [ numberOfSetWithoutAnswer, setNumberOfSetWithoutAnswer ] = useState(0);
    const [ numberOfQuestions, setNumberOfQuestions] = useState([]);

    const breadcrumb = [
        { text:'Test algorytmu do pobierania pytań egzaminacyjnych', link:'/test-algorytmu' }
    ]

    useEffect(() => {
        if(process.env.REACT_APP_ENV === "PRODUCTION"){
            history.push('/nie-znaleziono-strony');
        }else{
            setLoading(false);
        }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOnClick = async () => {
        await startTest();
    }

    const startTest = async () => {

        setStart(true);

        window.interval = setInterval(() => {
            dispatch(getQuestionsForExam());
        }, 3000)
        
    }

    useEffect(() => {

        if(start !== null ){       
            if(examQuestions.length > 0){
                getQuestionsForExamTest();
            }       
        }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[examQuestions])

    const getQuestionsForExamTest = () => {

        let amountOfQuestionsWithoutAnswer = 0;
        let array = [];
        let questions = new Questions();
        questions.totalPointsInExam = 0;
        questions.onlyQuestionsWithWrongAnswers = false;
        let sectionsCount = [];
        let questionsCount = [ ...numberOfQuestions];
        let answerCount = new AnswerCount(0,0);

        examQuestions.forEach((q) => {
            
            let question = new QuestionWithInformation();

            question.id = q.id;
            question.section = q.section;
            question.points = q.points;
            
            let userAnswer = questionsAnswers[q.id.toString()];

            question.userAnswer = userAnswer;

            if(userAnswer === 0){
                amountOfQuestionsWithoutAnswer++;
            }

            setRandomAnswer(q.id);

            questions.totalPointsInExam = questions.totalPointsInExam + q.points;

            array.push(question);

            setSectionCount(q.section , sectionsCount);

            setQuestionCount(q.id, questionsCount);
            
            setAnswerCount(userAnswer, answerCount);
        });
        
        questions.array = array;
        questions.examSetNumber = examSetNumber + 1;
        questions.sectionsCount = sectionsCount.filter(section => section.count > 1)
        questions.answersCount = answerCount;

        setQuestionsArray( prevQuestion => [ ...prevQuestion, questions]);

        if(amountOfQuestionsWithoutAnswer === 0){
            questions.isQuestionsWithoutAnswer = true;
            setNumberOfSetWithoutAnswer(numberOfSetWithoutAnswer + 1);
        }

        if(numberOfSetWithoutAnswer === 3){
            clearInterval(window.interval);
            alert("Nie ma już pytań, na które nie było oddanej żadnej odpowiedzi");
        }

        setExamSetNumber(examSetNumber + 1);

        setNumberOfQuestions(questionsCount);

        setShowData(true);      
    }

    function setSectionCount(section , sectionsCount){
        let foundIndex  = sectionsCount.findIndex(sc => sc.section === section);
        if(foundIndex !== -1){
            let count = sectionsCount[foundIndex].count;
            sectionsCount[foundIndex] = new SectionCount(section , count + 1);
        }else{
            sectionsCount.push(new SectionCount(section , 1));
        }
    }

    const setQuestionCount = (id , questionsCount) => {
        let foundIndex  = questionsCount.findIndex(qc => qc.id === id);
        if(foundIndex !== -1){
            let count = questionsCount[foundIndex].count;
            questionsCount[foundIndex] = new QuestionCount(id , count + 1);
        }else{
            questionsCount.push(new QuestionCount(id , 1));
        }
    }

    const setAnswerCount = (userAnswer, answerCount) =>{
        if(userAnswer === 1){
            answerCount.wrongAnswer = answerCount.wrongAnswer + 1;
        }
        if(userAnswer === 2){
            answerCount.goodAnswer = answerCount.goodAnswer + 1;
        }
    }
 
    const setRandomAnswer = (id) => {
        let answer = new Map();
        answer.set(id.toString() , Math.floor( 1 + Math.random() * 2));
        dispatch(addQuestionsAnswers(answer))
    }

    const showTable = event => {
        const id = event.currentTarget.id;
        const displayStyle = getStyle(id, 'display');
        if(displayStyle === 'none'){
            document.getElementById(id).childNodes[1].style.display = 'contents';
        }else{
            document.getElementById(id).childNodes[1].style.display= 'none';
        }
    }

    function getStyle(id, name){  
        const element = document.getElementById(id).childNodes[1];
        return element.currentStyle ? element.currentStyle[name] : window.getComputedStyle ? window.getComputedStyle(element, null).getPropertyValue(name) : null;
    }

    const checkCorrectnessOfSets = (isQuestionsWithoutAnswer, totalPointsInExam, questionsArray) => {
        if(isQuestionsWithoutAnswer || totalPointsInExam !== 74 || questionsArray.length !== 32){
            return '#dc3545';
        }else{
            return 'rgb(65, 182, 43)';
        }
    }

    const handleStopInteralOnClick = () => {
        clearInterval(window.interval);
    }

    if(loading){
        return(<></>)
    }else
    return (
    <Layout title={breadcrumb}>
        <div className="Container">

            {/* <CustomButton
                widthInPercent={100}
                content={"Testuj poprawność pytań dla egzaminów dla użytkownika anonimowego"}
                onClick={testCorrectnessQuestionsForDemoExams}
            /> */}

            <CustomButton 
                widthInPercent={50}
                content={"Wylosuj sety i ustaw losowe odpowiedzi"}
                onClick={handleOnClick}
            />
            <CustomButton 
                widthInPercent={50}
                content={"Zatrzymaj"}
                onClick={handleStopInteralOnClick}
            />
            <br/>
            {
                showData &&
                <>
                <h5>Ilość powtarzających się id dla całkowitej liczbie wylosowanych egzaminów: {examSetNumber}</h5>
                {numberOfQuestions.filter(question => question.count > 1).sort((a,b) => a.count - b.count).map((question, index) => <p key={index}>Id pytania: <b>{question.id}</b>  Ilość powtórzeń: <b>{question.count}</b></p>)}
                {
                    questionsArray.map((question, index) => 
                        <>
                            <h5 className="Questions-header" style={{color: checkCorrectnessOfSets( question.isQuestionsWithoutAnswer, question.totalPointsInExam, question.array)}}>Egzamin numer: {question.examSetNumber}, Całkowita liczba punktów w egzaminie: {question.totalPointsInExam}</h5>
                            <p>Liczba pytań na które użytownik odpowiedział przedtem błędnie: {question.answersCount.wrongAnswer}, Liczba pytań na które użytownik odpowiedział przedtem poprawnie: {question.answersCount.goodAnswer}</p>
                            {question.sectionsCount.sort((a,b) => a.count - b.count).map((section, index) => <p key={index}>Sekcja: <b>{section.section}</b> Ilość powtórzeń: <b>{section.count}</b></p>)}
                            <Table 
                            className="Table-questions"
                            id={index}
                            style={{color: checkCorrectnessOfSets( question.isQuestionsWithoutAnswer, question.totalPointsInExam, question.array)}}
                            onClick={showTable}
                                >
                                <thead >
                                    <tr>
                                        <th>
                                            Numer pytania
                                        </th>
                                        <th>
                                            Id pytania
                                        </th>
                                        <th>
                                            Liczba punktów
                                        </th>
                                        <th>
                                            Poprzednia odpowiedź użytkownika na to pytanie
                                        </th>
                                        <th>
                                            Sekcja
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    question.array.map((q, index) => 
                                    <tr>
                                        <td>
                                            {index}
                                        </td>
                                        <td>
                                            {q.id}
                                        </td>
                                        <td>
                                            {q.points}
                                        </td>
                                        <td>
                                            {q.userAnswer}
                                        </td>
                                        <td>
                                            {q.section}
                                        </td>
                                    </tr>
                                    ) 
                                }
                                </tbody>
                            </Table>
                        </>
                    )
                }
                </>
            }
        </div>
    </Layout>

    )
}

export default GetQuestionsForExamTest
