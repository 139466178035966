import React, { useContext  , useState , useEffect } from 'react';
import CustomToast from '../components/others/toast/CustomToast';
import { useSelector } from 'react-redux';

const ToastManagerContext = React.createContext()

export function useToastManager(){
    return useContext(ToastManagerContext)
}

export function ToastManagerProvider({ children }){

    const showMessage = useSelector((state) => state.notifications.showToast);
    const toastMessage = useSelector((state) => state.notifications.toastMessage);
    const modalType = useSelector((state) => state.notifications.type);

    const [ listToast , setListToast ] = useState([]);
    let toastProperties = null;

    useEffect(() => {
        
        if(showMessage && modalType === 'error'){
            showToast('custom-error', toastMessage);
        }
        if(showMessage && modalType === 'success'){
            showToast('custom-success', toastMessage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showMessage])

    const showToast = (type , message) => {

        const id = Math.floor((Math.random() * 100000) + 1);

        switch(type) {
            case 'custom-success':
                toastProperties = {
                    id,
                    description: message,
                    color: 'success'
                }
                break;
            case 'custom-error':
                 toastProperties = {
                    id,
                    description: message,
                    color: 'error'
                }
                break;
            case 'success':
                toastProperties = {
                    id,
                    description: 'Poprawna odpowiedź',
                    color: 'success'
                }
                break;
            case 'error':
                toastProperties = {
                    id,
                    description: 'Błędna odpowiedź',
                    color: 'error'
                }
                break;
            default:
                setListToast([]);
        }
        setListToast([...listToast, toastProperties]);

    }

    const clearToast = () => {
        setListToast([]);
    }

    const removeFirstToast = () => {
        const newListToast = [...listToast];
        newListToast.shift();
        setListToast(newListToast);
    }

    const value = {
        showToast,
        clearToast
    }

    return(
        <>
        <ToastManagerContext.Provider value={value}>
            {children}
        </ToastManagerContext.Provider>
        <CustomToast 
            toastList={listToast}
            autoDelete={true}
            dismissTime={2500}
            removeFirstToast={removeFirstToast}
        />
        </>
    )
}