import React , { useState , useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import logo from '../../../images/logo.svg'
import './NavigationBar.css'
import { FaBars, FaTimes } from 'react-icons/fa';
import { NavLink , Link } from 'react-router-dom';

const NavigationBar = () => {

    const [ click , setClick ] = useState(false)

    const disableScroll = () => { document.body.style.overflow = 'hidden' }
    const enabledScroll = () => { document.body.style.overflow = 'auto' }

    const closeMenu = () => {
        setClick(false)
        enabledScroll()
    }

    const handleClick = () => {
        setClick(!click)
        if(click === false){
            disableScroll()
        }
        else{
            enabledScroll()
        }
    }
    
    useEffect(() => {

        function handleResize(){

            if(window.innerWidth >= 1000){
                setClick(false)
                enabledScroll()
            }

        }

        window.addEventListener('resize' , handleResize)
    })

    return (
        <div className="NavigationSection">
            <Container fluid="xl">
                <nav className="navbar">
                    <div className="navbar__container">
                        <ul className="navbar__left">
                            <div className="navbar__left-logo" onClick={closeMenu}>  
                                <Link to='/'>
                                    <img src={logo} alt="logo" />
                                </Link>
                            </div>
                        </ul>
                        <ul className="menu-icon__container">
                            <div className="menu-icon" onClick={handleClick}>
                                {click ? <FaTimes /> : <FaBars />}
                            </div>
                        </ul>
                        <ul className={click ? 'navbar__right active' : 'navbar__right '}>
                            <li onClick={closeMenu}>
                                <NavLink to='/' exact={true}> 
                                    Strona główna
                                </NavLink>
                            </li>
                            <li onClick={closeMenu}>
                                <NavLink to='/polityka-prywatnosci' > 
                                    Polityka prywatności
                                </NavLink>
                            </li> 
                            <li onClick={closeMenu}>
                                <NavLink to='/warunki-korzystania' > 
                                    Warunki korzystania
                                </NavLink>
                            </li> 
                            <li onClick={closeMenu}>
                                <NavLink to='/logowanie' > 
                                    Zaloguj się
                                </NavLink>
                            </li> 
                        </ul>
                    </div>
                </nav>
            </Container>
        </div>
    )
}

export default NavigationBar
