import React , { useEffect } from 'react';
import CustomButton from '../button/CustomButton';
import './Modal.css';
import { IoWarningOutline , IoCloseCircleOutline , IoCheckmarkCircleOutline } from 'react-icons/io5';
import { RiLockUnlockLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useOnlineStatus } from '../../../contexts/OnlineStatusContext';
import UpdateEmailForm from '../../../components/paymentSummary/updateEmailForm/UpdateEmailForm';
import { DeleteAccountForm } from '../../settings/deleteAccount/DeleteAccountForm';
import { closeModal } from '../../../redux/actions/notificationActions';
import { useLocation } from 'react-router-dom';

const Modal = () => {

    let modalContent = useSelector((state) => state.notifications.modalContent);
    const showModal = useSelector((state) => state.notifications.showModal);
    const loading = useSelector((state) => state.notifications.loading);
    const singleButtonStyle = !modalContent.answer1 ? {justifyContent: 'flex-end'} : {};
    const isExamStarted = useSelector((state) => state.exam.isExamStarted);

    const isOnline = useOnlineStatus();
    const dispatch = useDispatch();
    const location = useLocation();

    function getColorFromType(type){
        switch(type){
            case 'Error':
                return "red";
            case 'Success':
                return "green";
            case 'Warning':
                return "yellow";
            case 'UnlockPremium':
                return "green"
            default:
                return "";
        } 
    }

    useEffect(() => {
        if(showModal){
            window.addEventListener("popstate", handleCloseModal);
        }
        
        return () => {
            window.removeEventListener("popstate", handleCloseModal);
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[showModal])

    const handleCloseModal = () => {
        const pathname = location.pathname;
        if(pathname !== '/egzamin' || (pathname === '/egzamin' && !isExamStarted)){
            dispatch(closeModal());
        }
    }

    return (
     <>
        {
        (showModal && isOnline) &&
        <div className="Modal__background">
            
            <div className="Modal__container">
    
                <div className="Modal__icon">
                    {
                        modalContent.type === "Error" && 
                        <>
                            <IoCloseCircleOutline 
                                className={"icon--error"}
                            />
                        </>
                    }
                    {
                        modalContent.type === "Success" &&
                        <>
                            <IoCheckmarkCircleOutline
                                className={"icon--success"}
                            />
                        </>
                    }
                    {
                        modalContent.type === "Warning" &&
                        <>  
                            <IoWarningOutline 
                                className={"icon--warning"}
                            />
                        </>
                    }
                    {
                        modalContent.type === "UnlockPremium" &&
                        <>  
                            <RiLockUnlockLine 
                                className={"icon--success"}
                            />
                        </>
                    }
                </div>
    
    
                <div className="Modal__content">
    
                    {
                        modalContent.title &&
                        <div className="Modal__header">
                            <h5>{modalContent.title}</h5>
                        </div>
                    }

                    <div className="Modal__body">
                        {
                            modalContent.body
                        }
                        {
                            modalContent.isUpdateEmailFormAvailable &&
                            <UpdateEmailForm 
                                CloseModal={() => modalContent.function1()}
                            />
                        }
                        {
                            modalContent.isDeleteAccountFormAvailable &&
                            <DeleteAccountForm
                                CloseModal={() => modalContent.function1()}
                            />
                        }
                    </div>
    
                    <div className="Modal__footer" style={singleButtonStyle}>
                        {modalContent.answer1 && <CustomButton onClick={() => modalContent.function1(modalContent.id)} content={modalContent.answer1} />}
                        {modalContent.answer2 && <CustomButton onClick={() => modalContent.function2(modalContent.id)} content={modalContent.answer2} loading={loading} color={getColorFromType(modalContent.type)}/>}
                    </div>

                </div>
    
            </div>
        </div>
        }
     </>
  )
}

export default Modal