import store from "../redux/store";
import { closeModal } from '../redux/actions/notificationActions';
import { setIsExamStarted } from '../redux/actions/examActions';
import history from "../helpers/history";
import PrepareLinkForUsersBrowser from "../helpers/PrepareLinkForUsersBrowser";

export class ModalContent  {

    constructor
    (
        type, 
        title, 
        body, 
        answer1, 
        function1, 
        answer2, 
        function2, 
        isUpdateEmailFormAvailable, 
        isDeleteAccountFormAvailable
    )
    {
        this.type = type
        this.title = title
        this.body = body
        this.answer1 = answer1
        this.function1 = function1
        this.answer2 = answer2
        this.function2 = function2
        this.isUpdateEmailFormAvailable = isUpdateEmailFormAvailable
        this.isDeleteAccountFormAvailable = isDeleteAccountFormAvailable
    }

}

export function createAlertDuringExamModalContent(fun){
    return new ModalContent(
        "Warning",
        "Czy chcesz przerwać egzamin?",
        "Wybranie tej akcji spowoduje utracenie postępów w trwającym egzaminie.",
        "Nie",
        () => store.dispatch(closeModal()),
        "Tak",
        () => fun()
    );
}

export function createAlertWhenUserWantEndExamModalContent(fun){
    return new ModalContent(
        "Warning",
        "Czy chcesz zakończyć egzamin?",
        "Wybranie tej akcji spowoduje zakończenie tego egzaminu bez możliwości powrotu do niego.",
        "Nie",
        () => store.dispatch(closeModal()),
        "Tak",
        () => fun()
    );
}

export function createAlertUnlockPremium(){
    return new ModalContent(
        "UnlockPremium",
        "Odblokuj konto premium",
        "Kup premium i odblokuj już teraz dostęp do całej aplikacji.",
        "Później",
        () => store.dispatch(closeModal()),
        "Tak",
        () => moveToPremiumPlanPage()
    );
}

function moveToPremiumPlanPage(){
    history.push("/plan-premium");
    store.dispatch(closeModal());
}

export function createAlertForPaymentBeingVerified(){
    return new ModalContent(
        "Warning",
        "Twoja płatność jest weryfikowana",
        "Premium zostanie dodane po poprawnym przetworzeniu",
        "Zamknij",
        () => store.dispatch(closeModal()),
        "Ok",
        () => store.dispatch(closeModal())
    );
}

export function createAlertForNotVerifiedPayment(){
    return new ModalContent(
        "Error",
        "Weryfikacja płatności się nie powiodła",
        "Twoja płatność nie przeszła weryfikacji",
        "Zamknij",
        () => store.dispatch(closeModal()),
        "Ok",
        () => store.dispatch(closeModal())
    );
}

export function createAlertForVerifiedPayment(){
    return new ModalContent(
        "Success",
        "Weryfikacja płatności się powiodła",
        "Premium zostało dodane do Twojego konta, ciesz się pełnymi możliwościami aplikacji prawojazdy.pro",
        "Zamknij",
        () => store.dispatch(closeModal()),
        "Ok",
        () => store.dispatch(closeModal())
    );
}

export function createAlertEndPremium(){
    return new ModalContent(
        "UnlockPremium",
        "Twoje konto premium się zakończyło",
        "Przedłuż swoje premium już teraz jeśli potrzebujesz więcej czasu na naukę.",
        "Później",
        () => store.dispatch(closeModal()),
        "Tak",
        () => handleEndExam()
    );
}

const handleEndExam = () => {
    const isExamStarted = store.getState().exam.isExamStarted;
    if (isExamStarted){
        store.dispatch(setIsExamStarted(false));
    }
    moveToPremiumPlanPage();
}

export function createAlertForNotSupportedBrowserVersion (
    browserName, 
    browserVersion, 
    lowestRequiredBrowserVersion,
    linkToUpdate
){
    return new ModalContent(
        "Error",
        "Wykryto nieobsługiwaną wersję przeglądarki.",
        `Wykryto, że korzystasz z przeglądarki internetowej: ${browserName} w wersji: ${browserVersion}.\nNajniższa obsługiwana wersja tej przeglądarki to ${lowestRequiredBrowserVersion}.\nPobierz aktualizację lub nową przeglądarkę internetową. Lista obsługiwanych przeglądarek jest dostępna w warunkach korzystania, w punkcie 6.`,
        "Zamknij",
        () => store.dispatch(closeModal()),
        "Zaktualizuj",
        () => window.location.href = linkToUpdate
    )
}

export function createAlertForDisabledLocalStorage(){
    return new ModalContent(
        "Error",
        "Wykryto wyłączenie przetrzymywania danych!",
        "Przetrzymywanie danych w twojej przeglądarce jest wyłączone. W celu poprawnego działania aplikacji wymagane jest włączenie zapisywania plików cookies i innych danych witryny.",
        "Zamknij",
        () => store.dispatch(closeModal()),
        "Poradnik",
        () => window.location.href = PrepareLinkForUsersBrowser()
    );
}