export const PricingColumnData = [
    {
        id: '63d7dab5-1c99-440f-b841-84040d308fe1',
        isPopular: 'PricingColumn__popularHeader--blank',
        popularText: '',
        header: 'Wszystkie kategorie',
        price: 9.99,
        firstText: 'Dostęp na 7 dni',
        secondText: 'W pakiecie otrzymasz dostęp do całego serwisu, czyli między innymi:',
        bulletPointList: 
        [        
               "Wszystkie oficjalne pytania występujące na egzaminach państwowych",
               "Wskazówki szczegółowo wyjaśniające pytania",      
               "Wszystkie kategorie - A, A1, AM, B, B1, C, C1, D, D1, T",
               "Dostęp do wersji komputerowej oraz aplikacji mobilnej",
               "Dział nauka umożliwiający szybką i efektywną naukę teorii"          
        ],
        sku: '07_days_premium'
    },
    {
        id: '68cfe9d0-cb1c-45ba-bfaa-30947120322d',
        isPopular: 'PricingColumn__popularHeader',
        popularText: 'Najczęściej wybierany',
        header: 'Wszystkie kategorie',
        price: 19.99,
        firstText: 'Dostęp na 30 dni',
        secondText: 'W pakiecie otrzymasz dostęp do całego serwisu, czyli między innymi:',
        bulletPointList: 
        [        
            "Wszystkie oficjalne pytania występujące na egzaminach państwowych",
            "Wskazówki szczegółowo wyjaśniające pytania",     
            "Wszystkie kategorie - A, A1, AM, B, B1, C, C1, D, D1, T",
            "Dostęp do wersji komputerowej oraz aplikacji mobilnej",
            "Dział nauka umożliwiający szybką i efektywną naukę teorii"               
        ],
        sku: '30_days_premium'
    },
    {
        id: '362e9583-0894-43e3-8162-ffcbf6acad73',
        isPopular: 'PricingColumn__popularHeader--blank',
        popularText: '',
        header: 'Wszystkie kategorie',
        price: 29.99,
        firstText: 'Dostęp na 90 dni',
        secondText: 'W pakiecie otrzymasz dostęp do całego serwisu, czyli między innymi:',
        bulletPointList: 
        [        
            "Wszystkie oficjalne pytania występujące na egzaminach państwowych",
            "Wskazówki szczegółowo wyjaśniające pytania",     
            "Wszystkie kategorie - A, A1, AM, B, B1, C, C1, D, D1, T",
            "Dostęp do wersji komputerowej oraz aplikacji mobilnej",
            "Dział nauka umożliwiający szybką i efektywną naukę teorii"              
        ],
        sku: '90_days_premium'
    }
]