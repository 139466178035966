import store from '../redux/store';
import { setRefreshExamPage } from '../redux/actions/examActions';

function RefreshExamPageDuringExamSummary(){

    const showedSummaryExamPage  = store.getState().exam.showedSummaryExamPage;
    const isExamStarted  = store.getState().exam.isExamStarted;

    if(showedSummaryExamPage === true && isExamStarted === false){
        store.dispatch(setRefreshExamPage(true));
    }
    return true;
    
}

export default RefreshExamPageDuringExamSummary;