import React from 'react';
import { FaQuoteRight , FaQuoteLeft } from 'react-icons/fa';
import './Quote.css';

const Quote = (props) => {
    return (
            <div className="quote__container">
                    <div className="quote__icon--up">
                        <FaQuoteLeft />
                        <div></div>
                    </div>
                    <div className="quote__text">
                        <p>{props.data.comment}</p>
                    </div>
                    <div className="quote__icon--down">
                        <div></div>
                        <FaQuoteRight />
                    </div>
                    <div className="quote__author"> 
                        <p>{props.data.author}</p>
                    </div>
             </div>
    )
}

export default Quote
