import { ActionExamTypes } from '../contants/action-types';

const initialState = {
    examQuestions: [],
    activeQuestion: null,
    currentIndex: null,
    examResult: [],
    examSummaryIndex: 0,
    isStartMovie: false,
    isMovieEnded: false,
    selectedAnswer: 0,
    isExamStarted: false,
    baseQuestionNumber: 1,
    specialistQuestionNumber: 0,
    refreshExamPage: false,
    showedSummaryExamPage: false,
    showedExamSummaryModal: false
}

export const examReducer = ( state = initialState, { type , payload } ) => {

    switch (type) {
        case ActionExamTypes.GET_QUESTIONS_FOR_EXAM:
            return {
                ...state,
                examQuestions: payload,
                currentIndex: 0
            }
        case ActionExamTypes.CLEAR_EXAM_DATA:
            return {
                ...state,
                examQuestions: [],
                activeQuestion: null,
                currentIndex: null,
                examResult: [],
                examSummaryIndex: 0,
                baseQuestionNumber: 0,
                specialistQuestionNumber: 0,
                refreshExamPage: false
            }
        case ActionExamTypes.SET_NEXT_ACTIVE_QUESTION:      
            return {
                    ...state,
                    activeQuestion: payload.activeQuestion,
                    currentIndex: payload.currentIndex,
                    baseQuestionNumber: payload.baseQuestionNumber,
                    specialistQuestionNumber: payload.specialistQuestionNumber
            }
         case ActionExamTypes.SEND_ANSWER_TO_EXAM:
            return {
                    ...state,
                    examQuestions: payload.examQuestions,
                    selectedAnswer: payload.selectedAnswer
            }
        case ActionExamTypes.COMPUTE_EXAM_RESULT:
            return {
                    ...state,
                    examResult: payload,
                    currentIndex: null
            }
        case ActionExamTypes.SET_SUMMARY_INDEX:
            return {
                    ...state,
                    examSummaryIndex: payload
            }
        case ActionExamTypes.SET_IS_START_MOVIE:
            return {
                    ...state,
                    isStartMovie: payload
            }
        case ActionExamTypes.SET_IS_MOVIE_ENDED:
            return {
                    ...state,
                    isMovieEnded: payload
            }
        case ActionExamTypes.SET_SELECTED_ANSWER:
            return {
                ...state,
                selectedAnswer: payload
            }
        case ActionExamTypes.SET_IS_EXAM_STARTED:
            return {
                ...state,
                isExamStarted: payload
            }
        case ActionExamTypes.SET_REFRESH_EXAM_PAGE:
            return{
                ...state,
                refreshExamPage: payload
            }
        case ActionExamTypes.SET_SHOWED_SUMMARY_EXAM_PAGE:
            return{
                ...state,
                showedSummaryExamPage: payload
            }
        case ActionExamTypes.SET_SHOWED_EXAM_SUMMARY_MODEL:
            return{
                ...state,
                showedExamSummaryModal: payload
            }
        default: return state
    }

};