import React from 'react';
import './ExamInformationItem.css';

const ExamInformationItem = (props) => {
  return (
    <div className={ props.IsHidden ? 'ExamInformationItem__Container HiddenInformation' : 'ExamInformationItem__Container'}>
      <p>{props.text}</p>
      <div className='Cover'>{props.value}</div>
    </div>
  )
}

export default ExamInformationItem