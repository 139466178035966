import React , { useEffect , useState } from 'react';
import { useParams } from "react-router-dom";
import Layout from '../../components/dashboard/layout/Layout';
import TranslateNameFromPathForRouting from '../../helpers/TranslateNameFromPathForRouting';
import { TranslateNameFromPathToSectionForRegulations } from '../../helpers/TranslateNameFromPathToSection';
import LearningItem from '../../components/dashboard/learningItem/LearningItem';
import { useSelector } from 'react-redux';
import SmallSpinner from '../../components/others/spinner/SmallSpinner';
import ValidateUrl from '../../helpers/ValidateUrl';

const Regulations = () => {

    const { id } = useParams();
    const regulationsStore = useSelector((state) => state.regulations);
    const regulationProgress = new Map(Object.entries(regulationsStore.regulationsProgress)); 
    const user = useSelector((state) => state.user.user);
    const isActivePremium = useSelector((state) => state.user.isActivePremium);

    const [loading , setLoading] = useState(true);
    const [regulations, setRegulations] = useState(null);
    const [ nameFromPath ] = useState(TranslateNameFromPathToSectionForRegulations(id));

    const breadcrumb = [
        { text:'Nauka', link:'/nauka' },
        { text:'Przepisy', link:'/nauka/przepisy' },
        { text: TranslateNameFromPathForRouting(id) , link:'/nauka/przepisy/'+id}
    ]

    useEffect(() => {  

        if(user !== null){

            let isValidateUrl = true;
            isValidateUrl = ValidateUrl(id, 'regulations');

            if(!isValidateUrl || regulationsStore.regulations.length === 0 || Array.isArray(regulationsStore.regulationsAnswers)){
                setLoading(true);
            }else{
                setRegulations(regulationsStore.regulations);
                setLoading(false);
            }

        }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[regulationsStore.regulations, user, isActivePremium, regulationsStore.regulationsAnswers])

    useEffect(() => {
        window.scrollTo(0 , 0);
    },[])

    return (
        <> 
            { loading && 
                 <SmallSpinner 
                 isText={true}
                 isCenter={true}
              />
            }  
            { 
            !loading &&
            regulations !== null &&
            <Layout title={breadcrumb} isProgressCircleActive={true} progress={regulationProgress.get(nameFromPath.join('.'))} color={'#41B62B'} >
                <div style={{marginBottom: '80px'}}>
                {
                    regulations.filter((item) => item.section === parseInt(nameFromPath[0]) && item.subSection === parseInt(nameFromPath[1]))
                    .sort((a,b) => (a.numInSection > b.numInSection ? 1: -1))
                    .map((item, i) =>
                            <LearningItem key={i} data={item} answer={regulationsStore.regulationsAnswers[item.id.toString()]} type={'regulation'}/>  
                    )
                }
                </div>
            </Layout>
            }
        </>
    )
}

export default Regulations
