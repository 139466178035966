import { createAlertForPaymentBeingVerified, createAlertForNotVerifiedPayment, createAlertForVerifiedPayment } from "../models/ModalContet";

export function waitForNextRequest(ms){
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function getAlertToModal(paymentStatus){
    switch(paymentStatus){
        case 'VERIFIED_PAYMENT':
            return createAlertForPaymentBeingVerified();
        case 'VERIFIED_FAIL':
            return createAlertForNotVerifiedPayment();
        case 'PREMIUM_ADDED':
            return createAlertForVerifiedPayment();
        default:
            return;
    }
}

export function translateStatus(status){
    switch(status) {
        case 'REGISTERED':
            return 'Płatność zarejestrowana, trwa przetwarzanie płatności';
        case 'NOTIFICATED':
            return 'Płatność weryfikowana, trwa przetwarzanie płatności';
        case 'VERIFIED_OK':
            return 'Płatność zweryfikowana';
        case 'VERIFIED_FAIL':
            return 'Weryfikacja płatności się nie powiodła';
        default:
            return "Przetwarzanie płatności";
    }
}