import React from "react";
import { Route , Redirect , Switch } from 'react-router-dom';
import Dashboard from "../page/dashboard/Dashboard";
import Learning from "../page/dashboard/Learning";
import Signs from "../page/dashboard/Signs";
import Regulations from "../page/dashboard/Regulations";
import Department from "../page/dashboard/Department";
import DepartmentQuestion from "../page/dashboard/DepartmentQuestion";
import Exam from "../page/dashboard/Exam";
import Wrongs from "../page/dashboard/Wrongs";
import Setting from "../page/dashboard/settings/Setting";
import PrivateRouteDashboard from "./PrivateRouteDashboard";
import Website from "../page/website/Website";
import PrivacyPolicy from "../page/website/PrivacyPolicy";
import TermsOfUse from "../page/website/TermsOfUse";
import Login from "../page/userPanel/Login";
import ForgotPassword from "../page/userPanel/ForgotPassword";
import Signup from "../page/userPanel/Signup";
import Action from "../helpers/Action"
import PrivateRouteWebsite from "./PrivateRouteWebsite";
import InternalServerError from "../page/other/InternalServerError";
import NotFound from "../page/other/NotFound";
import PricingDashboard from "../page/dashboard/PricingDashboard";
import Account from "../page/dashboard/Account";
import PaymentSummary from "../page/dashboard/PaymentSummary";
import { UserPremiumTimerProvider } from "../contexts/UserPremiumTimerContext";
import Acknowledgments from "../page/website/Acknowledgments";
import GetQuestionsForExamTest from "../tests/GetQuestionsForExamTest";

const Routes = () => {
  return (
    <Switch>
        <Route 
            path={[
            '/konto',
            '/nauka',
            '/nauka/znaki',
            '/nauka/znaki/:id',
            '/nauka/przepisy',
            '/nauka/przepisy/:id',
            '/dzialy',
            '/dzialy/czesc-podstawowa',
            '/dzialy/czesc-podstawowa/:id',
            '/dzialy/czesc-specjalistyczna',
            '/dzialy/czesc-specjalistyczna/:id',
            '/egzamin',
            '/bledy',
            '/opcje',
            '/plan-premium',
            '/podsumowanie-platnosci/:id',
            '/test-algorytmu'
            ]}>    
            <Dashboard>
            <UserPremiumTimerProvider>
                <Switch>
                    <PrivateRouteDashboard path='/konto' exact component={Account}/>
                    <PrivateRouteDashboard path='/nauka' exact component={Learning} />
                    <PrivateRouteDashboard path='/nauka/znaki' exact component={Learning} />
                    <PrivateRouteDashboard path='/nauka/znaki/:id' exact component={Signs} />
                    <PrivateRouteDashboard path='/nauka/przepisy' exact component={Learning} />
                    <PrivateRouteDashboard path='/nauka/przepisy/:id' exact component={Regulations} />
                    <PrivateRouteDashboard path='/dzialy' exact component={Department}/>
                    <PrivateRouteDashboard path='/dzialy/czesc-podstawowa' exact component={Department} />
                    <PrivateRouteDashboard path='/dzialy/czesc-podstawowa/:id' exact component={DepartmentQuestion} />
                    <PrivateRouteDashboard path='/dzialy/czesc-specjalistyczna' exact component={Department} />
                    <PrivateRouteDashboard path='/dzialy/czesc-specjalistyczna/:id' exact component={DepartmentQuestion} />
                    <PrivateRouteDashboard path='/egzamin' exact component={Exam}/>
                    <PrivateRouteDashboard path='/bledy' exact component={Wrongs}/>
                    <PrivateRouteDashboard path='/opcje' exact component={Setting}/> 
                    <PrivateRouteDashboard path='/plan-premium' exact component={PricingDashboard}/>
                    <PrivateRouteDashboard path='/podsumowanie-platnosci/:id' exact component={PaymentSummary}/>
                    <PrivateRouteDashboard path='/test-algorytmu' exact component={GetQuestionsForExamTest}/>
                    <Redirect from='*' to='/nie-znaleziono-strony' />
                </Switch>
            </UserPremiumTimerProvider>
            </Dashboard>
        </Route>
        <Route 
            path={[
            '/',
            '/polityka-prywatnosci',
            '/warunki-korzystania',
            '/logowanie',
            '/przypomnienie-hasla',
            '/rejestracja',
            '/podziekowania',
            '/akcje',
            '/wewnetrzny-blad-serwera',
            '/nie-znaleziono-strony'
            ]}>
            <Switch>
                <PrivateRouteWebsite path='/' exact component={Website} />
                <PrivateRouteWebsite path='/polityka-prywatnosci' exact component={PrivacyPolicy} />
                <PrivateRouteWebsite path='/warunki-korzystania' exact component={TermsOfUse} />
                <PrivateRouteWebsite path='/logowanie' exact component={Login} />
                <PrivateRouteWebsite path='/przypomnienie-hasla' exact component={ForgotPassword} />
                <PrivateRouteWebsite path='/rejestracja' exact component={Signup} />
                <PrivateRouteWebsite path='/podziekowania' exact component={Acknowledgments} />
                <Route path='/akcje' exact component={Action} />
                <Route path='/wewnetrzny-blad-serwera' exact component={InternalServerError} />
                <Route path='/nie-znaleziono-strony' exact component={NotFound} />
                <Redirect from='*' to='/nie-znaleziono-strony' />
            </Switch>
        </Route>
     </Switch>
  )
}

export default Routes