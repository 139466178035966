import mockupNauka from '../images/mockupNauka.webp';
import mockupEgzamin from '../images/mockupEgzamin.webp';
import iconLearning from '../images/iconauka.svg';
import iconExam from '../images/icotest.svg';
import iconDepartments from '../images/icodzialy.svg';
import iconWrong from '../images/icobledy.svg';


const imageLeft = {
    flexDirection: 'row-reverse',
    WebkitBoxOrient: 'horizontal',
    WebkitBoxDirection: 'reverse',
    msFlexDirection: 'row-reverse'
}

const imageRight = {
    flexDirection: 'inherit',
    WebkitBoxOrient: 'inherit',
    WebkitBoxDirection: 'inherit',
    msFlexDirection: 'inherit'
}

export const MockupSectionData = 
[
    {
        componentDirection: imageRight,
        img: mockupEgzamin,
        icons: [iconExam , iconDepartments , iconWrong], 
        headerText: 'Pełna baza pytań egzaminacyjnych WORD przedstawiona w jasny sposób!',
        paragraphText: 'W aplikacji znajdziesz <b> najnowsze oficjalne pytania występujące na egzaminie WORD </b> dla <b>wszystkich kategorii</b>, a efektywny sposób nauki zapewnią Ci trzy dostępne sekcje w aplikacji. <br><br> Dział <B>EGZAMIN</b> jest odzwierciedleniem państwowego egzaminu w którym zmierzysz się 32 pytaniami, a na koniec otrzymasz wynik oraz dodatkowo przejrzysz pytania wraz z wyjaśnieniami(!), w których popełniłeś błędy. <br><br> Sekcja  <B>DZIAŁY</B>  pozwoli Ci, na zaliczanie pytań według działów, według których podzielone są pytania na prawo jazdy np. dział 8. Skrzyżowania z sygnalizacją świetlną. Dodatkowo w sekcji podczas rozwiązywania pytań będziesz widział  <b>wskazówki przygotowane przez naszych ekspertów</b>, które wspomogą Twoją naukę teorii oraz naprowadzą na dobrą odpowiedź. <br><br> Kącik <B>BŁĘDY</b> umożliwi Ci powrót do pytań, na które udzieliłeś błędnych odpowiedzi, co stanowi super utrwalenie najtrudniejszych pytań!<br/><br/>W aplikacji już teraz możesz założyć darmowe konto, dające Ci dostęp do trzech próbnych egzaminów oraz do pierwszych sekcji w działach nauka i działy. Darmowe konto nie ma limitu czasowego i umożliwia przetestowanie wyłącznie treści dla kategorii B.'
    },
    {
        componentDirection: imageLeft,
        img: mockupNauka,
        icons: [iconLearning], 
        headerText: 'Przystępna i efektywna nauka teorii!',
        paragraphText: 'Czy próbowałeś czytać <i>Prawo o ruchu drogowym</i>?<br> Znajdziesz tam dużo zbędnych informacji co utrudnia przyswajanie ważnych z punktu widzenia kandydata na kierowcę informacji.<br><br> Znaleźliśmy na to rozwiązanie.<b> Nasi eksperci wyodrębnili  z </b><i>Prawo o ruchu drogowym</i> <b>przepisy </b>dotyczące<b> kierowców, bezpieczeństwa </b>oraz<b> zachowania na drodze.</b> <br><br> Tak powstał dział <B>NAUKI</B>, który składa się z <b>350 przepisów wyselekcjonowanych przez naszych doświadczonych nauczycieli kodeksu drogowego oraz 390 znaków z pełnymi opisami.</b> Po zaznajomieniu z przepisem lub znakiem w aplikacji - wystarczy jedno kliknięcie, aby oznaczyć daną jednostkę teorii jako przyswojoną, co pozwala Ci w pełni panować nad postępem Twojej edukacji!'
    }

]

