import React , { useState } from 'react';
import { Card, Alert, Container } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { Link } from "react-router-dom";
import FirebaseErrorTranslateCode from '../../helpers/FirebaseTranslateCode';
import logo from "../../images/logo.svg";
import './UserPanel.css';
import * as Yup from 'yup';
import TextField from '../../components/userPanel/textField/TextField';
import { Formik, Form } from 'formik';
import SmallSpinner from '../../components/others/spinner/SmallSpinner';
import { CookiesInformationData } from '../../data/CookiesInformationData';
import CookieModal from '../../components/website/cookiesModal/CookieModal';
import { detectNetworkConnection } from '../../helpers/DetectNetworkConnection';

export default function ForgotPassword() {

    const params = new URLSearchParams(window.location.search);
    const premiumCodeParameter = params.get('premiumCode');

    const validate = Yup.object({
        email: Yup.string()
            .email('Adres email jest nie poprawny')
            .required('Adres email jest wymagany')          
    })
    
    const { resetPassword } = useAuth()
    const [error , setError] = useState('')
    const [message , setMessage] = useState('')
    const [loading , setLoading] = useState(false)

    async function sendResetPasswordEmail(values){

        setMessage('') 
        setError('')
        setLoading(true)

        if(detectNetworkConnection()){
            try{
                await resetPassword(values.email)
                .then(response => {
                     setMessage('Wiadomość została wysłana')
                 })
                 .catch(error => {
                     const polishError = FirebaseErrorTranslateCode(error.code)
                     setError(polishError)
                 })
     
             }
             catch (e){
                console.log(e);
                setError('Nie udało się wysłać wiadomości')
             }
        }
         setLoading(false)
         
    }
    return (
        <>
        <Formik
        initialValues={{
            email:''
        }}
        validationSchema={validate}
        onSubmit={values =>{
            sendResetPasswordEmail(values)
        }}
        >
            {formik => (
                <Container className="d-flex align-items-center justify-content-center UserPanel__Container UserPanelSection">
                    <div className="w-100" style={{ maxWidth: "400px"}}>
                        <Card>
                            <Card.Body>
                                <div className="UserPanel__Logo">
                                    <Link to='/'>
                                        <img src={logo} alt="logo" />
                                    </Link>
                                </div>
                            { error && <Alert variant="danger" className="alert-danger-custom">{error}</Alert>}
                            { message && <Alert variant="success ">{message}</Alert>}
                            { !message && 
                            <Form>
                                <TextField label="Email" name="email" type="email" placeholder="Adres email"/>
                                <button disabled={loading}  className={loading ? 'UserPanel__button button--loading' : 'UserPanel__button button--blue'} type="submit">
                                    {loading ? <SmallSpinner isText={false} />: "Zresetuj hasło"}
                                </button>
                            </Form>
                            }
                            <div className="w-100 text-center mt-2">
                                <Link to={premiumCodeParameter === null ? "/logowanie" : `/logowanie?premiumCode=${premiumCodeParameter}`} className='UserPanel__link'>Zaloguj się</Link>
                             </div>
                            </Card.Body>
                        </Card>
                        <div className="w-100 text-center mt-2">
                            Nie masz konta? <Link to={premiumCodeParameter === null ? "/rejestracja" : `/rejestracja?premiumCode=${premiumCodeParameter}`} className='UserPanel__link'>Załóż darmowe konto</Link>
                        </div>
                    </div>
                </Container>
            )}
        </Formik>  
        <CookieModal data={CookiesInformationData}/>
        </>
    )
}
