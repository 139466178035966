import React, { useState , useEffect } from 'react';
import { Tabs , Tab } from 'react-bootstrap';
import Layout from '../../../components/dashboard/layout/Layout';
import './Settings.css';
import Account from './Account';
import ChangePassword from './ChangePassword';
import Subscription from './Subscription';
import { useAuth } from '../../../contexts/AuthContext';

const Setting = () => {
   
    const breadcrumb = [
        { text:'Opcje', link:'/opcje' }
    ]

    const { currentUser } = useAuth();
    
    const checkFirebaseUserProvider =  async () => {
        try{
            const currentProviderId = currentUser.providerData[0].providerId;
            if(currentProviderId !== 'password'){
                setIsChangePasswordTabAvailable(false);
            }else{
                setIsChangePasswordTabAvailable(true);
            }
        }catch(ex){
            console.log(ex);
        }
    }

    useEffect(() => {
        checkFirebaseUserProvider();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const [ isChangePasswordTabAvailable , setIsChangePasswordTabAvailable ] = useState(false);

    return (
        <Layout title={breadcrumb}>
            <div className="Container">
                <Tabs
                    defaultActiveKey="account"
                    transition={false}
                    className="mb-3"
                >
                    <Tab eventKey="account" title="Konto">
                        <Account />
                    </Tab>

                    <Tab eventKey="subscription" title="Subskrypcja">
                        <Subscription />
                    </Tab>
                    {
                        isChangePasswordTabAvailable &&
                        <Tab eventKey="password" title="Hasło">
                            <ChangePassword />
                        </Tab>
                    }
                </Tabs>
            </div>
        </Layout>
    )
}

export default Setting
