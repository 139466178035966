import moment from "moment";
import { auth } from "../Firebase";
import { setLastTimeRefreshTokenDate } from "../redux/actions/userActions";
import store from "../redux/store";

const checkIfTokenRefreshedInLast10Seconds = () => {
    const lastTimeRefreshTokenDate = store.getState().user.lastTimeRefreshTokenDate;
    let isTokenRefreshedInLast10secs = false;
    
    if (lastTimeRefreshTokenDate === null){
        store.dispatch(setLastTimeRefreshTokenDate());
    }else{
        const now = moment().format('YYYY-MM-DD HH:mm:ss');
        const timeNowMinus10secs = moment(now).subtract(10, 'seconds');

        if (timeNowMinus10secs.isAfter(lastTimeRefreshTokenDate)) {
            isTokenRefreshedInLast10secs = true;
        }
    }
   
    return isTokenRefreshedInLast10secs;
}

const refreshFirebaseToken = async () => {
    let refreshToken = false;

    if (checkIfTokenRefreshedInLast10Seconds()){
        refreshToken = true;
        store.dispatch(setLastTimeRefreshTokenDate());
    }

    return await auth.currentUser.getIdToken(refreshToken);
}

export default refreshFirebaseToken;