import './CustomToast.css';
import { FaTimes } from 'react-icons/fa';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const CustomToast = (props) => {

    const location = useLocation();
    const { toastList, autoDelete , dismissTime, removeFirstToast } = props; 
    const [list, setList] = useState(toastList);
    const [notificationClass , setNotificationClass] = useState('Notification__toast--sidebar');

    useEffect(() => {
        addNewToast();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toastList]);

    useEffect(() => {

        const interval = setInterval(() => {
            if (autoDelete && toastList.length && list.length) {
                deleteToast(toastList[0].id);
            }
        }, dismissTime);
        
        return () => {
            clearInterval(interval);    
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toastList, autoDelete, dismissTime, list]);

    useEffect(() => {

    }, [list])

    useEffect(() => {

        const sidebarSectionExist = document.getElementsByClassName('SidebarSection').length !== 0;
        
        if(notificationClass !== 'Notification__toast--sidebar' && sidebarSectionExist){
            setNotificationClass('Notification__toast--sidebar')
        }

        if(notificationClass === 'Notification__toast--sidebar' && !sidebarSectionExist){
            setNotificationClass('')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    const deleteToast = id => {
        
        const listItemIndex = toastList.findIndex(e => e.id === id);
        if(listItemIndex !== -1){
            list.splice(0 , list.length);
            setList([...list]);         
        }
    }
     
    function addNewToast(){
        if(toastList.length <= 1){
            setList(toastList);
        }
        if(toastList.length >= 2){
            deleteToast(toastList[0].id);
            removeFirstToast();
        }
    }
    
   
    return (
        <>
            <div className={`Notification__container bottom-right ${notificationClass === 'Notification__toast--sidebar' ? 'bottom-right--sidebar' : 'bottom-right--withoutSidebar'}`}>
                {
                    list.map((toast, i) =>     
                        <div 
                            key={i}
                            id={toast.id}
                            className={`Notification__toast ${notificationClass} bottom-right ${toast.color}`}
                            onClick={() => deleteToast(toast.id)}
                        >
                            <div className="Notification__svg">
                                <FaTimes  onClick={() => deleteToast(toast.id)} />
                            </div>

                            <div>
                                <p className="notification-message">
                                    {toast.description}
                                </p>
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default CustomToast
