import { ActionAnswersTypes } from '../contants/action-types';
import store from '../store'
import { saveQuetionsAnswersLocal, computeProgressInQuestions } from './questionActions';
import { getAmountOfWrongs } from './wrongActions';
import { saveSignsAnswersLocal, computeProgressInSigns } from './signActions';
import { QueueAnswerService } from '../../services/QueueAnswerService';
import { saveRegulationsAnswersLocal, computeProgressInRegulations } from './regulationActions';

export const addQuestionsAnswers = (answers, resetFlag) => {
    try{
        let queueAnswers = new QueueAnswerService().getQueueQuestions()

        answers.forEach((value, key) => {
            queueAnswers.set(key, value)
        })
    
        store.dispatch(saveQuetionsAnswersLocal(answers)).then(() => {
            store.dispatch(computeProgressInQuestions());
            store.dispatch(getAmountOfWrongs());
        })
    
        let stringifyQueueAnswers = JSON.stringify(Array.from(queueAnswers.entries()));
        localStorage.setItem("queueAnswersQuestions" , stringifyQueueAnswers);

        return{
            type: ActionAnswersTypes.ADD_QUESTIONS_ANSWERS,
            payload: { stringifyQueueAnswers , resetFlag }
        }
    }catch(ex){
        console.log(ex);
    }
}

export const clearQuestionsAnswersQueue = () => {
    console.log("answersActions clearQuestionsAnswersQueue")
    localStorage.setItem("queueAnswersQuestions", "");
    return{
        type: ActionAnswersTypes.CLEAR_QUESTIONS_ANSWERS_QUEUE
    }
}

export const addSignsAnswers = (answers) => {
    try{
        let signsAnswersQueue = new QueueAnswerService().getQueueSigns()
    
        answers.forEach((value, key) => {
            signsAnswersQueue.set(key, value)
        })
    
        store.dispatch(saveSignsAnswersLocal(answers)).then(() => {
            store.dispatch(computeProgressInSigns());
        })
    
        let stringifyQueueAnswers = JSON.stringify(Array.from(signsAnswersQueue.entries()));
        localStorage.setItem("queueAnswersSigns" , stringifyQueueAnswers);
    
        return{
            type: ActionAnswersTypes.ADD_SIGNS_ANSWERS,
            payload: stringifyQueueAnswers
        }
    }catch(ex){
        console.log(ex)
    }
}

export const clearSignsAnswersQueue = () => {
    localStorage.setItem("queueAnswersSigns", "");
    return{
        type: ActionAnswersTypes.CLEAR_SIGNS_ANSWERS_QUEUE
    }
}

export const addRegulationsAnswers = (answers) => {
    try{
        let queueAnswers = new QueueAnswerService().getQueueRegulations()
    
        answers.forEach((value, key) => {
            queueAnswers.set(key, value)
        })
    
        store.dispatch(saveRegulationsAnswersLocal(answers)).then(() => {
            store.dispatch(computeProgressInRegulations());
        })
    
        let stringifyQueueAnswers = JSON.stringify(Array.from(queueAnswers.entries()));
        localStorage.setItem("queueAnswersRegulations" , stringifyQueueAnswers);
    
        return{
            type: ActionAnswersTypes.ADD_REGULATIONS_ANSWERS,
            payload: stringifyQueueAnswers
        }
    }catch(ex){
        console.log(ex)
    }
}

export const clearRegulationsAnswersQueue = () => {
    localStorage.setItem("queueAnswersRegulations", "");
    return{
        type: ActionAnswersTypes.CLEAR_REGULATIONS_ANSWERS_QUEUE
    }
}

export const timestampNow = () => {
    return{
        type: ActionAnswersTypes.TIMESTAMP_NOW
    }
}

export const timestampResque = () => {
    return{
        type: ActionAnswersTypes.TIMESTAMP_RESQUE
    }
}

export const setIsStarted = (isStarted) => {
    return{
        type: ActionAnswersTypes.SET_IS_STARTED,
        payload: isStarted
    }
}

export const refreshPage = (pageToRefresh, value) => {
    
    return{
        type: ActionAnswersTypes.SET_REFRESH_PAGE,
        payload: { pageToRefresh , value }
    }
}