import { ModalContent } from '../../models/ModalContet';
import { ActionNotificationTypes } from '../contants/action-types';

export const showToast = (toastMessage , type) => async dispatch => {
    dispatch({
        type: ActionNotificationTypes.SHOW_TOAST,
        payload: { toastMessage , type },
    })
}

export const resetModalSettings = () =>  {

    const showToast = false;
    const toastMessage = "";
    const type = "";

    return{
        type: ActionNotificationTypes.RESET_TOAST_SETTINGS,
        payload: { showToast, toastMessage, type  }
    }
}

export const showModal = (modalContent) => {
    
    return{
        type: ActionNotificationTypes.SHOW_MODAL,
        payload: modalContent
    }  
}

export const closeModal = () => {

    const resetModalContent = new ModalContent();

    return{
        type: ActionNotificationTypes.CLOSE_MODAL,
        payload: resetModalContent
    }
}

export const setLoadingInModal = (loading) => {

    return{
        type: ActionNotificationTypes.SET_LOADING_IN_MODAL,
        payload: loading
    }
}

export const changeTypeOfModal = (type) => {

    return{
        type: ActionNotificationTypes.CHANGE_TYPE_OF_MODAL,
        payload: type
    }
}
