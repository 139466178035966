import React, { useEffect } from "react";
import './App.css';
import { Router } from 'react-router-dom';
import {AuthProvider} from "./contexts/AuthContext";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ToastManagerProvider } from "./contexts/ToastManagerContext";
import history from "./helpers/history";
import Routes from "./routes/Routes";
import { OnlineStatusProvider } from "./contexts/OnlineStatusContext";
import { GlobalDebug } from "./helpers/GlobalDebug";

function App() {

    useEffect(() => {
      if(process.env.REACT_APP_ENV === "PRODUCTION"){
        GlobalDebug(false);
      }
    }, [])

    return (
      <Provider store={store}> 
        <Router history={history}>
          <OnlineStatusProvider>
            <AuthProvider>
              <ToastManagerProvider>
                <Routes />
              </ToastManagerProvider>
            </AuthProvider>
          </OnlineStatusProvider>
        </Router>
      </Provider>
    );
  }
  
  export default App;
