import React from 'react';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import './AccountQuickStart.css';
import CustomButton from '../../others/button/CustomButton';
import { useHistory } from "react-router-dom";

const AccountQuickStart = () => {

    const history = useHistory();

    return (
        <div className='AccountQuickStart__Container'>
            <div className='AccountIcon__Container'>
                <IoIosInformationCircleOutline />
                <p><span style={{fontWeight: 500}}>Szybki start</span>
                <br/>Rozpocznij testy i przygotuj się na egzamin w wordzie.</p>
            </div>
            <div style={{width: '100%'}}>
                <CustomButton 
                    onClick={() => history.push("/egzamin")} 
                    content={"Zacznij egzamin"}
                    widthInPercent={100}
                />
            </div>
        </div>
    )
}

export default AccountQuickStart