import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/dashboard/sidebar/Sidebar';
import Topnav from '../../components/dashboard/topnav/Topnav';
import './Dashboard.css';
import { SidebarData } from '../../data/SidebarData';
import { useDispatch, useSelector } from 'react-redux';
import { getSignsFromApi } from '../../redux/actions/signActions';
import { getRegulationsFromApi } from '../../redux/actions/regulationActions';
import { getQuestionsFromApi } from '../../redux/actions/questionActions';
import { getUser, logOut } from '../../redux/actions/userActions';
import { timestampNow } from '../../redux/actions/answersActions';
import store from '../../redux/store';
import { QueueAnswerService } from '../../services/QueueAnswerService';
import { ResqueQueueService } from '../../services/ResqueQueueService';
import SmallSpinner from '../../components/others/spinner/SmallSpinner';
import Modal from '../../components/others/modal/Modal';
import { CryptoSha1 } from '../../helpers/Crypto';
import agent from '../../api/agent';
import { useOnlineStatus } from '../../contexts/OnlineStatusContext';
import { useToastManager } from '../../contexts/ToastManagerContext';
import { useAuth } from "../../contexts/AuthContext";
import { auth } from '../../Firebase';

const Dashboard = (props) => {

    const { logout } = useAuth();

    const dispatch = useDispatch();
    const { showToast } = useToastManager();
    const isOnline = useOnlineStatus();  

    const stores = useSelector((state) => state);
    const [loading, setLoading] = useState(false);
    const [isStarted, setIsStarted] = useState(false);

    let signs = stores.signs.signs;
    let regulations = stores.regulations.regulations;
    let questions = stores.questions.questions;
    let signsAnswers = stores.signs.signsAnswers;
    let regulationsAnswers = stores.regulations.regulationsAnswers;
    let questionsAnswers = stores.questions.questionsAnswers;

    const user = stores.user.user;

    useEffect(() => {
        console.log("queueAnswers INTERVAL JOB is STARTING!")
        const queueAnswersJob = setInterval(() => {
            let dateTimeLastExecution = new Date(store.getState().answers.timestamp)
            let dateTimeNow = Date.now()
            let durrationBettwenDateTimesInMs = dateTimeNow - dateTimeLastExecution
            let durToSec = durrationBettwenDateTimesInMs / 1000
            if (durrationBettwenDateTimesInMs > 1000 * 10) {
                console.log(" minelo " + durToSec + " sec, sprawdzam kolejki odpowiedzi")
                dispatch(timestampNow())
                new QueueAnswerService().sendAnswersFromQueues()
            }

        }, 10000);
        return () => clearInterval(queueAnswersJob);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let resqueQueueService = new ResqueQueueService();
        resqueQueueService.start();
        changeBackgroundColor();
        
        return () => {
            resqueQueueService.stop();
            resetBackgroundColor();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeBackgroundColor = () => {
        document.body.style = "background-color: #eff2f5;";
    }

    const resetBackgroundColor = () => {
        document.body.style = "background-color: #fff;";    
    }

    useEffect(() => {
        if(navigator.onLine && auth.currentUser !== null){
            setLoading(false);
            initializeData();
        }else{
            setLoading(true);
        }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOnline])

    function initializeData() {
        try{
            if (
                signs.length === 0 || 
                regulations.length === 0 || 
                questions.length === 0 || 
                Array.isArray(signsAnswers) || 
                Array.isArray(regulationsAnswers) || 
                Array.isArray(questionsAnswers)
            ){

                setLoading(true);
    
                let getBasicDataPromise = new Promise((reslove, reject) => {
    
                    if (!isStarted) {
    
                        if (signs.length === 0) {
                            dispatch(getSignsFromApi());
                        }
    
                        if (regulations.length === 0) {
                            dispatch(getRegulationsFromApi());
                        }
    
                        if (questions.length === 0) {
                            dispatch(getQuestionsFromApi());
                        }
    
                        setIsStarted(false);
                    }
    
                    let getBesicDataInterval = setInterval(() => {
    
                        let signsLenght = store.getState().signs.signs.length;
                        let regulationsLenght = store.getState().regulations.regulations.length;
                        let questionsLenght = store.getState().questions.questions.length;

                        if ( signsLenght !== 0 && regulationsLenght !== 0 && questionsLenght !== 0 ) {
                            clearInterval(getBesicDataInterval);
                            reslove("Basic data has been initialized");
                        }
    
                    }, 1500);
    
                })
    
                getBasicDataPromise.then(() => {               
                    dispatch(getUser()).then(() => {
                        setLoading(false);  
                    });                                 
    
                })            
            }
            else {
                initializeUserData();
                validateDataFromLocalStorage();
            }
        }catch(ex){
            setLoading(true);
            console.log(ex);
        }
    }

    function initializeUserData() {
        if(user === null){
            dispatch(getUser());
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    },[]);

    async function validateDataFromLocalStorage(){
        try{
            const info = await agent.User.getInfo();
        
            if(typeof info !== 'undefined'){
                //signs
                const signsString = JSON.stringify(signs);
                const signsChecksumFromLocalStorage = await CryptoSha1(signsString);
    
                if(signsChecksumFromLocalStorage !== info.signsTableChecksum){
                    setLoading(true);
                    dispatch(getSignsFromApi()).then(() => setLoading(false));
                }
                //regulations
                const regulationsString = JSON.stringify(regulations);
                const regulationsChecksumFromLocalStorage = await CryptoSha1(regulationsString);
    
                if(regulationsChecksumFromLocalStorage !== info.regulationsTableChecksum){
                    setLoading(true);
                    dispatch(getRegulationsFromApi()).then(() => setLoading(false));
                }
                //questions
                const questionsString = JSON.stringify(questions);
                const questionsChecksumFromLocalStorage = await CryptoSha1(questionsString);
    
                if(questionsChecksumFromLocalStorage !== info.questionsTableChecksum){
                    setLoading(true);
                    dispatch(getQuestionsFromApi()).then(() => setLoading(false));
                }
                //
                
            }
        }catch(e){
            console.log(e);
            setLoading(true);
        }
    }

    useEffect(() => {

        window.addEventListener('storage', handleLogoutInAnotherOpenTabs)
    
        return _ => { window.removeEventListener('storage', handleLogoutInAnotherOpenTabs) }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleLogoutInAnotherOpenTabs = async e => {

        if(e.key === 'access_token' && e.oldValue && !e.newValue) {       
            await logout().then(() => {       
                dispatch(logOut());
                showToast('custom-success' , 'Poprawnie wylogowano użytkownika w innej karcie przeglądarki');
            });
        }
    }

    return (
        <>
        <Modal />
        <div className="DashboardSection">

            {
                loading &&
                <SmallSpinner
                    isText={true}
                    isCenter={true}
                />
            }
            {
                !loading &&
                <>
                    <Topnav />
                    <Sidebar data={SidebarData} />
                    {props.children}
                </>
            }

        </div>
        </>
    )
}

export default Dashboard
