import React , { useEffect , useState } from 'react';
import './ExamTimer.css';

const ExamTimer = (props) => {

    const [ minutes , setMinutes ] =  useState(25);
    const [ seconds , setSeconds ] =  useState(0);

    useEffect(()=>{
        let interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            } 
            if(minutes === 0 && seconds === 0){
                props.finishExam();
            }
        }, 1000)
        return ()=> {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [minutes , seconds]);


    return (
        <div className='ExamTimer__Container'>
            <p>Czas do końca egzaminu</p>
            <div className='ExamTimer__Cover'>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</div>
        </div>
    )
}

export default ExamTimer