import { Section } from '../../models/Section';

export const SignsSections = [
    new Section("signs", "title", "Znaki drogowe",  '' , false),
    new Section("signs", "a", "Znaki ostrzegawcze (A)", '/nauka/znaki/znaki-ostrzegawcze' , false),
    new Section("signs", "b", "Znaki zakazu (B)",  '/nauka/znaki/znaki-zakazu' , true),
    new Section("signs", "c", "Znaki nakazu (C)",  '/nauka/znaki/znaki-nakazu' , true),
    new Section("signs", "d", "Znaki informacyjne (D)",  '/nauka/znaki/znaki-informacyjne' , true),
    new Section("signs", "e", "Znaki kierunku i miejscowości (E)",  '/nauka/znaki/znaki-kierunku-i-miejscowosci' , true),
    new Section("signs", "f", "Znaki uzupełniające (F)",  '/nauka/znaki/znaki-uzupelniajace' , true),
    new Section("signs", "g", "Znaki dodatkowe (G)", '/nauka/znaki/znaki-dodatkowe' , true),
    new Section("signs", "p", "Znaki poziome (P)", '/nauka/znaki/znaki-poziome' , true),
    new Section("signs", "t", "Tabliczki do znaków (T)", '/nauka/znaki/tabliczki-do-znakow' , true),
    new Section("signs", "syg", "Syngalizacja świetlna", '/nauka/znaki/syngalizacja-swietlna' , true),
    new Section("signs", "pol", "Sygnały dawane przez kierującego ruchem",  '/nauka/znaki/sygnaly-dawane-przez-kierujacego-ruchem' , true)
]