import React from 'react';
import ReactDOM from 'react-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import Root from './Root.js';

ReactDOM.render(
  <Root/>,
  document.getElementById('root')
);

