import React from 'react';
import './CustomButton.css';
import SmallSpinner from '../spinner/SmallSpinner';
import Image from 'react-bootstrap/Image';

const CustomButton = (props) => {

    const widthInPercent = props.widthInPercent ? { width: props.widthInPercent + '%'} : {};
    const heightInPixels = props.heightInPixels ? { height: props.heightInPixels + 'px' , padding: '0px'} : { minHeight: '50px'};    
    const customClass = prepareCustomClass();
    
    function prepareCustomClass(){
        let defaultClass = "Button";
        if(props.idOfActiveButton !== undefined && props.value !== undefined){
            if(parseInt(props.idOfActiveButton) === props.value){
                return "Button--active";
            }
        }
        return props.color === undefined ? defaultClass : `Button--${props.color}`;
    }

    return (
        <button 
            disabled={props.loading} 
            value={props.value} 
            onClick={props.onClick} 
            className={customClass} 
            type={props.type}
            style={{...widthInPercent , ...heightInPixels}}
        >
            {
                props.loading ? 
                <SmallSpinner 
                    isText={false} 
                /> : 
                <>
                    {
                        props.image !== undefined ?
                        <div className='Button--image'>
                            <Image  
                                alt={props.alt} 
                                src={props.image}
                            />
                            {props.content}
                        </div> :
                        <>
                            {props.content}
                        </>
                    }
                </>          
            }
        </button>
    )
}

export default CustomButton
