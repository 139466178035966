import { ActionSignsTypes } from '../contants/action-types';
import agent from '../../api/agent';
import { UserProgressService } from '../../services/UserProgressService';
import store from '../store';
import { CryptoSha1 } from '../../helpers/Crypto';

export const getSignsFromApi = () => async dispatch => {
    try{
        const signs = await agent.Signs.getSigns();
        console.log(signs);
        localStorage.setItem("signs" , JSON.stringify(signs));
        dispatch( {
            type: ActionSignsTypes.SAVE_SIGNS,
            payload: signs
        })
    }catch (error){
        console.log(error)
    }
};

export const getSignsAnswersFromApi = () => async dispatch => {
    try{
        const answers = await agent.UserAnswers.getSigns()
       
        let answersString = JSON.stringify(answers.signsAnswers);
        localStorage.setItem("signsAnswers" , answersString);

        let answersChecksum = await CryptoSha1(answersString)
        let answersTimestamp = Date.now()

        dispatch(saveSignsAnswersChecksumAndTimestamp(answersChecksum, answersTimestamp))
        dispatch( {
            type: ActionSignsTypes.SAVE_SIGNS_ANSWERS,
            payload: answers.signsAnswers
        })
    }catch (error){
        console.log(error)
    }
};

export const saveSignsAnswersChecksumAndTimestamp = (answersChecksum, answersTimestamp) => {

    console.log("saveSignsAnswersChecksumAndTimestamp")
    localStorage.setItem("signsAnswersTimestamp", answersTimestamp)
    localStorage.setItem("signsAnswersChecksum", answersChecksum)

    return{
        type: ActionSignsTypes.SAVE_SIGNS_ANSWERS_CHECKSUM_TIMESTAMP,
        payload: { answersChecksum, answersTimestamp,  }
    };

};

export const saveSignsAnswersLocal = (answersToSave) => async dispatch => {
    try{    
        const updatedSignsAnswers = store.getState().signs.signsAnswers
        answersToSave.forEach((value, key) => {
            updatedSignsAnswers[key] = value
        })

        let answersString = JSON.stringify(updatedSignsAnswers)
        localStorage.setItem("signsAnswers", answersString);
        
        let answersChecksum = await CryptoSha1(answersString)
        let answersTimestamp = Date.now()      
        dispatch(saveSignsAnswersChecksumAndTimestamp(answersChecksum, answersTimestamp))
    
        dispatch({
            type: ActionSignsTypes.SAVE_SIGNS_ANSWERS,
            payload: updatedSignsAnswers
        })
    }catch (error){
        console.log(error)
    }
};

export const computeProgressInSigns = (signsAnswers) => {

    if(signsAnswers === undefined){
        signsAnswers = store.getState().signs.signsAnswers;
    }

    let signs = store.getState().signs.signs;
    let signsProgress = new UserProgressService().combineProgressSignsSections(signs , signsAnswers);

    return{
        type: ActionSignsTypes.UPDATE_PROGRESS_IN_SIGNS,
        payload: signsProgress
    };

};

export const resetAnswersSignsLocal = () => async dispatch => {

    const signsAnswers = store.getState().signs.signsAnswers
    const newSignsAnswers = {...signsAnswers};
    
    Object.keys(newSignsAnswers).forEach(function(key){ newSignsAnswers[key] = 0}); 

    let answersString = JSON.stringify(newSignsAnswers)
    localStorage.setItem("signsAnswers", answersString);
    
    let answersChecksum = await CryptoSha1(answersString)
    let answersTimestamp = Date.now()      
    dispatch(saveSignsAnswersChecksumAndTimestamp(answersChecksum, answersTimestamp)) 

    dispatch({
        type: ActionSignsTypes.SAVE_SIGNS_ANSWERS,
        payload: newSignsAnswers
    })
}