import React , { useEffect } from 'react';
import { ModalContent } from '../../../models/ModalContet';
import { useDispatch  } from 'react-redux';
import { showModal , closeModal } from '../../../redux/actions/notificationActions';
import { useHistory } from 'react-router-dom';
import { setshowedExamSummaryModal } from '../../../redux/actions/examActions';

const ExamSummary = (props) => {
    
    const dispatch = useDispatch();
    const history = useHistory();
    const amountOfWrongAnswers = 32 -  (props.data.positiveCount === undefined ? 0 : props.data.positiveCount) ;
    const amountOfPoints = props.data.points === undefined ? 0 : props.data.points;

    const positiveResult = new ModalContent(
        "Success",
        "WYNIK TESTU POZYTYWNY",
        amountOfWrongAnswers > 0 ? `Uzyskałeś wynik pozytywny, uzyskując ${amountOfPoints} na 74 punkty. Ilość błędnych odpowiedzi: ${amountOfWrongAnswers}/32` : `Uzyskałeś wynik pozytywny, uzyskując ${props.data.points} na 74 punkty.`,
        null,
        null,
        "Przeglądaj",
        () => handleCloseModal()
    );

    const negativeResult = new ModalContent(
        "Error",
        "WYNIK TESTU NEGATYWNY",
        `Uzyskałeś wynik negatywny, uzyskując ${amountOfPoints} na 74 punkty. Ilość błędnych odpowiedzi: ${amountOfWrongAnswers}/32`,
        null,
        null,
        "Przeglądaj",
        () => handleCloseModal()
    );

    function handleCloseModal(){
        dispatch(closeModal());
        props.showExamResult();
    }

    useEffect(()=>{
        if(props.data.isPositive){
            dispatch(showModal(positiveResult));
        }else{
            dispatch(showModal(negativeResult));
        }
        dispatch(setshowedExamSummaryModal(true));

        return () => {
            dispatch(setshowedExamSummaryModal(false)); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        return () => {
          if (history.action === "POP") {
            dispatch(closeModal());
          }
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history])

    return (
        <>
        </>
    )
}

export default ExamSummary