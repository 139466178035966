export class Section  {

    constructor(category , section , title , link, disabledInDemoVersion){
        this.category = category
        this.section = section
        this.title = title
        this.link = link
        this.disabledInDemoVersion = disabledInDemoVersion
    }

}
