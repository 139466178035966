import React from 'react';
import './Badge.css';
import SmallSpinner from '../spinner/SmallSpinner';

const Badge = (props) => {

  return(
    <>
      {props.number !== 0 
        &&
        <div className='Badge' style={ props.number === null ? { backgroundColor: 'white'} : {}}>
          {props.number === null ? <SmallSpinner /> : props.number}
        </div>
      }
    </>
  )
};

export default Badge;
