import React , { useEffect } from 'react'
import SmallSpinner from '../../others/spinner/SmallSpinner';
import './ProgressCircle.css'

const ProgressCircle = (props) => {

    const amountOfProgress = "calc(220px - (220px * " + props.progress +") / 100)";
    const color = props.color;

    useEffect(() => {

    }, [props.progress])
    
    if(props.progress !== null && props.progress !== undefined)
    return(
        <div className="ProgressCircle">
            <svg>
                <circle cx="70" cy="70" r="35" style={ props.secondColor ? { stroke: props.secondColor } : {}}></circle>
                <circle cx="70" cy="70" r="35" style={{strokeDashoffset: amountOfProgress , stroke: color }}></circle> 
            </svg>
            <div className={props.progress !== 100 ? "ProgressCircle__number" : "ProgressCircle__number FullProgress"} style={{color: color , left:  props.progress === 100 ? '57px' : '62px'}}>
            <>{props.progress}<span>%</span></>       
            </div>
        </div>
    )
    else if(props.progress === null || props.progress === undefined)
    return (
        <div className="ProgressCircle">
            <SmallSpinner isText={true}/>
        </div>
    )
}

export default ProgressCircle
