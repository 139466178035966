import React , {  useState , useEffect } from 'react';
import { Card, Alert, Container} from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { Link, useHistory } from "react-router-dom";
import { facebookProvider, googleProvider } from '../../Firebase';
import FirebaseErrorTranslateCode from '../../helpers/FirebaseTranslateCode';
import logo from "../../images/logo.svg";
import './UserPanel.css';
import { FaFacebookSquare } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextField from '../../components/userPanel/textField/TextField';
import SmallSpinner from '../../components/others/spinner/SmallSpinner';
import CookieModal from '../../components/website/cookiesModal/CookieModal';
import { CookiesInformationData } from '../../data/CookiesInformationData';
import { CheckLocalstorageIfEnable } from '../../helpers/CheckLocalstorageIfEnable';
import Modal from '../../components/others/modal/Modal';
import { detectNetworkConnection } from '../../helpers/DetectNetworkConnection';
import DetectBrowserVersion from '../../helpers/DetectBrowserVersion';
import { ifChromeMobileBrowserSignInWithRedirect } from '../../helpers/AuthContextHelper';

export default function Login() {

    const params = new URLSearchParams(window.location.search);
    const premiumCodeParameter = params.get('premiumCode');

    const validate = Yup.object({
        email: Yup.string()
            .email('Adres email jest nie poprawny')
            .required('Adres email jest wymagany'),
        password: Yup.string()
            .min(6 , 'Hasło musi mieć minimum 6 znaków')
            .required('Hasło jest wymagane')          
    })

    const { login, loginBySocialMedia } = useAuth();
    const [error , setError] = useState('')
    const [loading , setLoading] = useState(false)
    const [loadingByFacebook , setLoadingByFacebook] = useState(false)
    const [loadingByGoogle , setLoadingByGoogle] = useState(false)
    const history = useHistory()

    async function handleOnClick(provider){
        let IsLocalStorageEnabled = CheckLocalstorageIfEnable();
        if(detectNetworkConnection() && !DetectBrowserVersion() && IsLocalStorageEnabled){
            try{
                setError('')

                setLoadingBySocialMedia(provider.providerId, true);

                await ifChromeMobileBrowserSignInWithRedirect(provider);

                await loginBySocialMedia(provider).then(() => 
                {
                    if(premiumCodeParameter === null){
                        history.push("/konto");
                    }else{
                         history.push(`/podsumowanie-platnosci/${premiumCodeParameter}`);
                    }   
                }).catch((error) => {
                    setError(FirebaseErrorTranslateCode(error.code));
                });
             }
             catch (e) {
                console.log(e);

                setError('Nie udało się zalogować')
             }
     
            setLoadingBySocialMedia(provider.providerId, false);
        }
    }

    const setLoadingBySocialMedia = (providerId, value) => {
        if (providerId === 'google.com'){
            setLoadingByGoogle(value);
        }

        if (providerId === 'facebook.com'){
            setLoadingByFacebook(value);
        }
    }

    async function sendLogin(values){

        setError('')
        setLoading(true)

        let IsLocalStorageEnabled = CheckLocalstorageIfEnable();
        const isOnline = detectNetworkConnection();
        if(IsLocalStorageEnabled && isOnline && !DetectBrowserVersion()){
            
            try{
                await login(values.email , values.password).then(response => {                  
                    if(premiumCodeParameter === null){
                        history.push("/konto");
                    }else{
                        history.push(`/podsumowanie-platnosci/${premiumCodeParameter}`);
                    }   
                }).catch(error => {
                    const polishError = FirebaseErrorTranslateCode(error.code)
                    setError(polishError)
                })
            }
            catch (e){
                console.log(e);
                setError('Nie udało się zalogować')
            }
            
        }
        setLoading(false)
    }

    useEffect(() => {
        return () => {
            setError('');
            setLoading(false);
            setLoadingByFacebook(false);
            setLoadingByGoogle(false);
        }
    }, [])

    return (
        <>
        <Modal/>
        <Formik
        initialValues={{
            email:'',
            password:''
        }}
        validationSchema={validate}
        onSubmit={values =>{
            sendLogin(values)
        }}
        >
            {formik => (
                <Container className="d-flex align-items-center justify-content-center UserPanel__Container UserPanelSection">
                    <div className="w-100" style={{ maxWidth: "400px"}}>
                        <Card>
                            <Card.Body>
                                <div className="UserPanel__Logo">
                                    <Link to='/'>
                                        <img src={logo} alt="logo" />
                                    </Link>
                                </div>
                            { error && <Alert variant="danger" className="alert-danger-custom">{error}</Alert>}
                            <Form>
                                <TextField label="Email" name="email" type="email" placeholder="Adres email"/>
                                <TextField label="Hasło" name="password" type="password" placeholder="Hasło"/>
                                <button disabled={loading}  className={loading ? 'UserPanel__button button--loading' : 'UserPanel__button button--blue'} type="submit">
                                    {loading ? <SmallSpinner isText={false} />: "Zaloguj się"}
                                </button>
                                <div className="w-100 text-center mt-2">
                                    <Link to={premiumCodeParameter === null ? "/przypomnienie-hasla" : `/przypomnienie-hasla?premiumCode=${premiumCodeParameter}`} className='UserPanel__link'>Zapomniałeś hasła?</Link>       
                                </div>
                            </Form>
                            <div className="UserPanel__Separator">
                                    <span className="UserPanel__TextInSeparator">lub</span>
                                </div>
                                <div className="UserPanel__ContainerButton">
                                    <button onClick={() => {handleOnClick(facebookProvider)}} className="UserPanel__button button--fb">
                                        {
                                            loadingByFacebook ? 
                                            <SmallSpinner isText={false} /> :
                                             <>
                                            <FaFacebookSquare />Facebook
                                            </>
                                        }
                                    </button>
                                    <button onClick={() => {handleOnClick(googleProvider)}} className="UserPanel__button button--google">
                                        {
                                            loadingByGoogle ?
                                            <SmallSpinner isText={false} /> :
                                            <>
                                                <FcGoogle />Google
                                            </>                                            
                                        }
                                    </button>
                            </div>
                            </Card.Body>
                        </Card>
                        <div className="w-100 text-center mt-2">
                            Nie masz konta? <Link to={premiumCodeParameter === null ? "/rejestracja" : `/rejestracja?premiumCode=${premiumCodeParameter}`} className='UserPanel__link'>Załóż darmowe konto</Link>
                            
                        </div>
                    </div>
                </Container>
            )}
        </Formik>  
        <CookieModal data={CookiesInformationData}/>
        </>
    )
}
