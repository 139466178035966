import { ActionDepartamentTypes } from '../contants/action-types';

const initialState = {
    exList: [],
    activeQuestion: null,
    currentSection: null
}

export const departamentReducer = (state = initialState, { type, payload }) => {
    
    let newExList;

    switch (type) {
        case ActionDepartamentTypes.SET_ACTIVE_QUESTION:
            return {
                ...state,
                activeQuestion: payload       
            }
        case ActionDepartamentTypes.SET_CURRENT_SECTION:
            //console.log(payload)
            return {
                ...state,
                currentSection: payload
            }
        case ActionDepartamentTypes.RESET_ACTIVE_QUESTION:
            return {
                ...state,
                activeQuestion: payload
            }
        case ActionDepartamentTypes.DELETE_QUESTION_FROM_EX_LIST:

            if(state.exList.includes(payload)){
                newExList = [ ...state.exList];
                newExList = newExList.filter(item => item !== payload);
                //console.log(newExList);
            }
            
            if(typeof newExList !== 'undefined'){
                return {
                    ...state,
                    exList: newExList
                } 
            }
            else{
                return {
                    ...state
                }  
            }
        
        case ActionDepartamentTypes.MOVE_QUESTION_FROM_EX_LIST:

            newExList = [ ...state.exList];

            if(state.exList.includes(payload)){                           
                newExList = newExList.filter(item => item !== payload);
            }

            newExList.push(payload);
            //console.log(newExList);

            if(typeof newExList !== 'undefined'){
                return {
                    ...state,
                    exList: newExList
                } 
            }
            else{
                return {
                    ...state
                }  
            }

        case ActionDepartamentTypes.DELETE_ALL_QUESTIONS_FROM_EX_LIST:
                
            return {
                ...state,
                exList: payload
            }
        
        default: return state
    }

};