import React  from 'react';
import './Sidebar.css';
import SidebarItem from './SidebarItem';

const Sidebar = (props) => {

    return (
        <div className="SidebarSection">
            <div className="Sidebar__container">
                <ul className="Sidebar__menu">
                {
                    props.data.map((item, i) =>     
                        <SidebarItem key={item.id} data={item}/> 
                    )
                }
                </ul>
            </div>
        </div>
    )
}

export default Sidebar