import React from 'react';
import { useHistory } from 'react-router-dom';
import ResetPassword from '../page/userPanel/ResetPassword'

const Action = () => {

  const history = useHistory();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const mode = params.get('mode');
  const actionCode = params.get('oobCode')

  switch (mode) {
    case 'resetPassword':
        return <ResetPassword actionCode={actionCode} />
    default:
        history.push("/nie-znaleziono-strony");
        return <></>
  }
}

export default Action;