import { SignsSections } from '../../data/Sections/SignsSections';
import { ActionSignsTypes } from '../contants/action-types';
import { getLocalStorageItemOrDefault, getLocalStorageItemParsedJsonOrDefault } from '../../helpers/GetLocalStorageItemOrDefault';
import 'core-js/es/object/from-entries';

const initialState = {
    signsSections: SignsSections,
    signs: getLocalStorageItemParsedJsonOrDefault('signs', []),
    signsAnswers: getLocalStorageItemParsedJsonOrDefault('signsAnswers', []),
    signsAnswersChecksum: getLocalStorageItemOrDefault('signsAnswersChecksum', ""),
    signsAnswersTimestamp: getLocalStorageItemOrDefault('signsAnswersTimestamp', ""), 
    signsProgress: {}
}

export const signReducer = (state = initialState, { type, payload }) => {

    
    switch (type) {
        case ActionSignsTypes.SAVE_SIGNS:
            return {
                ...state,
                signs: payload
            }
        case ActionSignsTypes.SAVE_SIGNS_ANSWERS:
            return {
                ...state,
                signsAnswers: payload
            }
        case ActionSignsTypes.SAVE_SIGNS_ANSWERS_CHECKSUM_TIMESTAMP:
            return {
                ...state,
                signsAnswersChecksum: payload.answersChecksum,
                signsAnswersTimestamp: payload.answersTimestamp,
            }
        case ActionSignsTypes.UPDATE_PROGRESS_IN_SIGNS:
            return {
                ...state,
                signsProgress: Object.fromEntries(payload)
            }
        default: return state
    }
};