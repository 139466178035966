import React , { useState , useEffect } from 'react';
import { Card, Alert, Container } from 'react-bootstrap';
import { Link , useHistory } from "react-router-dom";
import logo from "../../images/logo.svg";
import './UserPanel.css';
import { useAuth } from '../../contexts/AuthContext';
import FirebaseErrorTranslateCode from '../../helpers/FirebaseTranslateCode';
import SmallSpinner from '../../components/others/spinner/SmallSpinner';
import * as Yup from 'yup';
import { Formik , Form } from 'formik';
import TextField from '../../components/userPanel/textField/TextField';
import CookieModal from '../../components/website/cookiesModal/CookieModal';
import { CookiesInformationData } from '../../data/CookiesInformationData';
import { detectNetworkConnection } from '../../helpers/DetectNetworkConnection';

const ResetPassword = (props) => {

    const validate = Yup.object({
        password: Yup.string()
            .min(6 , 'Hasło musi mieć minimum 6 znaków')
            .required('Hasło jest wymagane')            
    })

    const [ actionCode ] = useState(props.actionCode)
    const [ loading , setLoading ] = useState(true)
    const [ loadingInButton, setLoadingInButton ] = useState(false)
    const [error , setError] = useState('')
    const [ codeExpiredError , setCodeExpiredError ] = useState()
    const [message , setMessage] = useState('')
    const { verifyPasswordResetCode , confirmPasswordReset } = useAuth()
    const history = useHistory()

    useEffect(() => {
        checkPasswordResetCode()
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
  
    async function checkPasswordResetCode(){
        const isOnline = detectNetworkConnection();
        if(!message && isOnline){
            try{
                await verifyPasswordResetCode(actionCode)
                .then().catch(error => {
                    const polishError = FirebaseErrorTranslateCode(error.code)
                    setCodeExpiredError(polishError)
                })
             }
             catch (e){
                console.log(e);
                setCodeExpiredError('Nie udało się zresetować hasła')
             }
             setLoading(false)
        }
    }

    async function sendConfirmPasswordReset(values){
        setLoadingInButton(true)
        if(detectNetworkConnection()){
            try{
                await confirmPasswordReset(actionCode , values.password)
                .then((response) => {
                    setMessage("Hasło zostało zmienione poprawnie, za chwilę zostaniesz przekierowany na stronę główną.")
                    redirectToHomePage();
                }).catch(error => {
                    const polishError = FirebaseErrorTranslateCode(error.code)
                    setError(polishError)
                })
             }
             catch (e){
                setError('Nie udało się zresetować hasła, za chwilę zostaniesz przekierowany na stronę główną.');
                redirectToHomePage();
            }         
        }
        setLoadingInButton(false)
    }

    function redirectToHomePage(){
        setTimeout(() => {
            history.push("/");
        }, 4000);
    }

    return (
        <>
        {
            loading &&
            <SmallSpinner 
                isText={true}
                isCenter={true}
            />
        }
        {
        !loading &&
        <>
        <Formik
            initialValues={{
                password:''
                }}
            validationSchema={validate}
            onSubmit={values =>{
            sendConfirmPasswordReset(values)
        }}
        >
        {formik => (
        <>
        <Container className="d-flex align-items-center justify-content-center UserPanel__Container UserPanelSection">
            <div className="w-100" style={{ maxWidth: "400px"}}>
                <Card>
                    <Card.Body>
                        <div className="UserPanel__Logo">
                            <Link to='/'>
                                <img src={logo} alt="logo" />
                            </Link>
                        </div>
                        { codeExpiredError && <p className="text-center">{codeExpiredError} spróbuj jeszcze raz <Link to="/przypomnienie-hasla" className='UserPanel__link'>zresetować hasło</Link></p>}
                        { message && <p className="text-center">{message}</p>}
                        { !codeExpiredError && !message &&
                        <>
                        { error && <Alert variant="danger" className="alert-danger-custom">{error}</Alert>}
                        <Form>
                            <TextField label="Hasło" name="password" type="password" placeholder="Nowe hasło"/>
                            <button disabled={loadingInButton}  className={loadingInButton ? 'UserPanel__button button--loading' : 'UserPanel__button button--blue'} type="submit">
                                    {loadingInButton ? <SmallSpinner isText={false} />: "Zapisz"}
                            </button>
                        </Form>
                        </>
                        }

                    </Card.Body>
                </Card>
            </div>
        </Container>
        </>
        )}
        </Formik>
        <CookieModal data={CookiesInformationData}/>
        </>
        }       
        </>
    )
}

export default ResetPassword
