import React from 'react'
import './InformationSectionBox.css'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

const InformationSectionBox = (props) => {
    return (
        <Col md>
            <div className="InformationSection__box">
                <div className="svg-rwd">
                    <Image 
                        alt={props.data.alt}
                        src={props.data.img} 
                        fluid />
                </div>
                <h2>{props.data.headerText}</h2>
                <p>{props.data.paragraphText}</p>
            </div>
        </Col>
    )
}

export default InformationSectionBox
