import { Section } from '../../models/Section';

export const SpecialistQuestionsSection = (selectedCategory)  => {

    switch(selectedCategory) {
        case 'C': case 'D':
            return [
                new Section(selectedCategory, selectedCategory+"1", "1. Dopuszczalne prędkości pojazdu, ograniczenia", '/dzialy/czesc-specjalistyczna/dopuszczalne-predkosci-pojazdu-ograniczenia' , false),
                new Section(selectedCategory, selectedCategory+"2", "2. Wyposażenie pojazdu związane z bezpieczeństwem", '/dzialy/czesc-specjalistyczna/wyposazenie-pojazdu-zwiazane-z-bezpieczenstwem' , true),
                new Section(selectedCategory, selectedCategory+"3", "3. Zasady odnoszące się do rodzaju transportu, czas pracy i odpoczynku kierowcy, wykorzystanie tachografów",  '/dzialy/czesc-specjalistyczna/zasady-odnoszace-sie-do-rodzaju-transportu' , true),
                new Section(selectedCategory, selectedCategory+"4", "4. Odstępy i hamowanie pojazdu",  '/dzialy/czesc-specjalistyczna/odstepy-i-hamowanie-pojazdu' , true),
                new Section(selectedCategory, selectedCategory+"5", "5. Czynniki ryzyka związane z różnymi warunkami drogowymi, w szczególności ze zmianą tych warunków w zależności od pogody i pory dnia lub nocy, właściwości różnych typów dróg i związane z tym obowiązujące wymagania, różne pola widzenia kierowców", '/dzialy/czesc-specjalistyczna/czynniki-ryzyka-zwiazane-z-roznymi-warunkami-drogowymi' , true),
                new Section(selectedCategory, selectedCategory+"6", "6. Technika kierowania pojazdem",  '/dzialy/czesc-specjalistyczna/technika-kierowania-pojazdem' , true),
                new Section(selectedCategory, selectedCategory+"7", "7. Czynniki bezpieczeństwa odnoszące się do pojazdu, ładunku i przewożonych osób", '/dzialy/czesc-specjalistyczna/czynniki-bezpieczenstwa' , true),
                new Section(selectedCategory, selectedCategory+"8", "8. Obowiązki właściciela / posiadacza pojazdu, ubezpieczenia, wymagane dokumenty",  '/dzialy/czesc-specjalistyczna/obowiazki-wlasciciela-pojazdu' , true),
                new Section(selectedCategory, selectedCategory+"9", "9. Aspekty mechaniczne związane z zachowaniem bezpieczeństwa na drodze", '/dzialy/czesc-specjalistyczna/aspekty-mechaniczne-zwiazane-z-zachowaniem-bezpieczenstwa-na-drodze' , true),
                new Section(selectedCategory, selectedCategory+"10", "10. Akcje ratunkowe",  '/dzialy/czesc-specjalistyczna/akcje-ratunkowe' , true)
            ];
        case 'T':
            return [
                new Section(selectedCategory, selectedCategory+"1", "1. Dopuszczalne prędkości pojazdu, ograniczenia",  '/dzialy/czesc-specjalistyczna/dopuszczalne-predkosci-pojazdu-ograniczenia' , false),
                new Section(selectedCategory, selectedCategory+"2", "2. Wyposażenie pojazdu związane z bezpieczeństwem", '/dzialy/czesc-specjalistyczna/wyposazenie-pojazdu-zwiazane-z-bezpieczenstwem' , true),
                new Section(selectedCategory, selectedCategory+"3", "3. Odstępy i hamowanie pojazdu", '/dzialy/czesc-specjalistyczna/odstepy-i-hamowanie-pojazdu' , true),
                new Section(selectedCategory, selectedCategory+"4", "4. Czynniki ryzyka związane z różnymi warunkami drogowymi, w szczególności ze zmianą tych warunków w zależności od pogody i pory dnia lub nocy, właściwości różnych typów dróg i związane z tym obowiązujące wymagania", '/dzialy/czesc-specjalistyczna/czynniki-ryzyka-zwiazane-z-roznymi-warunkami-drogowymi' , true),
                new Section(selectedCategory, selectedCategory+"5", "5. Różne pola widzenia kierowców", '/dzialy/czesc-specjalistyczna/rozne-pola-widzenia-kierowcow' , true),
                new Section(selectedCategory, selectedCategory+"6", "6. Czynniki bezpieczeństwa odnoszące się do pojazdu, ładunku i przewożonych osób",  '/dzialy/czesc-specjalistyczna/czynniki-bezpieczenstwa' , true),
                new Section(selectedCategory, selectedCategory+"7", "7. Obowiązki właściciela / posiadacza pojazdu, ubezpieczenia, wymagane dokumenty", '/dzialy/czesc-specjalistyczna/obowiazki-wlasciciela-pojazdu' , true),
                new Section(selectedCategory, selectedCategory+"8", "8. Aspekty mechaniczne związane z zachowaniem bezpieczeństwa na drodze",  '/dzialy/czesc-specjalistyczna/aspekty-mechaniczne-zwiazane-z-zachowaniem-bezpieczenstwa-na-drodze' , true),
                new Section(selectedCategory, selectedCategory+"9", "9. Akcje ratunkowe",  '/dzialy/czesc-specjalistyczna/akcje-ratunkowe' , true)
            ];
        default:
            return [
                new Section(selectedCategory, selectedCategory+"1", "1. Dopuszczalne prędkości pojazdu, ograniczenia", '/dzialy/czesc-specjalistyczna/dopuszczalne-predkosci-pojazdu-ograniczenia' , false),
                new Section(selectedCategory, selectedCategory+"2", "2. Wyposażenie pojazdu związane z bezpieczeństwem", '/dzialy/czesc-specjalistyczna/wyposazenie-pojazdu-zwiazane-z-bezpieczenstwem' , true),
                new Section(selectedCategory, selectedCategory+"3", "3. Odstępy i hamowanie pojazdu", '/dzialy/czesc-specjalistyczna/odstepy-i-hamowanie-pojazdu' , true),
                new Section(selectedCategory, selectedCategory+"4", "4. Czynniki ryzyka związane z różnymi warunkami drogowymi, w szczególności ze zmianą tych warunków w zależności od pogody i pory dnia lub nocy, właściwości różnych typów dróg i związane z tym obowiązujące wymagania", '/dzialy/czesc-specjalistyczna/czynniki-ryzyka-zwiazane-z-roznymi-warunkami-drogowymi' , true),
                new Section(selectedCategory, selectedCategory+"5", "5. Różne pola widzenia kierowców", '/dzialy/czesc-specjalistyczna/rozne-pola-widzenia-kierowcow' , true),
                new Section(selectedCategory, selectedCategory+"6", "6. Technika kierowania pojazdem", '/dzialy/czesc-specjalistyczna/technika-kierowania-pojazdem' , true),
                new Section(selectedCategory, selectedCategory+"7", "7. Czynniki bezpieczeństwa odnoszące się do pojazdu, ładunku i przewożonych osób",  '/dzialy/czesc-specjalistyczna/czynniki-bezpieczenstwa' , true),
                new Section(selectedCategory, selectedCategory+"8", "8. Obowiązki właściciela / posiadacza pojazdu, ubezpieczenia, wymagane dokumenty",  '/dzialy/czesc-specjalistyczna/obowiazki-wlasciciela-pojazdu' , true),
                new Section(selectedCategory, selectedCategory+"9", "9. Aspekty mechaniczne związane z zachowaniem bezpieczeństwa na drodze", '/dzialy/czesc-specjalistyczna/aspekty-mechaniczne-zwiazane-z-zachowaniem-bezpieczenstwa-na-drodze' , true),
                new Section(selectedCategory, selectedCategory+"10", "10. Akcje ratunkowe", '/dzialy/czesc-specjalistyczna/akcje-ratunkowe' , true)
            ];
        }
}