export class Questions {
    constructor(array, examSetNumber, totalPointsInExam, isQuestionsWithoutAnswer, sectionsCount, answersCount){
        this.array = array
        this.examSetNumber = examSetNumber
        this.totalPointsInExam = totalPointsInExam
        this.isQuestionsWithoutAnswer = isQuestionsWithoutAnswer
        this.sectionsCount = sectionsCount
        this.answersCount = answersCount
    }
}

export class QuestionWithInformation  {
    constructor(id, userAnswer, section, points){
        this.id = id
        this.userAnswer = userAnswer
        this.section = section
        this.points = points
    } 
}

export class SectionCount {
    constructor(section, count){
        this.section = section
        this.count = count
    } 
}

export class QuestionCount {
    constructor(id, count){
        this.id = id
        this.count = count
    } 
}

export class AnswerCount {
    constructor(goodAnswer, wrongAnswer){
        this.goodAnswer = goodAnswer
        this.wrongAnswer = wrongAnswer
    }
}

export class QuestionWithSectionAndPoints {
    constructor(id, section, points){
        this.id = id
        this.section = section
        this.points = points
    }
}