import React from 'react';
import './Item.css';
import ProgressCircle from '../progressCircle/ProgressCircle';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import '../learningItem/LearningItem.css';
import { showToast , resetModalSettings } from '../../../redux/actions/notificationActions';
import { RiLockLine } from 'react-icons/ri';
import { createAlertUnlockPremium, ModalContent } from '../../../models/ModalContet';
import { showModal , closeModal , setLoadingInModal } from '../../../redux/actions/notificationActions';
import { BsArrowRepeat } from 'react-icons/bs';
import { OverlayTrigger , Tooltip  } from 'react-bootstrap';
import { deleteProgresForSection } from '../../../redux/actions/questionActions';
import ReactHtmlParser from 'react-html-parser'; 

const Item = (props) => {

    console.log(props);

    const dispatch = useDispatch();
    const history = useHistory();
    const alertUnlockPremium = createAlertUnlockPremium();
    
    function handleCheckAccess(){
        if (props.section === 'basic' || props.section === 'specialist'){
            history.push(props.link);
        }
        else{
            dispatch(showToast(props.messageForToast , "success")).then(() => {
                dispatch(resetModalSettings());
            })   
        }
    }

    function handleShowUnlockPremiumModal(){
        dispatch(showModal(alertUnlockPremium));
    }

    function resetProgressForSection(event){
    
        event.stopPropagation();

        const alertResetProgressForSection = new ModalContent(
            "Error",
            "RESET POSTĘPU",
            `Czy napewno chcesz usunąć cały postęp dla działu:\n ${props.title}`,
            "Anuluj",
            () => dispatch(closeModal()),
            "Tak",
            () => resetProgress()
        );

        if(props.progress > 0){
            dispatch(showModal(alertResetProgressForSection));  
        }else{
            dispatch(showToast("W tym dziale nie ma postępu do zresetowania", "success")).then(() => {
                dispatch(resetModalSettings());
            })  
        }
                         
    }

    async function resetProgress(){
        dispatch(setLoadingInModal(true));
        try{
            dispatch(deleteProgresForSection(props.section, props.type)).then(() => {
                dispatch(closeModal());
                dispatch(showToast("Postęp dla pytań zresetowany", "success"))
            });
        }catch(error){
            console.log(error);
        }
    }

    function redirectToSection(event){
        event.preventDefault();
        history.push(props.link);
    }

    return (

        <>
            { 
                props.section === 'title' && 
                <div className="RegulationsItem__title">
                    <h4>{props.title}</h4>
                </div>
            }
            {
                props.section !== 'title' && props.messageForToast === null && !props.disabled &&
                <Link to={props.link}>
                <div className="DashBoardItem">

                    <div className="DashBoardItem__Name">
                    {ReactHtmlParser(props.title)}
                    </div>

                    <div className="DashBoardItem__ProgressCircle">
                        <ProgressCircle progress={props.progress} color={props.color}/>
                    </div>
                </div>
                </Link>
            }
            {
                props.section !== 'title' && props.progress !== 100 && props.messageForToast && !props.disabled &&
                <div className="DashBoardItem" onClick={redirectToSection}>

                    <div className="DashBoardItem__Name">
                    {ReactHtmlParser(props.title)}
                    </div>

                    {
                        (!props.activeResetProgress || props.progress === undefined) &&
                        <div className="DashBoardItem__ProgressCircle">
                            <ProgressCircle progress={props.progress} color={props.color}/>
                        </div>
                    }
                    {
                        (props.activeResetProgress && props.progress !== undefined) &&
                        <div className="DashBoardItem__ProgressCircle ActiveResetProgress">
                            <ProgressCircle progress={props.progress} color={props.color}/>
                            <OverlayTrigger 
                                overlay={<Tooltip id="button-tooltip-2">Reset postępu</Tooltip>}
                                placement="bottom"
                            >
                                <BsArrowRepeat 
                                    onClick={resetProgressForSection}
                                />
                            </OverlayTrigger>   
                        </div>
                    }     
                </div>
            }
            {
                 props.section !== 'title' && props.progress === 100 && props.messageForToast && !props.disabled &&
                 <div className="DashBoardItem" onClick={handleCheckAccess}>
 
                    <div className="DashBoardItem__Name">
                    {ReactHtmlParser(props.title)}
                    </div>
                    {
                        (!props.activeResetProgress || props.progress === undefined) &&
                        <div className="DashBoardItem__ProgressCircle">
                            <ProgressCircle progress={props.progress} color={props.color}/>
                        </div>
                    }
                    {
                        (props.activeResetProgress && props.progress !== undefined) &&
                        <div className="DashBoardItem__ProgressCircle ActiveResetProgress">
                            <ProgressCircle progress={props.progress} color={props.color}/>
                            <OverlayTrigger 
                                overlay={<Tooltip id="button-tooltip-2">Reset postępu</Tooltip>}
                                placement="bottom"
                            >
                                <BsArrowRepeat 
                                    onClick={resetProgressForSection}
                                />
                            </OverlayTrigger>   
                        </div>
                    }       
                 </div>         
            }  
            {
                 props.section !== 'title' && props.disabled &&
                 <div className="DashBoardItem DashBoardItem--disabled" onClick={handleShowUnlockPremiumModal}>
 
                     <div className="DashBoardItem__Name">
                     {ReactHtmlParser(props.title)}
                     </div>
 
                     <div className="DashBoardItem__DisabledIcon">
                         <RiLockLine />
                         <p>Dostępne w wersji premium</p>
                     </div>
                 </div>         
            }           
        </>
    )
}

export default Item
