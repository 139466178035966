import { clearQuestionsAnswersQueue, clearSignsAnswersQueue, clearRegulationsAnswersQueue, addQuestionsAnswers, addSignsAnswers, addRegulationsAnswers } from '../redux/actions/answersActions';
import { ChecksumCheckerService } from './ChecksumCheckerService';
import store from '../redux/store';
import agent from '../api/agent';
import { auth } from '../Firebase';
import 'core-js/es/object/from-entries';

export class QueueAnswerService {

    sendAnswersFromQueuesBeforeLogout(){
        return new Promise((reslove, reject) => {
            const areSendedQuestionsAnswers = this.sendQuestionsQueue();
            const areSendedSingsAnswers = this.sendSingsQueue();
            const areSendedRegulationsAnswers = this.sendRegulationsQueue();

            if (areSendedQuestionsAnswers && areSendedSingsAnswers && areSendedRegulationsAnswers){
                reslove("Sending user answers has been completed");
            }
        })
    }

    sendAnswersFromQueues(){
        this.sendQuestionsQueue();
        this.sendSingsQueue();
        this.sendRegulationsQueue();
    }

    getQueueQuestions(){
        let answersQueueState = store.getState().answers.questionsAnswersQueue
        return getQueue(answersQueueState, clearQuestionsAnswersQueue)
    }

    getResetParamQuestions(){
        let resetFlag = store.getState().answers.questionsAnswersResetFlag
        return resetFlag === true ? "?reset=true&" : ""
    }

    getQueueSigns(){
        let answersQueueState = store.getState().answers.signsAnswersQueue
        return getQueue(answersQueueState, clearSignsAnswersQueue)
    }

    getQueueRegulations(){
        let answersQueueState = store.getState().answers.regulationsAnswersQueue
        return getQueue(answersQueueState, clearRegulationsAnswersQueue)
    }

    sendSingsQueue(){
        let queueSigns = this.getQueueSigns();
        if (queueSigns.size > 0) {
            //console.log("queueSigns size:" + queueSigns.size)
            const signsAnswersTimestampBeforeSend = localStorage.getItem("signsAnswersTimestamp");
            store.dispatch(clearSignsAnswersQueue())
            agent.UserAnswers.patchSigns(Object.fromEntries(queueSigns))
            .then(response => {
                    const signsAnswersTimestampAfterSend = localStorage.getItem("signsAnswersTimestamp");
                    if (signsAnswersTimestampBeforeSend === signsAnswersTimestampAfterSend){
                        new ChecksumCheckerService().synchronizeSignsAnswers(response.checksum, response.timestamp, response.reset);
                    }        
                })
                .catch(error => {
                    //jeśli użytkownik jest null'em, to oznacza że ktoś skasował konto przez inną przeglądarke / aplikacje mobilną lub wylogował się w innej zakładce
                    if(auth.currentUser !== null){
                        // nie wysłano - wrzucamy spowrotem odpowiedzi do kolejki 
                        store.dispatch(addSignsAnswers(queueSigns))

                        //przechwycony blad, nic nie robimy, probujemy za 20 sek znowu
                        // jesli blad 415 (nie prawidlowe dane) - to moze usuwac tablice lub ja probowac odratowac
                        console.log("queueSigns result promise error:" + error)
                    }
                });
        }
        return true;
    }

    sendQuestionsQueue() {
        //console.log("QueueAnswerService sendQuestionsQueue()")
        //pytania odpowiedzi
        let queueQuestions = this.getQueueQuestions()
        let resetParam = this.getResetParamQuestions()
      
        if (queueQuestions.size > 0) {
            //console.log("queueQuestions size:" + queueQuestions.size)
            const questionsAnswersTimestampBeforeSend = localStorage.getItem("questionsAnswersTimestamp");
            console.log("QueueAnswerService sendQuestionsQueue()")
            store.dispatch(clearQuestionsAnswersQueue())
            agent.UserAnswers.patchQuestions(Object.fromEntries(queueQuestions), resetParam)
                .then(response => {   
                   const questionsAnswersTimestampAfterSend = localStorage.getItem("questionsAnswersTimestamp");
                   if (questionsAnswersTimestampBeforeSend === questionsAnswersTimestampAfterSend){
                        new ChecksumCheckerService().synchronizeQuestionsAnswers(response.checksum, response.timestamp, response.reset);
                   }
                })
                .catch(error => {
                    //jeśli użytkownik jest null'em, to oznacza że ktoś skasował konto przez inną przeglądarke / aplikacje mobilną lub wylogował się w innej zakładce
                    if(auth.currentUser !== null){
                        store.dispatch(addQuestionsAnswers(queueQuestions))
                        //przechwycony blad, nic nie robimy, probujemy za 20 sek znowu
                        // jesli blad 415 (nie prawidlowe dane) - to moze usuwac tablice lub ja probowac odratowac
                        console.log("QueueAnswerService sendQuestionsQueue result promise error:" + error)
                    }
                });
        }
        return true;
    }

    sendRegulationsQueue() {
        //znaki odpowiedzi
        let queueRegulations = this.getQueueRegulations()
        if (queueRegulations.size > 0) {
            //console.log("queueRegulations size:" + queueRegulations.size)
            const regulationsAnswersTimestampBeforeSend = localStorage.getItem("regulationsAnswersTimestamp");
            store.dispatch(clearRegulationsAnswersQueue())
            agent.UserAnswers.patchRegulations(Object.fromEntries(queueRegulations))
                .then(response => {
                    const regulationsAnswersTimestampAfterSend = localStorage.getItem("regulationsAnswersTimestamp");
                    if (regulationsAnswersTimestampBeforeSend === regulationsAnswersTimestampAfterSend){
                        new ChecksumCheckerService().synchronizeRegulationsAnswers(response.checksum, response.timestamp, response.reset);
                    }  
                })
                .catch(error => {
                    //jeśli użytkownik jest null'em, to oznacza że ktoś skasował konto przez inną przeglądarke / aplikacje mobilną lub wylogował się w innej zakładce
                    if(auth.currentUser !== null){
                        store.dispatch(addRegulationsAnswers(queueRegulations))
                        //przechwycony blad, nic nie robimy, probujemy za 20 sek znowu
                        // jesli blad 415 (nie prawidlowe dane) - to moze usuwac tablice lub ja probowac odratowac
                        console.log("queueRegulations result promise error:" + error)
                    }
                });
        }
        return true;
    }

}

//private functions
function getQueue(queueState, clearAnswersQueueCallback){
    //console.log("getQueue()")
    if(queueState === ""){
        return new Map()
    }
    try {
        return new Map(JSON.parse(queueState))
    } catch {
        store.dispatch(clearAnswersQueueCallback());
        return new Map()
    }
}
